import {
  AccessPointRequestDto,
  useLazySecurityUserAccessPointControllerGetAccessPointByIdsQuery,
} from '@shared/services/apiService/apiService_integration';
import {
  AttendanceLogResponseDto,
  useSecurityUserControllerGetAttendanceLogsQuery,
} from '@shared/services/apiService/apiService_log';
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_LIMIT_ITEMS_ON_PAGE,
  DEFAULT_NULL_MILLISECONDS,
  DEFAULT_POLLING_INTERVAL_MILLISECONDS,
} from '@shared/constants/pagination';
import {
  SecurityUserOfficeResponseDto,
  SecurityUserVisitorResponseDto,
  useLazySecurityUsersV2ControllerSearchVisitorsQuery,
} from '@shared/services/apiService/apiService_base';
import { StorageKeys, useLocalStorage } from '@shared/hooks/useLocalStorage';

import { MIN_LENGHT_SEARCH_QUERY_VISITORS } from './useSearchVisitors';
import { useEffect } from 'react';

type GetAttendanceLogProps = {
  page: number;
  search?: string;
};

type AttendanceLogsProps = {
  attendanceLog?: AttendanceLogResponseDto;
  visitorInfo?: SecurityUserVisitorResponseDto;
  accessPointInfo?: AccessPointRequestDto;
};

export const useGetAttendanceLog = ({ page, search }: GetAttendanceLogProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const isFirstPage = page === DEFAULT_INITIAL_PAGE;
  const pollingInterval = isFirstPage ? DEFAULT_POLLING_INTERVAL_MILLISECONDS : DEFAULT_NULL_MILLISECONDS;

  const hasSearchQuery = search && search.length >= MIN_LENGHT_SEARCH_QUERY_VISITORS;

  const { data: dataAttendanceLogs, isLoading: isLoadingAttendanceLogs } =
    useSecurityUserControllerGetAttendanceLogsQuery(
      {
        officeId: officeId!,
        limit: DEFAULT_LIMIT_ITEMS_ON_PAGE,
        page: page,
        //searchField: hasSearchQuery ? search : undefined, //TODO: visitorId
      },
      { refetchOnMountOrArgChange: true, pollingInterval, skip: !officeId },
    );

  const [triggerGetVisitorsInfo, { data: visitorsInfo, isLoading: isVisitorsInfoLoading }] =
    useLazySecurityUsersV2ControllerSearchVisitorsQuery();

  const [triggerGetAccessPoint, { data: accessPointInfo, isLoading: isAccessPointLoading }] =
    useLazySecurityUserAccessPointControllerGetAccessPointByIdsQuery();

  useEffect(() => {
    const visitorsIds = dataAttendanceLogs?.data.map(visitor => visitor.visitorId);
    const accessPointIds = dataAttendanceLogs?.data.map(visitor => visitor.accessPointId);

    visitorsIds && triggerGetVisitorsInfo({ ids: [...new Set(visitorsIds)] });
    accessPointIds && triggerGetAccessPoint({ ids: [...new Set(accessPointIds)] });
  }, [dataAttendanceLogs]);

  const attendanceLogs: AttendanceLogsProps[] | undefined =
    dataAttendanceLogs?.data &&
    dataAttendanceLogs?.data.map(attendanceLog => {
      const visitorInfoByLogId = visitorsInfo?.find(visitor => visitor.id === attendanceLog.visitorId);
      const accessPointInfoByLogId = accessPointInfo?.find(
        accessPoint => accessPoint.id === attendanceLog.accessPointId,
      );

      return {
        attendanceLog: attendanceLog ? attendanceLog : undefined,
        visitorInfo: visitorInfoByLogId ? visitorInfoByLogId : undefined,
        accessPointInfo: accessPointInfoByLogId ? accessPointInfoByLogId : undefined,
      };
    });

  const pageCount = dataAttendanceLogs?.meta.pageCount;
  const isEmpty = attendanceLogs?.length === 0 && !isLoadingAttendanceLogs;
  const hasPagination = dataAttendanceLogs?.meta && dataAttendanceLogs?.meta.pageCount > 1;
  const isLoading = isLoadingAttendanceLogs || isVisitorsInfoLoading || isAccessPointLoading;

  return {
    attendanceLogs,
    isLoading,
    pageCount,
    isEmpty,
    hasPagination,
  };
};
