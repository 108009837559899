import { Button, InputField } from '@shared/ui';

import { ForgotLoginOrPassword } from '@features/ForgotLoginOrPassword';
import predatorLogo from '@shared/assets/icons/origin/logo.svg';
import styles from './signInForm.module.scss';
import { useLayoutEffect } from 'react';
import { useSignIn } from '../hooks/useSignIn';
import { useWatch } from 'react-hook-form';

export const SignInForm = () => {
  const {
    onSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useSignIn();

  const loginValue = useWatch({ control, name: 'login' });

  useLayoutEffect(() => {
    const trimLoginValue = loginValue.trim();
    setValue('login', trimLoginValue);
  }, [loginValue]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img src={predatorLogo} alt="Logo" className={styles.logo} />
        <h1 className={styles.title}>Авторизация</h1>
        <p className={styles.description}>Для входа в приложение Urbanpredator введите Ваш логин и пароль</p>
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.inputs}>
          <InputField
            name="login"
            type="text"
            className={styles.input}
            placeholder="Введите логин"
            label="Логин"
            disabled={isSubmitting}
            error={errors.login}
            control={control}
          />
          <InputField
            className={styles.input}
            name="password"
            type="password"
            placeholder="Введите пароль"
            autoComplete="on"
            isSecure
            label="Пароль"
            disabled={isSubmitting}
            control={control}
            error={errors.password}
          />
        </div>
        <Button variant="primary" type="submit" disabled={!isValid} isLoading={isSubmitting} className={styles.button}>
          Войти
        </Button>

        <div className={styles.resetButton}>
          <ForgotLoginOrPassword />
        </div>
      </form>
    </div>
  );
};
