import { FC, ReactNode, useRef } from 'react';
import { Size, useElementSize } from '@shared/hooks/useElementSize';

import classNames from 'classnames';
import styles from './sidebarContent.module.scss';

type SidebarContentProps = {
  isEmpty?: boolean;
  hasBackOption: boolean;
  title?: string;
  list: ReactNode;
  actions: ReactNode;
  sidebarHeaderSize?: Size;
};

export const SidebarContent: FC<SidebarContentProps> = ({
  isEmpty = false,
  hasBackOption,
  title = '',
  list,
  actions,
}) => {
  const hasTitle = title.length !== 0;

  const classesRoot = classNames(styles.root, { [styles.rootEmptyHead]: !hasTitle });
  const classesContent = classNames(styles.content, { [styles.contentEmpty]: isEmpty });
  const classesList = classNames(styles.list, {
    [styles.listHasBackOption]: hasBackOption,
    [styles.listEmpty]: isEmpty,
    [styles.listEmptyHead]: !hasTitle,
  });

  const actionsRef = useRef(null);
  const [actionsSize] = useElementSize(actionsRef, undefined);

  return (
    <div className={classesRoot}>
      <div className={classesContent} style={{ marginBottom: actionsSize.height }}>
        {hasTitle && <p className={styles.title}>{title}</p>}
        <div className={classesList}>{list}</div>
      </div>
      <div className={styles.actions} ref={actionsRef}>
        {actions}
      </div>
    </div>
  );
};
