export type ErrorI = {
  data: {
    error: string;
    message: string;
    statusCode: number;
  };
  status: number;
};

export enum SuspiciousActivityTabEnum {
  VERIFIED = 'verified',
  NOT_VERIFIED = 'not_verified',
}

export enum VisitorsSortFieldsEnum {
  ATTENDED_AT = 'attended_at',
  NAME = 'name',
  PHONE = 'phone',
  COMPANY_NAME = 'company_name',
  ROLE = 'role',
  DURATION = 'duration',
}

export enum SuspiciousActivityKindEnum {
  BLUETOOTH_UNDEFINED = 'bluetooth_undefined',
  BIOMETRICS_UNDEFINED = 'biometrics_undefined',
  BLUETOOTH_AND_BIOMETRICS_UNDEFINED = 'bluetooth_and_biometrics_undefined',
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export enum EntryTypeEnum {
  ENTRANCE = 'entrance',
  EXIT = 'exit',
}

export enum PassageTypeEnum {
  DEFAULT = 'default',
  SUSPICIOUS = 'suspicious',
}

//TODO: rename enums when api ready
export enum BeaconTypeEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum JornalTabFilterEnum {
  ALL = 'all',
  SUSPICIOUS = 'suspicious',
}

export enum JornalSortFieldsEnum {
  CREATED_AT = 'created_at',
  LAST_NAME = 'last_name',
}

export enum VisitorAttendanceLogsSortFieldsEnum {
  CREATED_AT = 'created_at',
}
