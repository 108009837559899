import { VisitorRoleEnum } from '@pages/Visitors/types';
import { markerColors } from './markerColors';

export function getMarkerSecondaryColor(role: VisitorRoleEnum) {
  const secondaryMarkerColorMapping = {
    [VisitorRoleEnum.EMPLOYEE]: markerColors.employee_secondary,
    [VisitorRoleEnum.GUEST]: markerColors.guest_secondary,
    [VisitorRoleEnum.CONTRACTOR]: markerColors.contractor_secondary,
    [VisitorRoleEnum.UNIDENTIFIED]: markerColors.unidentified_secondary,
    [VisitorRoleEnum.SECURITY]: markerColors.security_secondary,
  };

  return secondaryMarkerColorMapping[role];
}
