import * as React from 'react';
function SvgGuests(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#Guests_svg__clip0_151_3380)">
        <g clipPath="url(#Guests_svg__clip1_151_3380)" stroke="#fff" strokeWidth={1.5} strokeLinecap="round">
          <path d="M11.333 14a4 4 0 00-4-4H4.667a4 4 0 00-4 4M15.333 14a4.002 4.002 0 00-2.666-3.772" />
          <path
            d="M6 7.333A2.667 2.667 0 106 2a2.667 2.667 0 000 5.333zM10.667 2.087a2.666 2.666 0 010 5.166"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="Guests_svg__clip0_151_3380">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="Guests_svg__clip1_151_3380">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgGuests;
