import {
  Archive,
  Camera,
  Map as MapIcon,
  Menu,
  Minus,
  Plus,
  RotateLeft,
  RotateRight,
} from '@shared/assets/icons/components';
import { ContextMenu, IconButton } from '@shared/ui';
import {
  INITIAL_CONTAINMENT_SCALE,
  INITIAL_SCALE_COUNTER,
  MAX_SCALE_COUNTER,
  MIN_SCALE_COUNTER,
  ROTATE_LEFT_DEGREE,
  ROTATE_RIGHT_DEGREE,
  SCALE_COUNTER_SHIFT,
  SWITCH_MARKER_SCALE_COUNTER,
} from '../../constants';
import {
  MutableRefObject,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useClientSize, useEventListener, useOutsideClick, useToggle } from '@shared/hooks';
import {
  useGetAllChildren,
  useGetCursorCoords,
  useGetMarkerTouchingCursor,
  useHandleImageLoad,
  useHandleMarkerClick,
  useHandleWheel,
  useRedraw,
  useRotate,
  useUpdateContainmentScale,
  useZoom,
  useZoomDown,
  useZoomUp,
} from '../../hooks';

import { ContextMenuVariantEnum } from '@shared/ui/ContextMenu/types';
import type { Coords } from '../../types';
import { MapMarker } from '../MapMarker';
import classNames from 'classnames';
import styles from './canvasMap.module.scss';

export interface CanvasMapProps {
  children?: ReactNode;
  image: string;
  overpan?: number;
  containInitialImage?: boolean;
  containUpdatedImage?: boolean;
  onClick?(pt: Coords): void;
}

export const CanvasMap = forwardRef<HTMLCanvasElement, CanvasMapProps>(
  ({ image, children, overpan = 30, containInitialImage = true, containUpdatedImage = true, onClick }, ref) => {
    const [isImageInitialised, setImageInitialised] = useState(false);
    const [containmentScale, setContainmentScale] = useState(INITIAL_CONTAINMENT_SCALE);
    const [totalScaleFactor, setTotalScaleFactor] = useState(INITIAL_SCALE_COUNTER);

    const { state: isContextMenuVisible, toggle: toggleContextMenu } = useToggle();
    const { state: isActivityLayerActive, toggle: toggleActivityLayer } = useToggle();
    const { state: isCameraLayerActive, toggle: toggleCameraLayer } = useToggle();

    const menuButtonRef = useRef(null);
    const contextMenuRef = useRef(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const mapImageRef = useRef<HTMLImageElement | null>(null);
    const draggingMarkerKeyRef = useRef<string | null>(null);
    const clickPointRef = useRef<DOMPoint | null>(null);
    const cursorRef = useRef<Coords | null>(null);
    const markersRef = useRef<ReactElement[]>([]);

    const flatChildren = useMemo(() => {
      const allChildren = useGetAllChildren(children);

      return allChildren;
    }, [children]);

    markersRef.current = flatChildren.filter(child => child.type && child.type === MapMarker);

    const updateCursor = useCallback(() => {
      if (!canvasRef.current) {
        return;
      }

      const hovered = useGetMarkerTouchingCursor(markersRef, canvasRef, cursorRef, mapImageRef);

      if (hovered) {
        canvasRef.current.style.cursor = 'pointer';
      } else {
        canvasRef.current.style.cursor = 'auto';
      }
    }, []);

    const redraw = useRedraw(markersRef, canvasRef, mapImageRef, updateCursor);

    const updateContainmentScale = useUpdateContainmentScale(canvasRef, mapImageRef, setContainmentScale);

    const handleImageLoad = useHandleImageLoad(
      isImageInitialised,
      containInitialImage,
      containUpdatedImage,
      containmentScale,
      canvasRef,
      mapImageRef,
      updateContainmentScale,
      redraw,
      setImageInitialised,
    );

    useEffect(() => {
      if (totalScaleFactor > SWITCH_MARKER_SCALE_COUNTER) {
        markersRef.current = flatChildren.map(child => {
          return {
            ...child,
            props: {
              ...child.props,
              isAvatar: true,
            },
          };
        });
      }

      redraw();
    }, [totalScaleFactor, flatChildren]);

    const resize = useCallback(() => {
      if (!canvasRef.current) {
        return;
      }

      if (cursorRef.current !== null) {
        const cursorXProportion = cursorRef.current.x / canvasRef.current.clientWidth;
        const cursorYProportion = cursorRef.current.y / canvasRef.current.clientHeight;

        cursorRef.current = {
          x: cursorXProportion * canvasRef.current.width,
          y: cursorYProportion * canvasRef.current.height,
        };
      }

      canvasRef.current.width = canvasRef.current.clientWidth;
      canvasRef.current.height = canvasRef.current.clientHeight;

      updateContainmentScale();
    }, []);

    const handleDocumentMouseMove = useCallback(
      (event: MouseEvent) => {
        if (!canvasRef.current) {
          return;
        }

        const context = canvasRef.current.getContext('2d');

        if (!context) {
          return;
        }

        const lastPt = useGetCursorCoords(canvasRef, cursorRef);
        const rect = canvasRef.current.getBoundingClientRect();

        if (event) {
          cursorRef.current = {
            x: event.clientX - rect.x,
            y: event.clientY - rect.y,
          };
        }

        if (!clickPointRef.current) {
          updateCursor();
          return;
        }

        if (!draggingMarkerKeyRef.current) {
          const pt = useGetCursorCoords(canvasRef, cursorRef);

          if (pt === null || lastPt === null || !mapImageRef.current) {
            return;
          }

          let translateX = pt.x - lastPt.x;
          let translateY = pt.y - lastPt.y;

          context.translate(translateX, translateY);
          redraw();
        }
      },
      [overpan, redraw, updateCursor],
    );

    const handleDocumentMouseUp = useMemo(
      () => () => {
        draggingMarkerKeyRef.current = null;
        clickPointRef.current = null;
        redraw();
      },
      [redraw],
    );

    const handleWheel = (event: Event) => useHandleWheel(event, totalScaleFactor, zoom);

    const handleCanvasMouseUp = useHandleMarkerClick(canvasRef, cursorRef, draggingMarkerKeyRef, markersRef, onClick);

    const handleCanvasMouseDown = useCallback(() => {
      // @ts-ignore: Old vendor prefixes
      document.body.style.mozUserSelect = 'none';
      document.body.style.webkitUserSelect = 'none';
      document.body.style.userSelect = 'none';

      clickPointRef.current = useGetCursorCoords(canvasRef, cursorRef);
      draggingMarkerKeyRef.current = useGetMarkerTouchingCursor(markersRef, canvasRef, cursorRef, mapImageRef);
    }, [handleDocumentMouseMove]);

    useEffect(() => {
      redraw();
    }, [flatChildren, redraw]);

    useEffect(() => {
      mapImageRef.current = new Image();
      mapImageRef.current.src = image;
      mapImageRef.current.onload = handleImageLoad;

      redraw();
    }, [image, handleImageLoad, redraw]);

    useEffect(() => {
      if (mapImageRef.current) {
        mapImageRef.current.onload = handleImageLoad;
      }
    }, [handleImageLoad]);

    useEffect(() => {
      resize();
    }, [resize]);

    useEventListener('resize', resize);
    useEventListener('mousemove', handleDocumentMouseMove, canvasRef);
    useEventListener('wheel', handleWheel, canvasRef);
    useEventListener('mouseup', handleDocumentMouseUp);
    useEventListener('mouseup', handleCanvasMouseUp, canvasRef);
    useEventListener('mousedown', handleCanvasMouseDown);

    const zoom = useZoom(canvasRef, cursorRef, setTotalScaleFactor, redraw);
    const zoomDown = useZoomDown(canvasRef, setTotalScaleFactor, redraw);
    const zoomUp = useZoomUp(canvasRef, setTotalScaleFactor, redraw);
    const rotate = useRotate(canvasRef, mapImageRef, redraw);

    const handleZoomUp = () => {
      if (totalScaleFactor < MAX_SCALE_COUNTER && totalScaleFactor + SCALE_COUNTER_SHIFT <= MAX_SCALE_COUNTER) {
        zoomUp();
      }
    };

    const handleZoomDown = () => {
      if (totalScaleFactor > MIN_SCALE_COUNTER && totalScaleFactor - SCALE_COUNTER_SHIFT >= MIN_SCALE_COUNTER) {
        zoomDown();
      }
    };

    const handleRotateRight = () => {
      rotate(ROTATE_LEFT_DEGREE);
    };

    const handleRotateLeft = () => {
      rotate(ROTATE_RIGHT_DEGREE);
    };

    const handleChangeMap = () => {
      console.log('todo');
    };

    const handleChangeActivityLayer = () => {
      toggleActivityLayer();
    };

    const handleChangeCameraLayer = () => {
      toggleCameraLayer();
    };

    const CanvasMapContextMenu = [
      {
        icon: <Archive />,
        title: 'Обновить план',
        onClick: handleChangeMap,
        variant: ContextMenuVariantEnum.ACTION,
      },
      {
        icon: <MapIcon />,
        title: 'Термокарта',
        onClick: handleChangeActivityLayer,
        variant: ContextMenuVariantEnum.SWITCH,
        value: isActivityLayerActive,
      },
      {
        icon: <Camera />,
        title: 'Слой с камерами',
        onClick: handleChangeCameraLayer,
        variant: ContextMenuVariantEnum.SWITCH,
        value: isCameraLayerActive,
      },
    ];

    useOutsideClick(contextMenuRef, undefined, toggleContextMenu);

    const { getIsBreakpoint } = useClientSize();
    const isDesktopL = getIsBreakpoint('desktopL');

    return (
      <div className={styles.root}>
        <div className={styles.controls}>
          <div className={styles.buttonGroup}>
            <IconButton onClick={handleZoomUp} variant="secondary" segment="up" size={isDesktopL ? 32 : 30}>
              <Plus />
            </IconButton>
            <div className={styles.scaleFactor}>{totalScaleFactor}</div>
            <IconButton onClick={handleZoomDown} variant="secondary" segment="down" size={isDesktopL ? 32 : 30}>
              <Minus />
            </IconButton>
          </div>

          <div className={styles.buttonGroup}>
            <IconButton className={styles.rotateLeft} onClick={handleRotateLeft} variant="secondary" segment="up">
              <RotateLeft />
            </IconButton>
            <IconButton className={styles.rotateRight} onClick={handleRotateRight} variant="secondary" segment="down">
              <RotateRight />
            </IconButton>
          </div>

          {/* <div>
            <IconButton ref={menuButtonRef} onClick={toggleContextMenu} variant="secondary" segment="default">
              <Menu />
            </IconButton>
            <ContextMenu
              className={styles.contextMenu}
              ref={contextMenuRef}
              anchorElement={menuButtonRef.current}
              menu={CanvasMapContextMenu}
              isVisible={isContextMenuVisible}
              variant="secondary"
              position="right-start"
            />
          </div> */}
        </div>
        <canvas
          className={styles.canvas}
          ref={node => {
            if (!node) {
              return;
            }
            (canvasRef as MutableRefObject<HTMLCanvasElement>).current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              (ref as MutableRefObject<HTMLCanvasElement>).current = node;
            }
          }}
        />
      </div>
    );
  },
);
