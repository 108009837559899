import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDone16 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m2 6 2.47 2.47a.75.75 0 0 0 1.06 0L11 3"
    />
  </svg>
);
export default SvgDone16;
