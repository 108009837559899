import { MAP_MARKER_ACTIVE_RADIUS, MAP_MARKER_AVATAR_RADIUS, MAP_MARKER_DEFAULT_RADIUS } from '../constants';
import { ReactElement, RefObject } from 'react';

import { Coords } from '../types';
import { useExtendContext } from './useExtendContext';
import { useGetCursorCoords } from './useGetCursorCoords';

type DistanceLookup = {
  [key: string]: number;
};

export const useGetMarkerTouchingCursor = (
  markersRef: RefObject<ReactElement[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  cursorRef: RefObject<Coords | null>,
  mapImageRef: RefObject<HTMLImageElement>,
) => {
  if (!canvasRef.current || !markersRef.current) {
    return null;
  }

  const context = useExtendContext(canvasRef.current.getContext('2d'));

  if (!context) {
    return null;
  }

  const close: DistanceLookup = {};

  const cursorPt = useGetCursorCoords(canvasRef, cursorRef);

  if (cursorPt === null) {
    return null;
  }

  markersRef.current.forEach(child => {
    const { markerKey, coords, isAvatar, isActive, onClick: onMarkerClick } = child.props;

    if (!(typeof onMarkerClick === 'function')) {
      return;
    }

    const sizeAvatar = isAvatar ? MAP_MARKER_AVATAR_RADIUS : MAP_MARKER_DEFAULT_RADIUS;
    const size = isActive && !isAvatar ? MAP_MARKER_ACTIVE_RADIUS : sizeAvatar;

    const HOVER_DIST = size;
    const HOVER_DIST_SQ = HOVER_DIST * HOVER_DIST;

    let distSq;

    if (!mapImageRef.current) {
      return;
    }

    distSq =
      (coords.x - mapImageRef.current.width / 2 - cursorPt.x) ** 2 +
      (coords.y - mapImageRef.current.height / 2 - cursorPt.y) ** 2;

    if (distSq < HOVER_DIST_SQ) {
      close[markerKey] = distSq;
    }
  });

  let closestDist = -1;
  let closest: string[] = [];

  Object.keys(close).forEach(key => {
    const distSq = close[key];
    if (closestDist === -1 || distSq < closestDist) {
      closestDist = distSq;
      closest = [];
      closest.push(key);
    } else if (distSq === closestDist) {
      closest.push(key);
    }
  });

  return closest[0] || null;
};
