import * as React from 'react';
function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.251 11.577a.817.817 0 000 1.179.874.874 0 001.212 0l4.286-4.167a.817.817 0 000-1.178L6.463 3.244a.875.875 0 00-1.212 0 .817.817 0 000 1.179L8.931 8l-3.68 3.577z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgArrowRight;
