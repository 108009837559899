import { FieldValues, UseFormWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';

export const useFormAutoSubmit = <TFieldValues extends FieldValues = FieldValues>(
  watch: UseFormWatch<TFieldValues>,
  onSubmit: (data: any) => void, //TODO: нужна правильная типизация
) => {
  const submitHandler = useRef(onSubmit);

  submitHandler.current = onSubmit;

  useEffect(() => {
    const subscription = watch(data => {
      submitHandler.current(data);
    });

    return () => subscription.unsubscribe();
  }, [watch]);
};
