import { Spinner, Table } from '@shared/ui';
import { formatPhoneString, getFullName, getTimeFromNow, formatTimezone } from '@shared/utils';
import {
  useGetVisitorsByIds,
  useGetVisitorsList,
  useSearchParamsVisitors,
  useVisitorsPosition,
} from '@pages/Visitors/hooks';

import { LayoutContent } from '@shared/widgets';
import { VisitorsSortFieldsEnum } from '@shared/store/types';

export const VisitorsListSection = () => {
  const { order, isSortOrderDesc, sort, page, setPage, handleSort } = useSearchParamsVisitors();

  const { visitorsIds } = useVisitorsPosition();

  const { visitors, isEmpty, isLoading, pageCount, hasPagination } = useGetVisitorsByIds({ ids: visitorsIds });
  //TODO: в этом списке отсутствуют "неопознаные", так как они фильтруются, нужно как-то их мержить? а как инфу по ним вытащить? только дата входа есть?
  const visitorsList = visitors && useGetVisitorsList(visitors, page, sort, order);

  if (isLoading) {
    return (
      <LayoutContent alignContentVariant="center">
        <Spinner />
      </LayoutContent>
    );
  }

  if (isEmpty || !visitorsList) {
    return <LayoutContent alignContentVariant="center" emptyHeading="Нет посетителей в офисе " />;
  }

  return (
    <LayoutContent>
      <Table
        hasPaginator={hasPagination}
        paginator={{ initialPage: page, pageCount: pageCount, onPageChange: setPage }}>
        <Table.THead>
          <Table.TSortableHeadCell
            defaultIsSortedDesc={sort === VisitorsSortFieldsEnum.ATTENDED_AT ? true : undefined}
            isSortedDesc={sort === VisitorsSortFieldsEnum.ATTENDED_AT ? isSortOrderDesc : undefined}
            onSort={() => handleSort(VisitorsSortFieldsEnum.ATTENDED_AT)}
            width={180}>
            Время прохода (GMT+3)
          </Table.TSortableHeadCell>
          <Table.TSortableHeadCell
            defaultIsSortedDesc={sort === VisitorsSortFieldsEnum.NAME ? true : undefined}
            isSortedDesc={sort === VisitorsSortFieldsEnum.NAME ? isSortOrderDesc : undefined}
            onSort={() => handleSort(VisitorsSortFieldsEnum.NAME)}
            width={340}>
            ФИО
          </Table.TSortableHeadCell>
          <Table.TSortableHeadCell
            defaultIsSortedDesc={sort === VisitorsSortFieldsEnum.PHONE ? true : undefined}
            isSortedDesc={sort === VisitorsSortFieldsEnum.PHONE ? isSortOrderDesc : undefined}
            onSort={() => handleSort(VisitorsSortFieldsEnum.PHONE)}
            width={160}>
            Телефон
          </Table.TSortableHeadCell>
          <Table.TSortableHeadCell
            defaultIsSortedDesc={sort === VisitorsSortFieldsEnum.COMPANY_NAME ? true : undefined}
            isSortedDesc={sort === VisitorsSortFieldsEnum.COMPANY_NAME ? isSortOrderDesc : undefined}
            onSort={() => handleSort(VisitorsSortFieldsEnum.COMPANY_NAME)}
            width={350}>
            Организация
          </Table.TSortableHeadCell>
          <Table.TSortableHeadCell
            defaultIsSortedDesc={sort === VisitorsSortFieldsEnum.DURATION ? true : undefined}
            isSortedDesc={sort === VisitorsSortFieldsEnum.DURATION ? isSortOrderDesc : undefined}
            onSort={() => handleSort(VisitorsSortFieldsEnum.DURATION)}
            width={150}>
            Время в офисе
          </Table.TSortableHeadCell>
          <Table.TSortableHeadCell
            defaultIsSortedDesc={sort === VisitorsSortFieldsEnum.ROLE ? true : undefined}
            isSortedDesc={sort === VisitorsSortFieldsEnum.ROLE ? isSortOrderDesc : undefined}
            onSort={() => handleSort(VisitorsSortFieldsEnum.ROLE)}
            width={160}>
            Роль
          </Table.TSortableHeadCell>
        </Table.THead>
        <Table.TBody>
          {visitorsList.map(visitor => (
            <Table.TRow key={visitor.id}>
              <Table.TCellFullDate date={formatTimezone(visitor.attendedAt)} />
              <Table.TCellTooltip maxStringChildrenLenght={32}>
                {getFullName({
                  firstName: visitor.firstName,
                  middleName: visitor.middleName,
                  lastName: visitor.lastName,
                })}
              </Table.TCellTooltip>
              <Table.TCell>{formatPhoneString(visitor?.phone)}</Table.TCell>
              <Table.TCellTooltip maxStringChildrenLenght={30}>
                {visitor.companyName ? visitor.companyName : '—'}
              </Table.TCellTooltip>
              <Table.TCell>{getTimeFromNow(visitor.attendedAt)}</Table.TCell>
              <Table.TCellRole role={visitor?.role ? visitor?.role : undefined} />
            </Table.TRow>
          ))}
        </Table.TBody>
      </Table>
    </LayoutContent>
  );
};
