import { AreasList, MapSection, VisitorsListSection } from '../ui';
import { LayoutHeader, Sidebar } from '@shared/widgets';
import { SidebarModal, Spinner } from '@shared/ui';
import { useClientSize, useTitle, useToggle } from '@shared/hooks';
import { useGetAreas, useOfficeMetrics, useSearchParamsVisitors } from '../hooks';

import { AuthPath } from '@shared/constants/routes';
import { CreateArea } from '@features/CreateArea';
import { NavLinkVariant } from '@shared/ui/NavLink/';
import { SearchForm } from '@features/SearchForm';
import { SidebarLayout } from '@shared/layouts';
import { TabProps } from '@shared/widgets/LayoutHeader/LayoutHeader';
import { Titles } from '@shared/constants/titles';
import { VISITORS_PAGE_HEADING } from '../constants';
import { VisitorViewEnum } from '../types';
import styles from './visitors.module.scss';
import { useGetAreaInfoById } from '../hooks/useGetAreaInfoById';

export const Visitors = () => {
  useTitle(Titles.Visitors);

  const { role, view, page, order, sort, search, handleSetView, handleSearch, reserSearchParams, setRole } =
    useSearchParamsVisitors();

  const { areas, activeArea, officeName, isAreasEmpty, handleSelectArea, refetchGetAreas } = useGetAreas({
    page,
    view,
    order,
    sort,
    search,
    reserSearchParams,
  });

  const { state: isSidebarModalOpen, toggle: toggleSidabarModal } = useToggle();

  const { areasWithVisitorsCount } = useOfficeMetrics({ areas });

  const { areaInfo, isGetAreaInfoLoading } = useGetAreaInfoById();

  if (areaInfo) console.log(areaInfo);

  const { getIsBreakpoint } = useClientSize();
  const isLaptop = getIsBreakpoint('laptop');

  const VISITORS_TABS: TabProps[] = [
    {
      title: 'На карте',
      parent: AuthPath.VISITORS,
      isTabActive: view === VisitorViewEnum.MAP,
      to: `${AuthPath.VISITORS}`,
      variantNavigation: NavLinkVariant.TAB_NAVIGATION,
      onNavigation: () => handleSetView(VisitorViewEnum.MAP),
    },
    {
      title: 'Списком',
      parent: AuthPath.VISITORS,
      isTabActive: view === VisitorViewEnum.LIST,
      to: `${AuthPath.VISITORS}`,
      variantNavigation: NavLinkVariant.TAB_NAVIGATION,
      onNavigation: () => handleSetView(VisitorViewEnum.LIST),
    },
  ];

  const VisitorsViewMapping = {
    [VisitorViewEnum.MAP]: (
      <MapSection
        areaInfo={areaInfo}
        isGetAreaInfoLoading={isGetAreaInfoLoading}
        activeRole={role}
        setActiveRole={setRole}
      />
    ),
    [VisitorViewEnum.LIST]: <VisitorsListSection />,
  };

  const hasRightAddonAreaName = activeArea?.name && !isLaptop;

  return (
    <>
      <SidebarLayout
        isSidebarModalOpen={isSidebarModalOpen}
        toggleSidabarModal={toggleSidabarModal}
        sidebar={
          <Sidebar
            headerTitle={activeArea?.name}
            contentTitle="Локации"
            headerSubtitle={officeName}
            contentList={
              <AreasList
                areas={areasWithVisitorsCount}
                handleSelectArea={area => {
                  handleSelectArea(area);
                  !isLaptop && toggleSidabarModal();
                }}
              />
            }
            contentActions={<CreateArea onSuccess={refetchGetAreas} />}
            isContentEmpty={isAreasEmpty}
            emptyContentTitle={`Пока не было добавлено ни\u00A0одной локации`}
          />
        }>
        <LayoutHeader
          tabs={VISITORS_TABS}
          title={VISITORS_PAGE_HEADING}
          rightAddons={
            <div className={styles.rightAddons}>
              <p className={styles.areaName}>{hasRightAddonAreaName ? activeArea?.name : ''}</p>
              <SearchForm childrenResults={<p></p>} defaultSearchValue={''} onSearch={handleSearch} />
            </div>
          }
        />
        {isGetAreaInfoLoading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : (
          <>{VisitorsViewMapping[view]}</>
        )}
      </SidebarLayout>
    </>
  );
};
