import { FC, ReactNode } from 'react';

import { AppHeader } from '@shared/widgets';
import { MobileScreen } from '@shared/ui/MobileScreen/MobileScreen';
import { useClientSize } from '@shared/hooks';
import { SidebarModal } from '@shared/ui';
import classNames from 'classnames';
import styles from './sidebarLayout.module.scss';

type SidebarLayoutVariants = 'primary' | 'secondary';

type SidebarLayoutProps = {
  children: ReactNode;
  sidebar: ReactNode;
  variant?: SidebarLayoutVariants;
  toggleSidabarModal: () => void;
  isSidebarModalOpen: boolean;
};

export const SidebarLayout: FC<SidebarLayoutProps> = ({
  children,
  sidebar,
  variant = 'primary',
  isSidebarModalOpen,
  toggleSidabarModal,
}) => {
  const classesDashboard = classNames(styles.dashboard, styles[variant]);

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('mobile');

  return (
    <div className={styles.app}>
      {isMobile ? (
        <>
          <div className={styles.wrapper}>
            <AppHeader onClickActionBar={toggleSidabarModal} isActionBarVisible={true} />
            <div className={styles.root}>
              <div className={styles.sidebar}>{sidebar}</div>
              <div className={classesDashboard}>
                <div className={styles.content}>{children}</div>
              </div>
            </div>
          </div>
          <SidebarModal isVisible={isSidebarModalOpen} closeCallback={toggleSidabarModal}>
            {sidebar}
          </SidebarModal>
        </>
      ) : (
        <MobileScreen />
      )}
    </div>
  );
};
