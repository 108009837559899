import axios from 'axios';

class AssetsService {
  loadToBucket = async (url: string, file: File, fileExtension: string) => {
    const contentType = fileExtension === 'svg' ? `image/svg+xml` : `image/png`;

    return await axios.put(`${url}`, file, {
      headers: {
        'Content-Type': contentType,
      },
    });
  };
}

export default new AssetsService();
