import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationAttention24 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g fill="#FAC031" fillRule="evenodd" clipPath="url(#NotificationAttention24_svg__a)" clipRule="evenodd">
      <path
        fillOpacity={0.25}
        d="M.106 9.232C.366 5.744.496 4 2.246 2.249 3.996.499 5.74.369 9.229.109a37.476 37.476 0 0 1 2.77-.108c.932 0 1.865.04 2.769.108C18.256.37 20 .5 21.751 2.25c1.75 1.75 1.88 3.495 2.14 6.983.067.904.108 1.837.108 2.77 0 .932-.041 1.865-.108 2.77-.26 3.487-.39 5.231-2.14 6.982-1.75 1.75-3.495 1.88-6.983 2.14-.904.067-1.837.108-2.77.108-.932 0-1.865-.04-2.77-.108-3.488-.26-5.232-.39-6.982-2.14S.366 18.259.106 14.77a37.474 37.474 0 0 1-.108-2.77c0-.932.04-1.865.108-2.77Z"
      />
      <path d="M12.001 13.608c.664 0 1.202-.568 1.202-1.268V7.269C13.202 6.568 12.664 6 12 6c-.663 0-1.2.568-1.2 1.269v5.07c0 .701.537 1.27 1.2 1.27Zm1.499 3.808c0-.875-.672-1.585-1.5-1.585s-1.5.71-1.5 1.585S11.172 19 12 19s1.5-.71 1.5-1.584Z" />
    </g>
    <defs>
      <clipPath id="NotificationAttention24_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotificationAttention24;
