import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCamera20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <rect
      width={12.109}
      height={11.302}
      x={0.807}
      y={4.037}
      stroke="#717680"
      strokeLinecap="round"
      strokeWidth={1.615}
      rx={3.229}
    />
    <path
      stroke="#717680"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.615}
      d="M19.167 5.833 13.333 10l5.834 4.166V5.833Z"
    />
  </svg>
);
export default SvgCamera20;
