import { SortOrderEnum, VisitorsSortFieldsEnum } from '@shared/store/types';
import { VisitorRoleEnum, VisitorViewEnum, VisitorsSearchParamsEnum } from '../types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { DEFAULT_INITIAL_PAGE } from '@shared/constants/pagination';

const DEFAULT_VISITORS_VIEW = VisitorViewEnum.MAP;
const DEFAULT_VISITORS_ORDER = SortOrderEnum.DESC;
const DEFAULT_VISITORS_SORT = VisitorsSortFieldsEnum.ATTENDED_AT;
const DEFAULT_VISITORS_SEARCH = '';
const DEFAULT_VISITORS_ROLE = '';

export const useSearchParamsVisitors = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const keysVisitorsView = Object.keys(VisitorViewEnum).map(v => String(v.toLowerCase()));
  const searchParamsView = searchParams.get(VisitorsSearchParamsEnum.VIEW);
  const hasSearchParamsView = searchParamsView !== null && keysVisitorsView.includes(searchParamsView);
  const defaultView = hasSearchParamsView ? (searchParamsView as VisitorViewEnum) : DEFAULT_VISITORS_VIEW;

  const defaultPage =
    searchParams.get(VisitorsSearchParamsEnum.PAGE) !== null
      ? searchParams.get(VisitorsSearchParamsEnum.PAGE)
      : (DEFAULT_INITIAL_PAGE as number);

  const keysOrder = Object.keys(SortOrderEnum).map(v => String(v.toLowerCase()));
  const searchParamsOrder = searchParams.get(VisitorsSearchParamsEnum.ORDER);
  const hasSearchParamsOrder = searchParamsOrder !== null && keysOrder.includes(searchParamsOrder);
  const defaultOrder = hasSearchParamsOrder ? (searchParamsOrder as SortOrderEnum) : DEFAULT_VISITORS_ORDER;

  const keysVisitorsSort = Object.keys(VisitorsSortFieldsEnum).map(v => String(v.toLowerCase()));
  const searchParamsSort = searchParams.get(VisitorsSearchParamsEnum.SORT);
  const hasSearchParamsSort = searchParamsSort !== null && keysVisitorsSort.includes(searchParamsSort);
  const defaultSort = hasSearchParamsSort ? (searchParamsSort as VisitorsSortFieldsEnum) : DEFAULT_VISITORS_SORT;

  const defaultSearch =
    searchParams.get(VisitorsSearchParamsEnum.SEARCH) !== null
      ? (searchParams.get(VisitorsSearchParamsEnum.SEARCH) as string)
      : DEFAULT_VISITORS_SEARCH;

  const keysRole = Object.keys(VisitorRoleEnum).map(v => String(v.toLowerCase()));
  const searchParamsRole = searchParams.get(VisitorsSearchParamsEnum.ROLE);
  const hasSearchParamsRole = searchParamsRole !== null && keysRole.includes(searchParamsRole);
  const defaultRole = hasSearchParamsRole ? (searchParamsRole as VisitorRoleEnum) : DEFAULT_VISITORS_ROLE;

  const [view, setView] = useState<VisitorViewEnum>(defaultView);
  const [page, setPage] = useState<number>(Number(defaultPage));
  const [order, setOrder] = useState<SortOrderEnum>(defaultOrder);
  const [sort, setSort] = useState<VisitorsSortFieldsEnum>(defaultSort);
  const [search, setSearch] = useState<string>(defaultSearch);
  const [role, setRole] = useState<VisitorRoleEnum | string>(defaultRole);

  useEffect(() => {
    const link = `${location.pathname}?${VisitorsSearchParamsEnum.VIEW}=${view}&${VisitorsSearchParamsEnum.PAGE}=${page}&${VisitorsSearchParamsEnum.ORDER}=${order}&${VisitorsSearchParamsEnum.SORT}=${sort}&${VisitorsSearchParamsEnum.ROLE}=${role}&${VisitorsSearchParamsEnum.SEARCH}=${search}`;
    navigate(link, { replace: true });
  }, [view, page, order, sort, role, search]);

  const isSortOrderDesc = order === SortOrderEnum.DESC;
  const newSortOrder = isSortOrderDesc ? SortOrderEnum.ASC : SortOrderEnum.DESC;

  const handleSetView = (view: VisitorViewEnum) => {
    setView(view);
    setPage(page);
    setOrder(order);
    setSort(sort);
    setSearch(search);
    setRole(role);
    setSearchParams({ view, page: page as unknown as string, order, sort, role, search });
  };

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  const handleSort = (sortField: VisitorsSortFieldsEnum) => {
    setPage(DEFAULT_INITIAL_PAGE);
    setSort(sortField);
    sort !== sortField ? setOrder(order) : setOrder(newSortOrder);
  };

  const reserSearchParams = () => {
    setView(DEFAULT_VISITORS_VIEW);
    setSearch(DEFAULT_VISITORS_SEARCH);
    setPage(DEFAULT_INITIAL_PAGE);
    setOrder(DEFAULT_VISITORS_ORDER);
    setSort(DEFAULT_VISITORS_SORT);
  };

  return {
    view,
    page,
    order,
    sort,
    search,
    role,
    isSortOrderDesc,
    handleSetView,
    setPage,
    handleSearch,
    handleSort,
    reserSearchParams,
    setRole,
  };
};
