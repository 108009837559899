import { JornalTabFilterEnum } from '@shared/store/types';

export const AuthPath = {
  DASHBOARD: '/dashboard',
  VIDEO_WALLS: '/video-walls',
  STREAMS: '/streams',
  JOURNAL: '/journal',
  BEACONS: '/beacons',
  VISITORS: '/visitors',
  NOTIFICATIONS: '/notifications',
  PASSES: '/passes',
};

export const UnauthPath = {
  SIGN_IN: '/sign-in',
};

export const JoutnalPath = {
  ALL: `${AuthPath.JOURNAL}/${JornalTabFilterEnum.ALL}`,
  SUSPICIOUS: `${AuthPath.JOURNAL}/${JornalTabFilterEnum.SUSPICIOUS}`,
};

export const NotificationsPath = {
  SUSPICIOUS: `${AuthPath.NOTIFICATIONS}/suspicious`,
  VERIFIED: `${AuthPath.NOTIFICATIONS}/verified`,
};

export const VisitorsPath = {
  VISITORS_BY_AREA_ID: (areaId = ':areaId'): string => `${AuthPath.VISITORS}/${areaId}`,
};

export const VideoWallPath = {
  VIDEO_WALL_BY_ID: (videoWallId = ':videoWallId'): string => `${AuthPath.VIDEO_WALLS}/${videoWallId}`,
  STREAM_BY_ID: (videoWallId = ':videoWallId', streamId = ':streamId'): string =>
    `${AuthPath.VIDEO_WALLS}/${videoWallId}/stream/${streamId}`,
};
