import { baseApiVideowall as api } from '../../store/api/baseApi_videowall';
export const addTagTypes = [
  'Prometheus',
  'Security Users Video Walls',
  'Security Users Cameras',
  'Security Users Areas',
  'Security Users Offices',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      appControllerGetHello: build.query<AppControllerGetHelloApiResponse, AppControllerGetHelloApiArg>({
        query: () => ({ url: `/` }),
      }),
      appControllerGetUserFromPayload: build.query<
        AppControllerGetUserFromPayloadApiResponse,
        AppControllerGetUserFromPayloadApiArg
      >({
        query: () => ({ url: `/iam` }),
      }),
      prometheusControllerWebhook: build.query<
        PrometheusControllerWebhookApiResponse,
        PrometheusControllerWebhookApiArg
      >({
        query: () => ({ url: `/metrics` }),
        providesTags: ['Prometheus'],
      }),
      videoWallsControllerCreateOne: build.mutation<
        VideoWallsControllerCreateOneApiResponse,
        VideoWallsControllerCreateOneApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/video-walls`,
          method: 'POST',
          body: queryArg.createVideoWallRequestDto,
        }),
        invalidatesTags: ['Security Users Video Walls'],
      }),
      videoWallsControllerFindById: build.query<
        VideoWallsControllerFindByIdApiResponse,
        VideoWallsControllerFindByIdApiArg
      >({
        query: queryArg => ({ url: `/v1/security-users/video-walls/${queryArg.id}` }),
        providesTags: ['Security Users Video Walls'],
      }),
      videoWallsControllerUpdateById: build.mutation<
        VideoWallsControllerUpdateByIdApiResponse,
        VideoWallsControllerUpdateByIdApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/video-walls/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateVideoWallRequestDto,
        }),
        invalidatesTags: ['Security Users Video Walls'],
      }),
      videoWallsControllerDeleteById: build.mutation<
        VideoWallsControllerDeleteByIdApiResponse,
        VideoWallsControllerDeleteByIdApiArg
      >({
        query: queryArg => ({ url: `/v1/security-users/video-walls/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Security Users Video Walls'],
      }),
      videoWallsControllerGetCamerasList: build.query<
        VideoWallsControllerGetCamerasListApiResponse,
        VideoWallsControllerGetCamerasListApiArg
      >({
        query: queryArg => ({ url: `/v1/security-users/video-walls/${queryArg.id}/cameras` }),
        providesTags: ['Security Users Video Walls'],
      }),
      camerasControllerFindById: build.query<CamerasControllerFindByIdApiResponse, CamerasControllerFindByIdApiArg>({
        query: queryArg => ({ url: `/v1/security-users/cameras/${queryArg.id}` }),
        providesTags: ['Security Users Cameras'],
      }),
      areasControllerGetAreaCameras: build.query<
        AreasControllerGetAreaCamerasApiResponse,
        AreasControllerGetAreaCamerasApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/areas/${queryArg.areaId}/cameras`,
          params: {
            searchQuery: queryArg.searchQuery,
            excludeCameraIds: queryArg.excludeCameraIds,
            type: queryArg['type'],
            order: queryArg.order,
            limit: queryArg.limit,
            page: queryArg.page,
          },
        }),
        providesTags: ['Security Users Areas'],
      }),
      officesControllerGetOfficeVideoWalls: build.query<
        OfficesControllerGetOfficeVideoWallsApiResponse,
        OfficesControllerGetOfficeVideoWallsApiArg
      >({
        query: queryArg => ({ url: `/v1/security-users/offices/${queryArg.officeId}/video-walls` }),
        providesTags: ['Security Users Offices'],
      }),
      officesControllerGetTargetCamera: build.query<
        OfficesControllerGetTargetCameraApiResponse,
        OfficesControllerGetTargetCameraApiArg
      >({
        query: queryArg => ({ url: `/v1/security-users/offices/${queryArg.officeId}/target-camera` }),
        providesTags: ['Security Users Offices'],
      }),
      officesControllerDeleteTargetCamera: build.mutation<
        OfficesControllerDeleteTargetCameraApiResponse,
        OfficesControllerDeleteTargetCameraApiArg
      >({
        query: queryArg => ({ url: `/v1/security-users/offices/${queryArg.officeId}/target-camera`, method: 'DELETE' }),
        invalidatesTags: ['Security Users Offices'],
      }),
      officesControllerSetTargetCamera: build.mutation<
        OfficesControllerSetTargetCameraApiResponse,
        OfficesControllerSetTargetCameraApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/offices/${queryArg.officeId}/target-camera/${queryArg.cameraIdFk}/camera`,
          method: 'PATCH',
        }),
        invalidatesTags: ['Security Users Offices'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as apiService_videowall };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type AppControllerGetUserFromPayloadApiResponse = /** status 200  */ object;
export type AppControllerGetUserFromPayloadApiArg = void;
export type PrometheusControllerWebhookApiResponse = unknown;
export type PrometheusControllerWebhookApiArg = void;
export type VideoWallsControllerCreateOneApiResponse = /** status 201  */ VideoWallResponseDto;
export type VideoWallsControllerCreateOneApiArg = {
  createVideoWallRequestDto: CreateVideoWallRequestDto;
};
export type VideoWallsControllerFindByIdApiResponse = /** status 200  */ VideoWallResponseDto;
export type VideoWallsControllerFindByIdApiArg = {
  id: string;
};
export type VideoWallsControllerUpdateByIdApiResponse = /** status 200  */ VideoWallResponseDto;
export type VideoWallsControllerUpdateByIdApiArg = {
  id: string;
  updateVideoWallRequestDto: UpdateVideoWallRequestDto;
};
export type VideoWallsControllerDeleteByIdApiResponse = unknown;
export type VideoWallsControllerDeleteByIdApiArg = {
  id: string;
};
export type VideoWallsControllerGetCamerasListApiResponse = /** status 200  */ CameraResponseDto[];
export type VideoWallsControllerGetCamerasListApiArg = {
  id: string;
};
export type CamerasControllerFindByIdApiResponse = /** status 200  */ CameraResponseDto;
export type CamerasControllerFindByIdApiArg = {
  id: string;
};
export type AreasControllerGetAreaCamerasApiResponse = /** status 200  */ AreaCamerasPaginatedResponseDto;
export type AreasControllerGetAreaCamerasApiArg = {
  areaId: string;
  searchQuery?: string;
  excludeCameraIds?: string[];
  type?: 'created_at' | 'name' | 'ip_address' | 'status';
  order?: 'asc' | 'desc';
  limit?: number;
  page?: number;
};
export type OfficesControllerGetOfficeVideoWallsApiResponse = /** status 200  */ VideoWallsListResponseDto;
export type OfficesControllerGetOfficeVideoWallsApiArg = {
  officeId: string;
};
export type OfficesControllerGetTargetCameraApiResponse = /** status 200  */ CameraResponseDto;
export type OfficesControllerGetTargetCameraApiArg = {
  officeId: string;
};
export type OfficesControllerDeleteTargetCameraApiResponse = /** status 200  */ boolean;
export type OfficesControllerDeleteTargetCameraApiArg = {
  officeId: string;
};
export type OfficesControllerSetTargetCameraApiResponse = /** status 200  */ CameraResponseDto;
export type OfficesControllerSetTargetCameraApiArg = {
  officeId: string;
  cameraIdFk: string;
};
export type CameraStatusKind = 'stand_by' | 'off' | 'on_capture' | 'error';
export type Point2ResponseDto = {
  x?: number;
  y?: number;
};
export type CameraResponseDto = {
  id: string;
  name: string;
  ipAddress: string;
  status: CameraStatusKind;
  previewImageUrl: string;
  defaultStreamUrl: string;
  maskedStreamUrl: string;
  predatorStreamUrl: string;
  coordinates: Point2ResponseDto;
};
export type VideoWallResponseDto = {
  id: string;
  name: string;
  description: string;
  officeId: string;
  securityUserId?: string | null;
  cameras: CameraResponseDto[];
};
export type CreateVideoWallRequestDto = {
  name: string;
  description: string;
  officeId: string;
  camerasIds?: string[];
};
export type UpdateVideoWallRequestDto = {
  name?: string;
  description?: string;
  camerasIds?: string[];
};
export type ResponseMetaDto = {
  count: number;
  total: number;
  page: number;
  pageCount: number;
  perPage: number;
};
export type AreaCamerasPaginatedResponseDto = {
  data: CameraResponseDto[];
  meta: ResponseMetaDto;
};
export type VideoWallsListItemDto = {
  id: string;
  name: string;
  camerasAmount: number;
};
export type VideoWallsListResponseDto = {
  videoWalls: VideoWallsListItemDto[];
};
export const {
  useAppControllerGetHelloQuery,
  useLazyAppControllerGetHelloQuery,
  useAppControllerGetUserFromPayloadQuery,
  useLazyAppControllerGetUserFromPayloadQuery,
  usePrometheusControllerWebhookQuery,
  useLazyPrometheusControllerWebhookQuery,
  useVideoWallsControllerCreateOneMutation,
  useVideoWallsControllerFindByIdQuery,
  useLazyVideoWallsControllerFindByIdQuery,
  useVideoWallsControllerUpdateByIdMutation,
  useVideoWallsControllerDeleteByIdMutation,
  useVideoWallsControllerGetCamerasListQuery,
  useLazyVideoWallsControllerGetCamerasListQuery,
  useCamerasControllerFindByIdQuery,
  useLazyCamerasControllerFindByIdQuery,
  useAreasControllerGetAreaCamerasQuery,
  useLazyAreasControllerGetAreaCamerasQuery,
  useOfficesControllerGetOfficeVideoWallsQuery,
  useLazyOfficesControllerGetOfficeVideoWallsQuery,
  useOfficesControllerGetTargetCameraQuery,
  useLazyOfficesControllerGetTargetCameraQuery,
  useOfficesControllerDeleteTargetCameraMutation,
  useOfficesControllerSetTargetCameraMutation,
} = injectedRtkApi;
