import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={44} fill="none" {...props}>
    <g clipPath="url(#Info_svg__a)">
      <path
        fill="#2A2B2F"
        fillRule="evenodd"
        d="M.694 16.925c.476-6.395.714-9.593 3.924-12.802C7.827.913 11.024.676 17.419.2a68.708 68.708 0 0 1 5.078-.198c1.71 0 3.42.074 5.078.198 6.395.476 9.592.714 12.802 3.923 3.209 3.21 3.447 6.407 3.923 12.802a68.69 68.69 0 0 1 .198 5.078c0 1.71-.075 3.42-.198 5.077-.476 6.395-.714 9.593-3.923 12.802-3.21 3.21-6.407 3.447-12.802 3.923a68.745 68.745 0 0 1-5.078.199c-1.71 0-3.42-.075-5.078-.199-6.395-.476-9.592-.714-12.801-3.923-3.21-3.21-3.448-6.407-3.924-12.802a68.703 68.703 0 0 1-.198-5.077c0-1.71.075-3.42.198-5.078"
        clipRule="evenodd"
      />
      <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M19.5 19h3v11" />
      <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M22.5 13h.01" />
    </g>
    <defs>
      <clipPath id="Info_svg__a">
        <path fill="#fff" d="M.5 0h44v44H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInfo;
