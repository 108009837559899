import {
  TBody,
  TCell,
  TCellBeaconType,
  TCellComment,
  TCellEntryType,
  TCellFullDate,
  TCellPassageType,
  TCellRole,
  TCellTooltip,
  TCellViolation,
  THead,
  THeadCell,
  TRow,
  TSortableHeadCell,
  Table as TableComponent,
} from './components';

export const Table = Object.assign(TableComponent, {
  TBody,
  THead,
  THeadCell,
  TCell,
  TCellViolation,
  TRow,
  TCellTooltip,
  TSortableHeadCell,
  TCellFullDate,
  TCellRole,
  TCellPassageType,
  TCellEntryType,
  TCellBeaconType,
  TCellComment,
});
