import { Dispatch, FC, SetStateAction } from 'react';

import { FilterVisitorsRole } from '@entities/FilterVisitorsRole';
import { VisitorRoleEnum } from '@pages/Visitors/types';
import styles from './mapFilters.module.scss';
import { useGetAreaMetrics } from '@pages/Visitors/hooks';

type MapFiltersProps = {
  activeRole: VisitorRoleEnum | string;
  setActiveRole: Dispatch<SetStateAction<VisitorRoleEnum | string>>;
};

export const MapFilters: FC<MapFiltersProps> = ({ activeRole, setActiveRole }) => {
  const { metrics } = useGetAreaMetrics();

  const handleFilterClick = (role: VisitorRoleEnum) => {
    if (activeRole === role) {
      setActiveRole('');
    } else {
      setActiveRole(role);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.filters}>
        {metrics.map(group => (
          <FilterVisitorsRole
            key={group.visitorRole}
            activeRole={activeRole}
            role={group.visitorRole}
            visitors={group.visitorCount}
            onFilterClick={() => handleFilterClick(group.visitorRole)}
          />
        ))}
      </div>
    </div>
  );
};
