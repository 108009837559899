import { FC, TdHTMLAttributes, useContext } from 'react';

import { TableContext } from '../table-context';
import classnames from 'classnames';
import styles from './index.module.scss';

export type TCellProps = TdHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  index?: number;
};

export const TCell: FC<TCellProps> = ({ className, style, children, index, ...props }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const classesCell = classnames(styles.component, className);

  return (
    <td className={classesCell} style={{ ...style, width }} {...props}>
      <div className={styles.inner}>{children}</div>
    </td>
  );
};
