import { ArrowDown, ArrowUp } from '@shared/assets/icons/components';
import { FC, useState } from 'react';

import { CameraResponseDto } from '@shared/services/apiService/apiService_videowall';
import { RadioButton } from '@shared/ui';
import classNames from 'classnames';
import plural from 'plural-ru';
import styles from './videoWallAccordeon.module.scss';

type VideoWallAccordeonProps = {
  name: string;
  streamsCount: number;
  cameras?: CameraResponseDto[];
  checked: boolean;
  onClick: (index: number) => void;
  index: number;
  activeIndex: number;
};

export const VideoWallAccordeon: FC<VideoWallAccordeonProps> = ({
  name,
  streamsCount,
  cameras,
  checked,
  onClick,
  index,
  activeIndex,
}) => {
  const isCamerasEmpty = streamsCount === 0;
  const streamsPlural = plural(streamsCount, 'камера', 'камеры', 'камер');

  const isContentVisible = index === activeIndex;

  const toggleContentVisibility = () => {
    onClick(index);
  };

  const classesRoot = classNames(styles.root, { [styles.rootActive]: checked });
  const classesDivider = classNames(styles.divider, {
    [styles.dividerIsVisible]: !checked && index !== 0 && index !== activeIndex + 1,
  });

  const hasCamerasList = checked && !isCamerasEmpty;

  return (
    <>
      <div className={classesRoot} onClick={toggleContentVisibility}>
        <div className={styles.radioButton}>
          <RadioButton name={name} checked={checked} />
        </div>
        <div className={styles.content}>
          <span className={classesDivider} />
          <div className={styles.nameContainer}>
            <p className={styles.name}>{name}</p>
            {isContentVisible ? <ArrowUp /> : <ArrowDown />}
          </div>
          <p className={styles.streamsCount}>
            {isCamerasEmpty ? 'Камеры не добавлены' : `${streamsCount} ${streamsPlural}`}
          </p>
          {hasCamerasList && isContentVisible && (
            <div className={styles.streamsContainer}>
              {cameras?.map(stream => (
                <div key={stream.id} className={styles.streamContainer}>
                  <div
                    className={classNames(styles.streamIndicator, {
                      [styles.streamIndicatorInactive]: stream.status === 'off' || stream.status === 'error',
                    })}
                  />
                  <p className={styles.streamName}>{stream.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
