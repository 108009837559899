import { ChangeEvent, forwardRef, useRef } from 'react';

import { SwitchProps } from './types';
import classNames from 'classnames';
import { mergeRefs } from 'react-merge-refs';
import styles from './switch.module.scss';

export const Switch = forwardRef<HTMLLabelElement, SwitchProps>(
  ({ size = 'small', checked = false, disabled, label, name, value, className, onChange, ...props }, ref) => {
    const labelRef = useRef<HTMLLabelElement>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e, { checked: e.target.checked, name });
      }
    };

    const classesLabel = classNames(
      styles.root,
      {
        [styles.disabled]: disabled,
      },
      className,
    );

    const classesSwitch = classNames(styles.switch, styles[size], { [styles.switchChecked]: checked });

    return (
      <label className={classesLabel} ref={mergeRefs([labelRef, ref])}>
        <input
          type="checkbox"
          onChange={handleChange}
          disabled={disabled}
          checked={checked}
          name={name}
          value={value}
          {...props}
        />
        <span className={classesSwitch} />
        {label && <span className={styles.label}>{label}</span>}
      </label>
    );
  },
);
