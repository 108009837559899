import { EntryTypeEnum, PassageTypeEnum } from '@shared/store/types';
import { Spinner, Table } from '@shared/ui';
import { formatPhoneString, formatTimezone, getFullName } from '@shared/utils';

import { DEFAULT_INITIAL_PAGE } from '@shared/constants/pagination';
import { LayoutContent } from '@shared/widgets';
import { Link } from 'react-router-dom';
import styles from './dashboardJournal.module.scss';
import { useClientSize } from '@shared/hooks';
import { useGetAttendanceLog } from '@pages/Journal/hooks';
import { useState } from 'react';

export const DashboardJournal = () => {
  const [page, setPage] = useState<number>(DEFAULT_INITIAL_PAGE);

  const { attendanceLogs, pageCount, isLoading, isEmpty, hasPagination } = useGetAttendanceLog({
    page,
    search: undefined,
  });

  const { getIsBreakpoint } = useClientSize();
  const isDesktop = getIsBreakpoint('desktop');

  if (isLoading) {
    return (
      <LayoutContent alignContentVariant="center">
        <Spinner />
      </LayoutContent>
    );
  }

  if (isEmpty || !attendanceLogs) {
    return <LayoutContent alignContentVariant="center" emptyHeading="Посещений офиса не было" />;
  }

  return (
    <LayoutContent>
      <div className={styles.header}>
        <p className={styles.heading}>
          <Link to={'/journal/all'}>Журнал посещений</Link>
        </p>
      </div>
      <Table
        hasPaginator={false}
        paginator={{
          initialPage: page,
          pageCount: pageCount,
          onPageChange: setPage,
          classNameTablePaginator: styles.paginator,
        }}
        className={styles.table}
        hasTopBorder={false}>
        <Table.THead heightRowVariant="smallRow">
          <Table.THeadCell rowVariant="secondary">Время прохода (GMT+3)</Table.THeadCell>
          <Table.THeadCell rowVariant="secondary">Тип прохода</Table.THeadCell>
          <Table.THeadCell rowVariant="secondary">ФИО</Table.THeadCell>
          <Table.THeadCell rowVariant="secondary">Телефон</Table.THeadCell>
          <Table.THeadCell rowVariant="secondary">Организация</Table.THeadCell>
          <Table.THeadCell rowVariant="secondary">Турникет</Table.THeadCell>
          <Table.THeadCell rowVariant="secondary">Статус прохода</Table.THeadCell>
        </Table.THead>
        <Table.TBody>
          {attendanceLogs.map(visitor => {
            return (
              <Table.TRow key={visitor.attendanceLog?.id}>
                <Table.TCellFullDate date={formatTimezone(visitor.attendanceLog?.createdAt)} />
                <Table.TCellEntryType entryType={visitor.accessPointInfo?.type as EntryTypeEnum.ENTRANCE} />
                <Table.TCellTooltip maxStringChildrenLenght={isDesktop ? 28 : 10}>
                  {getFullName({
                    firstName: visitor.visitorInfo?.firstName,
                    lastName: visitor.visitorInfo?.lastName,
                    middleName: visitor.visitorInfo?.middleName,
                  })}
                </Table.TCellTooltip>
                <Table.TCellTooltip maxStringChildrenLenght={isDesktop ? 28 : 10}>
                  {formatPhoneString(visitor.visitorInfo?.phone)}
                </Table.TCellTooltip>
                {/* TODO: дописать бэк, чтобы отдавал id компании  */}
                <Table.TCell>МТС</Table.TCell>
                <Table.TCellTooltip maxStringChildrenLenght={isDesktop ? 28 : 10}>
                  {visitor.accessPointInfo?.name || '—'}
                </Table.TCellTooltip>
                <Table.TCellPassageType
                  passageType={
                    visitor.attendanceLog?.suspiciousActivity === null
                      ? PassageTypeEnum.DEFAULT
                      : PassageTypeEnum.SUSPICIOUS
                  }
                />
              </Table.TRow>
            );
          })}
        </Table.TBody>
      </Table>
    </LayoutContent>
  );
};
