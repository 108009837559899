import * as React from 'react';
function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#fff" strokeWidth={2} strokeLinecap="round">
        <path d="M12.667 8H3.333M8 12.667V3.333" />
      </g>
    </svg>
  );
}
export default SvgPlus;
