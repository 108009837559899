import { AuthPath, JoutnalPath, NotificationsPath } from '@shared/constants/routes';

export const APP_NAVIGATION = [
  {
    title: 'Главная',
    parent: AuthPath.DASHBOARD,
    to: AuthPath.DASHBOARD,
  },
  {
    title: 'Видеостены',
    parent: AuthPath.VIDEO_WALLS,
    to: AuthPath.VIDEO_WALLS,
  },
  {
    title: 'Посетители',
    parent: AuthPath.VISITORS,
    to: AuthPath.VISITORS,
  },
  {
    title: 'Журнал',
    parent: AuthPath.JOURNAL,
    to: JoutnalPath.ALL,
  },
  {
    title: 'Уведомления',
    parent: AuthPath.NOTIFICATIONS,
    to: NotificationsPath.SUSPICIOUS,
  },
];
