import * as React from 'react';

import type { SVGProps } from 'react';
const MobileScreenSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg width="95" height="96" viewBox="0 0 95 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M66.7329 80.2247C66.7499 81.5195 66.2533 82.7684 65.3518 83.698C64.4503 84.6277 63.2174 85.1624 61.9226 85.1853H33.0764C31.7816 85.1624 30.5486 84.6277 29.6472 83.698C28.7457 82.7684 28.2491 81.5195 28.266 80.2247V15.7756C28.2491 14.4808 28.7457 13.2319 29.6472 12.3023C30.5486 11.3726 31.7816 10.8378 33.0764 10.8149H61.9226C63.2174 10.8378 64.4503 11.3726 65.3518 12.3023C66.2533 13.2319 66.7499 14.4808 66.7329 15.7756V80.2247Z"
      stroke="#717680"
      strokeWidth="1.96891"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.6548 18.5051H51.345"
      stroke="#717680"
      strokeWidth="1.96891"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.6269 80.0588C48.3348 80.0588 48.9086 79.4849 48.9086 78.7771C48.9086 78.0692 48.3348 77.4954 47.6269 77.4954C46.9191 77.4954 46.3452 78.0692 46.3452 78.7771C46.3452 79.4849 46.9191 80.0588 47.6269 80.0588Z"
      stroke="#717680"
      strokeWidth="1.96891"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.0957 84.4246L84.8736 11.6467" stroke="#8A8C99" strokeOpacity="0.45" strokeLinecap="round" />
  </svg>
);
export default MobileScreenSupport;
