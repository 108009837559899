import * as React from 'react';
function SvgVision(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#717680" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
        <path d="M8 15A7 7 0 108 1a7 7 0 000 14z" />
        <path d="M8 10.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
      </g>
    </svg>
  );
}
export default SvgVision;
