import { RadioButtonArea } from '../RadioButtonArea';
import { SecurityUserAreasListItemResponseDto } from '@shared/services/apiService/apiService_base';
import { SecurityUserAreasWithVisitorCountListItemResponseDto } from '@pages/Visitors/types';
import { useParams } from 'react-router-dom';

type AreasListProps = {
  areas?: SecurityUserAreasWithVisitorCountListItemResponseDto[];
  handleSelectArea: (area: SecurityUserAreasListItemResponseDto) => void;
};

export const AreasList = ({ areas, handleSelectArea }: AreasListProps) => {
  const { areaId } = useParams();

  if (!areas) {
    return null;
  }

  //TODO: сортировка по порядковому номеру с бэка или сделать drag'n'drop на фронте и запоминать порядок локаций в localstorage юзера
  
  // сортируем названия локаций по возрастанию
  areas.sort((a, b) => {
    const aNum = parseInt(a.name);
    const bNum = parseInt(b.name);
    return aNum - bNum;
  });

  return (
    <>
      {areas.map((area, index) => (
        <RadioButtonArea
          key={area.id}
          checked={area.id === areaId}
          areaName={area.name}
          visitorsCount={area.visitorCount}
          onClick={() => handleSelectArea(area)}
          withBorder={areas.length - 1 !== index}
        />
      ))}
    </>
  );
};
