import * as React from 'react';

import type { SVGProps } from 'react';
const SvgDone36 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7900_67911)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.158696 13.8475C0.548102 8.61536 0.742805 5.99927 3.36866 3.37341C5.99451 0.747561 8.61061 0.552859 13.8428 0.163455C15.1991 0.0625114 16.5982 0.00134277 17.9973 0.00134277C19.3964 0.00134277 20.7955 0.0625125 22.1518 0.163457C27.384 0.552863 30.0001 0.747566 32.626 3.37342C35.2518 5.99927 35.4465 8.61537 35.8359 13.8476C35.9369 15.2039 35.998 16.603 35.998 18.0021C35.998 19.4012 35.9369 20.8003 35.8359 22.1566C35.4465 27.3888 35.2518 30.0049 32.626 32.6307C30.0001 35.2566 27.384 35.4513 22.1518 35.8407C20.7955 35.9416 19.3964 36.0028 17.9973 36.0028C16.5982 36.0028 15.1991 35.9416 13.8428 35.8407C8.6106 35.4513 5.99451 35.2566 3.36865 32.6307C0.742801 30.0049 0.548098 27.3888 0.158694 22.1566C0.0577507 20.8003 -0.00341797 19.4012 -0.00341797 18.0021C-0.00341797 16.603 0.0577517 15.2039 0.158696 13.8475Z"
        fill="#54AB57"
        fill-opacity="0.25"
      />
      <path
        d="M10.5 17.7353L15.0162 21.8796C15.3986 22.2305 15.986 22.2305 16.3684 21.8796L25.5 13.5"
        stroke="#63C966"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7900_67911">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDone36;
