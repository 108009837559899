import { RefObject, useCallback, useEffect, useState } from 'react';

export type Size = {
  width: number;
  height: number;
};

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  ElemetRef: RefObject<T>,
  trigger?: string,
): [Size] {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ElemetRef.current?.offsetWidth || 0,
      height: ElemetRef.current?.offsetHeight || 0,
    });
  }, [ElemetRef.current?.offsetHeight, ElemetRef.current?.offsetWidth]);

  useEffect(() => {
    handleSize();
  }, [trigger, ElemetRef.current?.offsetWidth]);

  return [size];
}
