import { CloseIconWhite24, DropzoneFileUpload } from '@shared/assets/icons/components';
import { FC, useCallback, useEffect, useState } from 'react';
import { getFileExtension, getFileNameFromUrl } from '@shared/utils';

import { AssetsService } from '@shared/services/assetsService';
import { Button } from '../Button';
import { DropzoneProps } from './types';
import { Spinner } from '../Spinner';
import classNames from 'classnames';
import styles from './dropzone.module.scss';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

export const Dropzone: FC<DropzoneProps> = ({ name = '', loading, label, error, className, onFileRead, ...props }) => {
  const { register, setValue, setError } = useFormContext();

  const [filesData, setFilesData] = useState<File[]>([]);

  const hasFiles = filesData.length > 0;
  const hasError = !!error;

  const onDrop = useCallback(async (droppedFiles: File[]) => {
    const data = await onFileRead?.(droppedFiles);

    if (data === null) {
      return;
    }

    const getPresignedPutUrlResponse = data as unknown as { data: { presignedUrl: string } };

    const presignedPutUrl = getPresignedPutUrlResponse.data.presignedUrl;

    if (presignedPutUrl) {
      try {
        await AssetsService.loadToBucket(presignedPutUrl, droppedFiles[0], getFileExtension(droppedFiles[0]));
        setFilesData(droppedFiles);
        setValue(name, getFileNameFromUrl(presignedPutUrl), {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      } catch (error) {
        setError(name, {
          message: 'Не удалось загрузить файл',
        });
      }
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/svg': ['.svg'],
      'image/png': ['.png'],
    },
  });

  useEffect(() => {
    if (fileRejections.length !== 0) {
      setError(name, {
        message: 'Тип файла должен быть svg или png',
      });
    }
  }, [fileRejections, name]);

  const handleResetFiles = () => {
    setFilesData([]);
    setValue(name, '', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const classesRoot = classNames(styles.root, className);

  return (
    <div className={classesRoot}>
      {label && <label className={styles.label}>{label}</label>}
      {hasFiles ? (
        filesData.map(file => (
          <div className={styles.attachment}>
            <p key={uuidv4()} className={styles.fileName}>
              {file.name}
            </p>
            <div className={styles.resetButton} onClick={handleResetFiles}>
              <CloseIconWhite24 />
            </div>
          </div>
        ))
      ) : (
        <>
          <div className={styles.dropzone} {...getRootProps()}>
            <input {...register(name)} {...props} {...getInputProps()} />
            <div className={styles.inner}>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <DropzoneFileUpload />
                  <p className={styles.placeholder}>
                    Переместите сюда файл для загрузки <br />
                    или
                  </p>
                  <Button className={styles.button}>Выберите на компьютере</Button>
                </>
              )}
            </div>
          </div>
          {hasError && <span className={styles.errorText}>{error?.message}</span>}
        </>
      )}
    </div>
  );
};
