import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { schemaResolverCreateVideoWall } from '../constants';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from '@shared/hooks';
import { useVideoWallsControllerCreateOneMutation } from '@shared/services/apiService/apiService_videowall';
import { yupResolver } from '@hookform/resolvers/yup';

type CreateVideoWallFormValue = {
  name: string;
  description: string;
};

export const useCreateVideoWall = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<CreateVideoWallFormValue>({
    resolver: yupResolver(schemaResolverCreateVideoWall),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const officeId = activeOffice && activeOffice.id;

  const [createVideoWall, { isSuccess, isLoading, isError }] = useVideoWallsControllerCreateOneMutation();

  const onSubmit = handleSubmit(async (data: CreateVideoWallFormValue) => {
    if (!officeId) {
      return;
    }

    await createVideoWall({
      createVideoWallRequestDto: {
        name: data.name,
        description: data.description,
        officeId: officeId,
      },
    });
  });

  return {
    control,
    errors,
    isValid,
    onSubmit,
    reset,
    isLoading,
    isSuccess,
    isError
  };
};
