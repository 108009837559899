import * as React from 'react';
import type { SVGProps } from 'react';
const SvgShevronDown20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m5.5 8.5 3.341 2.924a1 1 0 0 0 1.318 0L13.5 8.5"
    />
  </svg>
);
export default SvgShevronDown20;
