import { FC, ReactNode } from 'react';

import { UfoIcon } from '@shared/assets/icons/components';
import classNames from 'classnames';
import styles from './layoutContent.module.scss';

type LayoutContentProps = {
  children?: ReactNode;
  emptyHeading?: string;
  alignContentVariant?: 'start' | 'center';
  iconSize?: string;
};

export const LayoutContent: FC<LayoutContentProps> = ({
  emptyHeading = '',
  alignContentVariant = 'start',
  children,
  iconSize,
}) => {
  const classesRoot = classNames(styles.root, styles[alignContentVariant]);

  if (emptyHeading) {
    return (
      <div className={classesRoot}>
        <div className={iconSize === 'small' ? styles.iconSmall : ''}>
          <UfoIcon />
        </div>
        <span className={styles.emptyHeading}>{emptyHeading}</span>
      </div>
    );
  }

  return <div className={classesRoot}>{children}</div>;
};
