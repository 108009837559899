import OvenPlayer from 'ovenplayer';
import { useEffect } from 'react';

export const WebRtcPlayer = ({ activeStream, playerRef, index }) => {
  let player = null;

  const defaultConfig = {
    autoStart: true,
    autoFallback: true,
    controls: false,
    showBigPlayButton: false,
    mute: true,
    expandFullScreenUI: false,
    webrtcConfig: {
      timeoutMaxRetry: 10000,
      connectionTimeout: 10000,
    },
  };

  const outputConfig = {
    ...defaultConfig,
    sources: [
      {
        type: 'webrtc',
        file: activeStream.streamUrl,
      },
    ],
  };

  const cameraModes = ['raw', 'mask', 'predator'];

  useEffect(() => {
    let activePlayers = OvenPlayer.getPlayerList();
    if (activePlayers.length > 0) {
      let source;

      cameraModes.map(mode => {
        if (activeStream.streamUrl.includes('/' + mode)) {
          if (activePlayers[index]) {
            source = [
              {
                type: mode,
                file: activeStream.streamUrl,
              },
            ];
            activePlayers[index].load(source);
          }
        }
      });
    }

    if (playerRef.current) {
      player = OvenPlayer.create(playerRef.current, outputConfig);

      return () => {
        if (player) {
          player.stop();

          if (playerRef.current && playerRef.current.firstChild) {
            playerRef.current.removeChild(playerRef.current.firstChild);
          }
          playerRef.current = null;
          player = null;
        }
      };
    }
  }, [activeStream, playerRef]);

  return (
    <div ref={playerRef}>
      <div className="ovenplayer" />
    </div>
  );
};
