import { ContextMenuItemsProps, ContextMenuProps, ContextMenuVariantEnum } from './types';
import { Done16, Shevron12 } from '@shared/assets/icons/components';
import { Popover, RefPopoverAnchorElement } from '../Popover';

import { Switch } from '../Switch';
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './contextMenu.module.scss';

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ className, isVisible, menu, anchorElement, variant = 'primary', position = 'bottom-start' }, ref) => {
    const classesRoot = classNames(styles.root, styles[variant], className);

    const renderMenuAction = (menuItem: ContextMenuItemsProps, variant: ContextMenuVariantEnum) => {
      switch (variant) {
        case ContextMenuVariantEnum.DEFAULT:
          return <></>;
        case ContextMenuVariantEnum.ACTION:
          return (
            <div className={styles.iconLink}>
              <Shevron12 />
            </div>
          );
        case ContextMenuVariantEnum.SWITCH:
          return <Switch checked={menuItem.value} onChange={menuItem.onClick} />;
        case ContextMenuVariantEnum.SELECT:
          return <div className={styles.iconLink}>{menuItem.value && <Done16 />}</div>;
        default:
          <></>;
      }
    };

    return (
      <Popover
        ref={ref}
        className={classesRoot}
        isVisible={isVisible}
        anchorElement={anchorElement as RefPopoverAnchorElement}
        position={position}>
        <div className={styles.menu}>
          {menu.map((menuItem, index) => (
            <button key={index} className={styles.button} onClick={menuItem.onClick}>
              <div className={styles.icon}>{menuItem.icon}</div>
              <div className={classNames(styles.content, { [styles.contentLast]: menu.length - 1 === index })}>
                <p className={styles.title}>{menuItem.title}</p>
                {menuItem.variant && renderMenuAction(menuItem, menuItem.variant)}
              </div>
            </button>
          ))}
        </div>
      </Popover>
    );
  },
);
