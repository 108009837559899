export enum VisitorRoleEnum {
  EMPLOYEE = 'employee',
  GUEST = 'guest',
  CONTRACTOR = 'contractor',
  UNIDENTIFIED = 'unrecognized',
  SECURITY = 'security',
}

export enum VisitorsSearchParamsEnum {
  VIEW = 'view',
  PAGE = 'page',
  ORDER = 'order',
  SORT = 'sort',
  SEARCH = 'search',
  ROLE = 'role',
}

export enum VisitorViewEnum {
  MAP = 'map',
  LIST = 'list',
}

export interface VisitorLocation {
  id: string;
  name: string;
  office: string;
  visitors: number;
}

export type Visitor = {
  id: string;
  fullname: string;
  phone: string;
  role: VisitorRoleEnum;
  startX?: number;
  startY?: number;
  company?: string | undefined;
  accessTimestamp: string;
  avatarEmployee?: string | undefined;
  hostFullname?: string | undefined;
  hostAvatar?: string;
  staffCompanyName?: string;
};

export type VisitorPositionResponseDto = {
  visitorUuid: string;
  seenTime: string;
  x: number;
  y: number;
  r: number;
};

export type VisitorMarkerType = {
  role: VisitorRoleEnum;
} & VisitorPositionResponseDto;

export interface AreaVisitorsMetricsProps {
  visitorRole: VisitorRoleEnum;
  visitorCount: number;
}

export type AreaMetricsProps = {
  areaUuid: string;
  metrics: AreaVisitorsMetricsProps[];
};

export type OfficeVisitorsMetricsProps = {
  areaUuid: string;
  visitorRole: VisitorRoleEnum;
  visitorCount: number;
};

export type OfficeMetricsProps = {
  officeUuid: string;
  metrics: OfficeVisitorsMetricsProps[];
};

export type SecurityUserAreasWithVisitorCountListItemResponseDto = {
  id: string;
  name: string;
  visitorCount: number;
};
