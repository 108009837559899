import { REACT_APP_API_BASE_URL } from '@shared/constants/environments';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import queryString from 'query-string';
import { store } from '@shared/store';

export const baseQueryBase = fetchBaseQuery({
  baseUrl: REACT_APP_API_BASE_URL || 'localhost',
  prepareHeaders: headers => {
    const token = store.getState().auth.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      return headers;
    }
    return headers;
  },
  paramsSerializer: params => {
    return queryString.stringify(params);
  },
});
