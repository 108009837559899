import { useTitle, useToggle } from '@shared/hooks';

import { PredatorStream } from '@features/PredatorStream';
import { Sidebar } from '@shared/widgets';
import { SidebarLayout } from '@shared/layouts';
import { Spinner } from '@shared/ui';
import { Titles } from '@shared/constants/titles';
import styles from './streamPage.module.scss';
import { useGetCameraById } from '@pages/VideoWalls/hooks';

export const StreamPage = () => {
  useTitle(Titles.Cameras);

  const { state: isSidebarModalOpen, toggle: toggleSidabarModal } = useToggle();

  const { camerasListData, videoWallData, cameraInfo, isLoading, officeAddress } = useGetCameraById();

  const isStreamDataLoading = isLoading || !cameraInfo;

  const hasStockVideoWall = !videoWallData?.securityUserId;
  const hasLastCameraVideoWall = camerasListData?.length === 1;

  return (
    <SidebarLayout
      toggleSidabarModal={toggleSidabarModal}
      isSidebarModalOpen={isSidebarModalOpen}
      variant="secondary"
      sidebar={
        <Sidebar
          headerTitle={cameraInfo?.name}
          headerSubtitle={officeAddress}
          contentList={''}
          contentActions={''}
          hasBackOption
        />
      }>
      {isStreamDataLoading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : (
        <PredatorStream stream={cameraInfo} hasStockVideoWall={hasStockVideoWall || hasLastCameraVideoWall} />
      )}
    </SidebarLayout>
  );
};
