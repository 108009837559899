import {
  useSecurityUsersOfficesV2ControllerActivateEmergencyModeMutation,
  useSecurityUsersOfficesV2ControllerDeactivateEmergencyModeMutation,
} from '@shared/services/apiService/apiService_base';

export const useEmergency = () => {
  const [activateEmergency, { isLoading: isActivateLoading, isSuccess: isActivateSuccess }] =
    useSecurityUsersOfficesV2ControllerActivateEmergencyModeMutation();

  const [deactivateEmergency, { isLoading: isDeactivateLoading, isSuccess: isDeactivateSuccess }] =
    useSecurityUsersOfficesV2ControllerDeactivateEmergencyModeMutation();

  return {
    isActivateLoading,
    isActivateSuccess,
    activateEmergency,
    isDeactivateLoading,
    isDeactivateSuccess,
    deactivateEmergency,
  };
};
