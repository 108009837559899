import {
  useOfficesControllerDeleteTargetCameraMutation,
  useOfficesControllerSetTargetCameraMutation,
} from '@shared/services/apiService/apiService_videowall';

import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useLocalStorage } from '@shared/hooks';

export const useUpdateTargetDashboardCamera = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const [deleteTargetCamera, { isSuccess: isSuccessDeleteTargetCamera }] =
    useOfficesControllerDeleteTargetCameraMutation();

  const handleDeleteCamera = async () => {
    officeId &&
      (await deleteTargetCamera({
        officeId: officeId,
      }));
  };

  const [updateTargetCamera, { isSuccess: isSuccessUpdateTargetCamera }] =
    useOfficesControllerSetTargetCameraMutation();

  const handleUpdateCamera = async (swapCameraId: string) => {
    officeId &&
      (await deleteTargetCamera({
        officeId: officeId,
      }));

    officeId &&
      (await updateTargetCamera({
        officeId: officeId,
        cameraIdFk: swapCameraId,
      }));
  };

  return {
    isSuccessUpdateTargetCamera,
    isSuccessDeleteTargetCamera,
    handleUpdateCamera,
    handleDeleteCamera,
  };
};
