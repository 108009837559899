import { FC, ReactNode } from 'react';

import { createPortal } from 'react-dom';
import { useIsMounted } from '@shared/hooks';

type PortalProps = {
  children: ReactNode;
};

export const Portal: FC<PortalProps> = ({ children }) => {
  return useIsMounted() ? createPortal(children, document.querySelector('#root') as Element) : null;
};
