import { RefObject, useMemo } from 'react';

export const useRotate = (
  canvasRef: RefObject<HTMLCanvasElement>,
  mapImageRef: RefObject<HTMLImageElement>,
  redraw: () => void,
) => {
  const rotate = useMemo(
    () => (degree: number) => {
      let rotateDegree = 0;

      function step() {
        if (!canvasRef.current || !mapImageRef.current) {
          return;
        }

        const context = canvasRef.current.getContext('2d');

        if (!context) {
          return;
        }

        const angle = degree > 0 ? 2 : -2;

        context.rotate((angle * Math.PI) / 180);

        redraw();

        if (rotateDegree <= 86) {
          window.requestAnimationFrame(step);
          rotateDegree = rotateDegree + 2;
        }
      }

      window.requestAnimationFrame(step);
    },
    [redraw],
  );

  return rotate;
};
