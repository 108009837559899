export const INITIAL_CONTAINMENT_SCALE = 1;

/* В SCALE_FACTOR Должны быть значения сокращаемых дробей, иначе scale не будет возврщаться к изначальному значению */
export const SCALE_UP_FACTOR = 102 / 100;
export const SCALE_DOWN_FACTOR = 1 / SCALE_UP_FACTOR;

export const INITIAL_SCALE_COUNTER = 100;
export const MIN_SCALE_COUNTER = 80;
export const MAX_SCALE_COUNTER = 180;

export const SWITCH_MARKER_SCALE_COUNTER = 140;
export const SCALE_COUNTER_SHIFT = 10;
export const CANVAS_ZOOM_SHIFT = 1;
export const ROTATE_LEFT_DEGREE = 90;
export const ROTATE_RIGHT_DEGREE = -90;

export const MAP_MARKER_DEFAULT_RADIUS = 20;
export const MAP_MARKER_ACTIVE_RADIUS = 16;
export const MAP_MARKER_AVATAR_RADIUS = 20;
export const MAP_MARKER_AVATAR_BORDER = 2;
