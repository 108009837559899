import 'dayjs/locale/ru';

import { FC, ReactNode } from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale('ru');

type DayjsProviderProps = {
  children: ReactNode;
};

export const DayjsProvider: FC<DayjsProviderProps> = ({ children }) => {
  return <>{children}</>;
};
