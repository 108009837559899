import { FC, useRef } from 'react';
import { useLockedBody, useOutsideClick } from '@shared/hooks';

import { CloseIcon } from '@shared/assets/icons/components';
import { ModalProps } from './types';
import { Portal } from '@shared/hocs/Portal';
import classNames from 'classnames';
import styles from './modal.module.scss';

export const Modal: FC<ModalProps> = ({
  isVisible,
  children,
  title,
  width,
  containerVariant = 'defaultContainer',
  hasHeader = true,
  containerStyles,
  closeCallback,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // useOutsideClick(ref, undefined, closeCallback);
  useLockedBody(isVisible);

  const classesContainer = classNames(styles.container, styles[containerVariant], containerStyles);

  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.root}>
        <div className={classesContainer} ref={ref} style={{ width }}>
          {hasHeader && (
            <div className={styles.headerContainer}>
              <p className={styles.title}>{title ? title : ''}</p>
              <button className={styles.closeButton} onClick={closeCallback}>
                <CloseIcon />
              </button>
            </div>
          )}
          {children && children}
        </div>
      </div>
    </Portal>
  );
};
