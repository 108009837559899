import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenu15 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#fff"
      d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM8 4.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM8 14.75a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
  </svg>
);
export default SvgMenu15;
