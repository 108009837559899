export function getFileNameFromUrl(url: string) {
  const fullUrl = new URL(url);

  const pathName = fullUrl.pathname;

  const pathNameSplitted = pathName.split('/');
  const pathNameSplittedLenght = pathName.split('/').length;
  const pathNameSplittedLastItem = pathNameSplittedLenght - 1;

  return pathNameSplitted[pathNameSplittedLastItem];
}
