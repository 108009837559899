import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './authLayout.module.scss';

interface AuthLayoutProps {
  children: ReactNode;
  className?: string;
}

export const AuthLayout = ({ children, className }: AuthLayoutProps) => {
  return <div className={classNames(styles.root, className)}>{children}</div>;
};
