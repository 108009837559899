import { NavLink, NavLinkVariant } from '@shared/ui';
import { mockAlerts, mockEvents } from './mockAlerts';

import { Alert } from '../Alert';
import { AlertVariantEnum } from '../Alert/Alert';
import { AuthPath } from '@shared/constants/routes';
import { LayoutContent } from '@shared/widgets';
import { Link } from 'react-router-dom';
import { SuspiciousActivityTabEnum } from '@shared/store/types';
import { TabProps } from '@shared/widgets/LayoutHeader/LayoutHeader';
import classNames from 'classnames';
import { formatTimezone } from '@shared/utils';
import styles from './notificationsSidebar.module.scss';
import { useGetNotifications } from '@pages/Notifications/hooks';
import { useState } from 'react';

enum NotificationsTabsEnum {
  ALERTS,
  EVENTS,
}

type NotificationsSidebarProps = {
  className?: string;
};

export const NotificationsSidebar = ({ className }: NotificationsSidebarProps) => {
  const { notifications, isEmpty, isLoading, pageCount, hasPagination, refetch } = useGetNotifications({
    limit: 1000,
    page: 1,
    suspiciousActivityTab: SuspiciousActivityTabEnum.NOT_VERIFIED,
  });

  const [activeTab, setActiveTab] = useState(NotificationsTabsEnum.ALERTS);

  const [activeAlert, setActiveAlert] = useState<string | undefined>(undefined);

  const isEmptyAlerts = !notifications?.length;
  const isEmptyevents = !mockEvents.length;

  const NOTIFICATIONS_TABS: TabProps[] = [
    {
      title: 'Срочные оповещения',
      isTabActive: activeTab === NotificationsTabsEnum.ALERTS,
      onNavigation: () => setActiveTab(NotificationsTabsEnum.ALERTS),
      hasCounter: true,
    },
    {
      title: 'События',
      isTabActive: activeTab === NotificationsTabsEnum.EVENTS,
      onNavigation: () => setActiveTab(NotificationsTabsEnum.EVENTS),
      hasCounter: false,
    },
  ];

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header}>
        <p className={styles.heading}>
          <Link to={'/notifications'}>Уведомления</Link>
        </p>
        <div className={styles.tabs}>
          {NOTIFICATIONS_TABS.map(({ title, isTabActive = false, onNavigation, hasCounter }) => (
            <NavLink
              key={title}
              to={AuthPath.DASHBOARD}
              variant={NavLinkVariant.TAB_NAVIGATION}
              onNavigation={onNavigation}
              isTabActive={isTabActive}
              hasCounter={false}
              countValue={notifications?.length}>
              {title}
            </NavLink>
          ))}
        </div>
      </div>
      <div className={styles.alerts}>
        {activeTab === 0 ? (
          isEmptyAlerts ? (
            <LayoutContent alignContentVariant="center" emptyHeading="Событий не было" iconSize="small" />
          ) : (
            notifications?.map(notification => (
              <Alert
                key={notification.notification?.id}
                id={notification.notification?.id}
                activeId={activeAlert}
                createdAt={formatTimezone(notification.notification?.createdAt)}
                variant={AlertVariantEnum.ATTENTION}
                notification={notification}
                onSetActiveAlert={setActiveAlert}
                onSuccess={refetch}
              />
            ))
          )
        ) : isEmptyevents ? (
          <LayoutContent alignContentVariant="center" emptyHeading="Событий не было" iconSize="small" />
        ) : (
          mockEvents.map(alert => (
            <Alert
              key={alert.id}
              id={alert.id}
              activeId={activeAlert}
              createdAt={alert.createdAt}
              variant={alert.variant}
              onSetActiveAlert={setActiveAlert}
            />
          ))
        )}
      </div>
    </div>
  );
};
