import { ChangeEvent, FC } from 'react';

import { ButtonGroupProps } from './types';
import classNames from 'classnames';
import styles from './buttonGroup.module.scss';

export const ButtonGroup: FC<ButtonGroupProps> = ({ name, label, checked, onChange, ...props }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, { checked: e.target.checked, name });
    }
  };
  return (
    <div className={styles.root}>
      {label && <span className={styles.label}>{label}</span>}
      <label className={styles.group}>
        <input name={name} type="checkbox" checked={checked} onChange={handleChange} {...props} />
        <span className={classNames(styles.select, styles.left, { [styles.checked]: checked })}>Да</span>
        <span className={classNames(styles.select, styles.right, { [styles.checked]: !checked })}>Нет</span>
      </label>
    </div>
  );
};
