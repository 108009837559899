type UserProps = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};

export const getShortenFullName = ({ firstName, middleName, lastName }: UserProps) => {
  if (!firstName && !middleName && !lastName) {
    return '—';
  }

  const shortenFirstName = firstName && `${firstName.slice(0, 1)}.`;
  const shortenMiddleName = middleName && `${middleName.slice(0, 1)}.`;

  return `${!!lastName ? lastName : ''} ${!!firstName ? shortenFirstName : ''} ${
    !!middleName ? shortenMiddleName : ''
  }`;
};
