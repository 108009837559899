import { VerificationLogStatusKind } from '@shared/services/apiService/apiService_log';

export const getIsVioleted = (status: VerificationLogStatusKind | null | undefined) => {
  switch (status) {
    case 'verify_status_verified':
      return false;
    case 'verify_status_violation':
      return true;
  }
};
