import { useParams } from 'react-router-dom';
import { useVideoWallsControllerDeleteByIdMutation } from '@shared/services/apiService/apiService_videowall';

export const useDeleteVideoWall = () => {
  const { videoWallId } = useParams();

  const [deleteVideoWallById, { isSuccess, isLoading }] = useVideoWallsControllerDeleteByIdMutation();

  const handleDeleteVideoWall = async () => {
    if (!videoWallId) {
      return;
    }

    await deleteVideoWallById({
      id: videoWallId,
    });

    window.location.reload();
  };

  return { isLoading, isSuccess, handleDeleteVideoWall };
};
