import * as React from 'react';

import type { SVGProps } from 'react';
const SvgBox = (props: SVGProps<SVGSVGElement>) => (
  <svg width="130" height="75" viewBox="0 0 130 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M88.7109 7.99663C88.7109 6.05987 90.205 4.45104 92.1364 4.30795L125.028 1.87111C127.173 1.71222 129 3.40935 129 5.55979V28.6933C129 30.7361 127.344 32.3921 125.301 32.3921H92.4097C90.3669 32.3921 88.7109 30.7361 88.7109 28.6933V7.99663Z"
      stroke="#717680"
      strokeWidth="2"
    />
    <path
      d="M88.7109 60.3535C88.7109 62.2902 90.205 63.8991 92.1364 64.0422L125.028 66.479C127.173 66.6379 129 64.9407 129 62.7903V39.6568C129 37.614 127.344 35.958 125.301 35.958H92.4097C90.3669 35.958 88.7109 37.614 88.7109 39.6568V60.3535Z"
      stroke="#717680"
      strokeWidth="2"
    />
    <path
      d="M41.2891 7.99663C41.2891 6.05987 39.795 4.45104 37.8636 4.30795L4.97199 1.87111C2.82742 1.71222 0.999905 3.40935 0.999905 5.55979V28.6933C0.999905 30.7361 2.65591 32.3921 4.6987 32.3921H37.5903C39.6331 32.3921 41.2891 30.7361 41.2891 28.6933V7.99663Z"
      stroke="#717680"
      strokeWidth="2"
    />
    <path
      d="M41.2891 60.3535C41.2891 62.2902 39.795 63.8991 37.8636 64.0422L4.97199 66.479C2.82742 66.6379 0.999905 64.9407 0.999905 62.7903V39.6568C0.999905 37.614 2.65591 35.958 4.6987 35.958H37.5903C39.6331 35.958 41.2891 37.614 41.2891 39.6568V60.3535Z"
      stroke="#717680"
      strokeWidth="2"
    />
    <path
      d="M44.8555 8.33112C44.8555 6.28833 46.5115 4.63232 48.5543 4.63232H81.4458C83.4886 4.63232 85.1446 6.28833 85.1446 8.33112V28.6926C85.1446 30.7354 83.4886 32.3914 81.4458 32.3914H48.5543C46.5115 32.3914 44.8555 30.7354 44.8555 28.6926V8.33112Z"
      stroke="#717680"
      strokeWidth="2"
    />
    <path
      d="M44.8555 60.0185C44.8555 62.0613 46.5115 63.7173 48.5543 63.7173H81.4458C83.4886 63.7173 85.1446 62.0613 85.1446 60.0185V39.657C85.1446 37.6143 83.4886 35.9582 81.4458 35.9582H48.5543C46.5115 35.9582 44.8555 37.6143 44.8555 39.657V60.0185Z"
      stroke="#717680"
      strokeWidth="2"
    />
    <path d="M92.4097 69.4155L37.5904 69.4155" stroke="#717680" strokeWidth="2" strokeLinecap="round" />
    <path d="M100.241 73.3311L29.7593 73.331" stroke="#717680" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
export default SvgBox;
