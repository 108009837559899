import { Coords } from '../types';
import { RefObject } from 'react';
import { useExtendContext } from './useExtendContext';

export const useGetCursorCoords = (canvasRef: RefObject<HTMLCanvasElement>, cursorRef: RefObject<Coords | null>) => {
  if (!canvasRef.current) {
    return null;
  }
  const context = useExtendContext(canvasRef.current.getContext('2d'));

  if (!context) {
    return null;
  }

  if (cursorRef.current) {
    return context.transformedPoint(cursorRef.current.x, cursorRef.current.y);
  }
  return null;
};
