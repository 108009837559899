import {
  SecurityUserOfficeResponseDto,
  useLazySecurityUsersOfficesV2ControllerGetVisitorInfoQuery,
} from '@shared/services/apiService/apiService_base';
import { useEffect, useState } from 'react';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { VisitorMarkerType } from '../types';
import { useLocalStorage } from '@shared/hooks';

export const useGetVisitorById = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const [activeMarker, setActiveMarker] = useState<VisitorMarkerType | null>();

  const [triggerGetVisitorInfoById, dataVisitorInfo] = useLazySecurityUsersOfficesV2ControllerGetVisitorInfoQuery();

  useEffect(() => {
    if (!officeId || !activeMarker?.visitorUuid) {
      return;
    }

    triggerGetVisitorInfoById({ officeId: officeId, visitorId: activeMarker?.visitorUuid });
  }, [activeMarker]);

  const visitorInfo = dataVisitorInfo.data;

  return { activeMarker, visitorInfo, setActiveMarker };
};
