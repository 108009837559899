import {
  SecurityUserAreasListItemResponseDto,
  SecurityUserOfficeResponseDto,
  useSecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasQuery,
} from '@shared/services/apiService/apiService_base';
import { SortOrderEnum, VisitorsSortFieldsEnum } from '@shared/store/types';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { VisitorViewEnum } from '../types';
import { VisitorsPath } from '@shared/constants/routes';
import { useLocalStorage } from '@shared/hooks';

type UseGetAreaProps = {
  view: VisitorViewEnum;
  page: number;
  order: SortOrderEnum;
  sort: VisitorsSortFieldsEnum;
  search: string;
  reserSearchParams: () => void;
};

export const useGetAreas = ({ view, page, order, sort, search, reserSearchParams }: UseGetAreaProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const navigate = useNavigate();

  const { areaId } = useParams();

  const [activeArea, setActiveArea] = useState<SecurityUserAreasListItemResponseDto>();

  const officeId = activeOffice && activeOffice.id;
  const officeName = activeOffice && activeOffice.name;

  const {
    data,
    isLoading,
    refetch: refetchGetAreas,
  } = useSecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasQuery({ officeId: officeId! }, { skip: !officeId });

  const areas = data?.areas;
  const isAreasEmpty = areas?.length === 0;
  const isLoadingAreas = isLoading;

  useEffect(() => {
    if (!areas) {
      return;
    }

    const initialAreaById = areas?.find(area => area.id === areaId);
    const initialAreaByFirstItem = areas[0];

    const selectedAreaString = localStorage.getItem('selectedArea');
    const selectedArea = selectedAreaString ? JSON.parse(selectedAreaString) : initialAreaByFirstItem;


    if (areaId && initialAreaById) {
      setActiveArea(initialAreaById);
    } else {
      navigate(
        {
          pathname: VisitorsPath.VISITORS_BY_AREA_ID(selectedArea.id),
          search: createSearchParams({
            view,
            page: page as unknown as string,
            order,
            sort,
            search,
          }).toString(),
        },
        { replace: true },
      );
      setActiveArea(selectedArea);
    }
  }, [areas, areaId]);

  const handleSelectArea = (area: SecurityUserAreasListItemResponseDto) => {
    reserSearchParams();
    navigate({
      pathname: VisitorsPath.VISITORS_BY_AREA_ID(area.id),
      search: createSearchParams({
        view: view,
        page: page as unknown as string,
        order: order,
        sort: sort,
        search: search,
      }).toString(),
    });
    localStorage.setItem('selectedArea', JSON.stringify(area));
  };

  return { activeArea, officeName, areas, isAreasEmpty, isLoadingAreas, handleSelectArea, refetchGetAreas };
};
