import { LayoutContent, LayoutHeader } from '@shared/widgets';
import { NOTIFICATIONS_TABS, NOTIFICATION_PAGE_HEADING, NotificationTabEnum } from '@pages/Notifications/constants';
import { formatTimezone, getFullName } from '@shared/utils';
import { getSuspiciousAccessPoint, getSuspiciousActivityReason } from '../helpers';
import { useClientSize, useTitle } from '@shared/hooks';
import { useGetNotifications, useSearchParamsNotifications, useSelectNotification } from '@pages/Notifications/hooks/';

import { FC } from 'react';
import { MainLayout } from '@shared/layouts';
import { Spinner } from '@shared/ui';
import { SuspiciousActivityTabEnum } from '@shared/store/types';
import { Table } from '@shared/ui';
import { Titles } from '@shared/constants/titles';
import { VerifyNotification } from '@features/VerifyNotification';
import { formatPhoneString } from '@shared/utils';
import { getIsVioleted } from '@features/VerifyNotification/helpers';

export const PassagesVerified: FC = () => {
  useTitle(Titles.Notifications);

  const { page, setPage } = useSearchParamsNotifications({
    tab: NotificationTabEnum.VERIFIED,
  });

  const { notifications, isEmpty, isLoading, pageCount, hasPagination, refetch } = useGetNotifications({
    page: page,
    suspiciousActivityTab: SuspiciousActivityTabEnum.VERIFIED,
  });

  const { activeNotification, isVisibleModalVerify, toggleModalVerify, handleCommentClick } = useSelectNotification();

  const { getIsBreakpoint } = useClientSize();
  const isDesktop = getIsBreakpoint('desktop');

  if (isLoading) {
    return (
      <MainLayout>
        <LayoutHeader title={NOTIFICATION_PAGE_HEADING} tabs={NOTIFICATIONS_TABS} />
        <LayoutContent alignContentVariant="center">
          <Spinner />
        </LayoutContent>
      </MainLayout>
    );
  }

  if (isEmpty || !notifications) {
    return (
      <MainLayout>
        <LayoutHeader title={NOTIFICATION_PAGE_HEADING} tabs={NOTIFICATIONS_TABS} />
        <LayoutContent alignContentVariant="center" emptyHeading="Нет проверенных проходов" />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <LayoutHeader title={NOTIFICATION_PAGE_HEADING} tabs={NOTIFICATIONS_TABS} />
      <LayoutContent>
        <Table
          hasPaginator={hasPagination}
          paginator={{ initialPage: page, pageCount: pageCount, onPageChange: setPage }}>
          <Table.THead>
            <Table.THeadCell width={150}>Дата и время (GMT+3)</Table.THeadCell>
            <Table.THeadCell width={250}>ФИО</Table.THeadCell>
            <Table.THeadCell>Локация, турникет</Table.THeadCell>
            <Table.THeadCell>Телефон</Table.THeadCell>
            <Table.THeadCell>Событие</Table.THeadCell>
            <Table.THeadCell>Нарушение</Table.THeadCell>
            <Table.THeadCell width={350}>Действие</Table.THeadCell>
          </Table.THead>
          <Table.TBody>
            {notifications.map(notification => (
              <Table.TRow key={notification.notification?.id}>
                <Table.TCellFullDate date={formatTimezone(notification.notification?.createdAt)} />
                <Table.TCell>
                  {getFullName({
                    lastName: notification.visitorInfo?.lastName,
                    firstName: notification.visitorInfo?.firstName,
                    middleName: notification.visitorInfo?.middleName,
                  })}
                </Table.TCell>
                <Table.TCell>
                  {getSuspiciousAccessPoint({ access_point: notification.accessPointInfo?.name })}
                </Table.TCell>
                <Table.TCell>{formatPhoneString(notification.visitorInfo?.phone)}</Table.TCell>
                <Table.TCellTooltip maxStringChildrenLenght={isDesktop ? 28 : 10}>
                  {getSuspiciousActivityReason({
                    suspicion_biometric_undefined: Boolean(notification.notification?.suspicion_biometric_undefined),
                    suspicion_bluetooth_undefined: Boolean(notification.notification?.suspicion_bluetooth_undefined),
                  })}
                </Table.TCellTooltip>
                <Table.TCellViolation isViolation={getIsVioleted(notification.notification?.verify_status)} />
                <Table.TCellComment
                  hasPatch={true}
                  notificationComment={notification.notification?.security_comment}
                  onClick={() => handleCommentClick(notification.notification)}
                />
              </Table.TRow>
            ))}
          </Table.TBody>
        </Table>
        {activeNotification && (
          <VerifyNotification
            isModalVisible={isVisibleModalVerify}
            notification={activeNotification}
            closeCallback={toggleModalVerify}
            onSuccess={refetch}
          />
        )}
      </LayoutContent>
    </MainLayout>
  );
};
