import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBle24 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#717680"
      strokeWidth={2}
      d="M3.607 15.497 9.404 5.484c1.155-1.995 4.037-1.995 5.192 0l5.797 10.013c1.158 2-.285 4.503-2.596 4.503H6.203c-2.31 0-3.754-2.503-2.596-4.503Z"
    />
  </svg>
);
export default SvgBle24;
