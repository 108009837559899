import {
  SecurityUserOfficeResponseDto,
  useSecurityUsersOfficesV2ControllerGetVisitorsListQuery,
} from '@shared/services/apiService/apiService_base';

import { DEFAULT_LIMIT_ITEMS_ON_PAGE } from '@shared/constants/pagination';
import { DEFAULT_VISITORS_MAP_POOLING_INTERVAL } from '../constants';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useLocalStorage } from '@shared/hooks';

type GetVisitorsByIds = {
  ids: string[];
};

export const useGetVisitorsByIds = ({ ids }: GetVisitorsByIds) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const { data: VisitorsList, isLoading } = useSecurityUsersOfficesV2ControllerGetVisitorsListQuery(
    {
      officeId: officeId!,
      ids: ids,
    },
    { pollingInterval: DEFAULT_VISITORS_MAP_POOLING_INTERVAL, skip: ids.length === 0 || !officeId },
  );

  const visitors = VisitorsList?.visitors;
  const isEmpty = visitors?.length === 0;
  const pageCount = visitors?.length && Math.ceil(visitors?.length / DEFAULT_LIMIT_ITEMS_ON_PAGE);
  const hasPagination = pageCount ? pageCount > 1 : false;

  return {
    isLoading,
    isEmpty,
    visitors,
    pageCount,
    hasPagination,
  };
};
