import { FC, ReactNode } from 'react';
import { SidebarContent, SidebarHeader } from './ui';

import classNames from 'classnames';
import styles from './sidebar.module.scss';

type SidebarProps = {
  headerTitle?: string;
  headerSubtitle?: string | null;
  headerAction?: () => void;
  hasHeaderActionNotAllowed?: boolean;
  contentTitle?: string;
  contentList: ReactNode;
  contentActions: ReactNode;
  hasBackOption?: boolean;
  isContentEmpty?: boolean;
  emptyContentTitle?: string;
};

export const Sidebar: FC<SidebarProps> = ({
  headerTitle = '',
  headerSubtitle = '',
  headerAction,
  hasHeaderActionNotAllowed = false,
  contentTitle,
  hasBackOption = false,
  contentList,
  contentActions,
  isContentEmpty = false,
  emptyContentTitle = '',
}) => {
  if (isContentEmpty) {
    return (
      <div className={classNames(styles.root, styles.rootEmpty)}>
        <SidebarContent
          isEmpty={true}
          hasBackOption={false}
          title={emptyContentTitle}
          actions={contentActions}
          list={contentList}
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <SidebarHeader
        title={headerTitle}
        subtitle={headerSubtitle}
        hasBackOption={hasBackOption}
        action={headerAction}
        hasActionNotAllowed={hasHeaderActionNotAllowed}
      />
      <SidebarContent title={contentTitle} hasBackOption={hasBackOption} actions={contentActions} list={contentList} />
    </div>
  );
};
