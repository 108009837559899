export function getShortlyCues(cues: VTTCue[], currentTime: number) {
  if (cues.length === 0) {
    return null;
  }

  let shortlyCue = cues[0];
  let shortlyTimeDiff = Math.abs(currentTime - shortlyCue.endTime);

  for (let i = 1; i < cues.length; i++) {
    const timeDiff = Math.abs(currentTime - cues[i].endTime);

    if (timeDiff < shortlyTimeDiff) {
      shortlyCue = cues[i];
      shortlyTimeDiff = timeDiff;
    }
  }

  return shortlyCue;
}