import { Navigate, Route, Routes } from 'react-router-dom';

import { SignInPage } from '@pages/SignIn';
import { UnauthPath } from '@shared/constants/routes';

export const UnauthorizedRoutes = () => {
  return (
    <Routes>
      <Route path={UnauthPath.SIGN_IN} element={<SignInPage />} />
      <Route path="*" element={<Navigate to={UnauthPath.SIGN_IN} replace />} />
    </Routes>
  );
};
