import { ChangeEvent, forwardRef, useState } from 'react';

import { TextareaProps } from './types';
import classNames from 'classnames';
import styles from './textarea.module.scss';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ value, defaultValue, label, placeholder, onChange, error, maxLength, disabled, ...props }, ref) => {
    const uncontrolled = value === undefined;

    const [stateValue, setStateValue] = useState(defaultValue || '');
    const [currentWordsLength, setCurrentWordsLength] = useState(0);

    const hasError = Boolean(error);

    const hint = currentWordsLength === 0 ? `Не более ${maxLength} символов` : `${currentWordsLength} / ${maxLength}`;

    const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value: newValue },
      } = event;

      if (onChange) {
        onChange(event, { value: newValue });
      }

      setCurrentWordsLength(newValue.length);

      if (uncontrolled) {
        setStateValue(newValue);
      }
    };

    const classesLabel = classNames(styles.label, { [styles.errorColor]: hasError });
    const classesTextarea = classNames(styles.textarea, { [styles.errorBorder]: hasError });

    return (
      <div className={styles.root}>
        <label className={classesLabel}>{label}</label>
        <textarea
          ref={ref}
          value={uncontrolled ? stateValue : value}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          className={classesTextarea}
          onChange={handleTextareaChange}
          {...props}
        />
        {hasError ? (
          <span className={styles.error}>{error?.message}</span>
        ) : (
          <span className={styles.hint}>{hint}</span>
        )}
      </div>
    );
  },
);
