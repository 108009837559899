import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTarget16 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.5 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334ZM15.167 8H12.5M4.5 8H1.833M8.5 4V1.333M8.5 14.667V12"
    />
  </svg>
);
export default SvgTarget16;
