import * as React from 'react';
function SvgLogOut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.667 15H4a1.334 1.334 0 01-1.333-1.333V4.333A1.333 1.333 0 014 3h2.667M11.333 12.333L14.667 9l-3.334-3.333M14.667 9h-8"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgLogOut;
