import * as React from 'react';
function SvgHidePassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#hide-pass-icon_svg__clip0_81_3143)" fill="#969FA8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.793 5.207a1 1 0 011.414-1.414l1.975 1.975 2.732 2.73 3.587 3.59s0-.001 0 0l3.525 3.523 2.181 2.182a1 1 0 01-1.414 1.414l-2.367-2.367c-1.229.89-2.062 1.374-3.903 1.69-.658.112-2.38.112-3.038 0-2.11-.362-2.896-.943-4.469-2.106a25.729 25.729 0 01-2.432-2.061c-1.053-1.005-1.58-1.507-1.58-2.363 0-.857.527-1.359 1.58-2.364a25.733 25.733 0 012.432-2.06c.469-.347.867-.641 1.252-.894L5.793 5.207zm4.04 4.04a3.502 3.502 0 104.919 4.919l-1.438-1.438a1.502 1.502 0 11-2.043-2.042L9.833 9.248z"
        />
        <path d="M20.517 14.274l-8.884-8.885c.705-.014 1.492.013 1.89.081 2.109.361 2.895.943 4.468 2.105a25.736 25.736 0 012.433 2.061c1.053 1.005 1.58 1.507 1.58 2.364 0 .83-.496 1.328-1.487 2.274z" />
      </g>
      <defs>
        <clipPath id="hide-pass-icon_svg__clip0_81_3143">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgHidePassIcon;
