import type { SVGProps } from 'react';
const SvgToastError = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_3204_3453)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0882932 7.6931C0.30463 4.78633 0.412799 3.33294 1.87161 1.87414C3.33041 0.415329 4.7838 0.307161 7.69057 0.0908252C8.44408 0.0347455 9.22136 0.000762939 9.99864 0.000762939C10.7759 0.000762939 11.5532 0.0347461 12.3067 0.0908261C15.2135 0.307163 16.6669 0.415331 18.1257 1.87414C19.5845 3.33295 19.6926 4.78633 19.909 7.6931C19.9651 8.44661 19.999 9.22389 19.999 10.0012C19.999 10.7785 19.9651 11.5557 19.909 12.3092C19.6926 15.216 19.5845 16.6694 18.1257 18.1282C16.6669 19.587 15.2135 19.6952 12.3067 19.9115C11.5532 19.9676 10.7759 20.0016 9.99864 20.0016C9.22136 20.0016 8.44407 19.9676 7.69057 19.9115C4.7838 19.6952 3.33041 19.587 1.8716 18.1282C0.412796 16.6694 0.304628 15.216 0.0882922 12.3092C0.0322126 11.5557 -0.00177002 10.7784 -0.00177002 10.0012C-0.00177002 9.22389 0.0322132 8.44661 0.0882932 7.6931Z"
        fill="#FF0014"
        fillOpacity="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0011 11.3404C10.5539 11.3404 11.002 10.8671 11.002 10.2832L11.002 6.05716C11.002 5.4733 10.5539 5 10.0011 5C9.44833 5 9.00021 5.47331 9.00022 6.05717L9.00023 10.2832C9.00023 10.8671 9.44835 11.3404 10.0011 11.3404ZM11.25 14.5131C11.25 13.7839 10.6904 13.1928 10 13.1928C9.30964 13.1928 8.75 13.7839 8.75 14.5131C8.75 15.2422 9.30964 15.8333 10 15.8333C10.6904 15.8333 11.25 15.2422 11.25 14.5131Z"
        fill="#FF0014"
      />
    </g>
    <defs>
      <clipPath id="clip0_3204_3453">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgToastError;
