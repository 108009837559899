import dayjs from 'dayjs';
import plural from 'plural-ru';

export const showDate = (date: string) => dayjs(date).format('DD.MM.YYYY');
export const showTime = (date: string) => dayjs(date).format('HH:mm:ss');
export const getTimeFromNow = (date?: string | null) => {
  if (!date) {
    return '—';
  }

  const difference = dayjs(new Date()).diff(date, 'milliseconds');

  const seconds = dayjs.duration(difference).seconds();
  const minutes = dayjs.duration(difference).minutes();
  const hours = dayjs.duration(difference).hours();
  const days = dayjs.duration(difference).days();
  const months = dayjs.duration(difference).months();

  const timeNumberFormatter = (timeNumber: number) => (timeNumber < 9 ? `0${timeNumber}` : timeNumber);

  const daysFormatted = days != 0 ? `${days} ${plural(days, 'день', 'дня', 'дней')}` : '';
  const monthsFormatted = months != 0 ? `${months} ${plural(months, 'месяц', 'месяца', 'месяцев')}` : '';

  const timeFormatted = `${timeNumberFormatter(hours)}:${timeNumberFormatter(minutes)}:${timeNumberFormatter(seconds)}`;

  return !!months ? `${monthsFormatted} ${timeFormatted}` : `${daysFormatted} ${timeFormatted}`;
};
