import { AreaMetrics, DEFAULT_VISITORS_MAP_POOLING_INTERVAL, VisitorsMapPath } from '../constants';
import { useEffect, useState } from 'react';

import { AreaMetricsProps } from '../types';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const useGetAreaMetrics = () => {
  const { areaId } = useParams();

  const [metrics, setMetrics] = useState(AreaMetrics);

  useEffect(() => {
    const timer = setInterval(async () => {
      axios.get(`${VisitorsMapPath.AREA_METRICS(areaId)}`).then(function (response) {
        const data: AreaMetricsProps = response.data;

        data.metrics.map(metric => {
          const visiorGroup = metric.visitorRole;
          const newMetrics = metrics.map(group =>
            group.visitorRole === visiorGroup ? { ...group, visitorCount: metric.visitorCount } : group,
          );
          setMetrics(newMetrics);
        });
      });
    }, DEFAULT_VISITORS_MAP_POOLING_INTERVAL);

    return () => clearInterval(timer);
  }, [areaId]);

  return { metrics };
};
