import {
  CANVAS_ZOOM_SHIFT,
  INITIAL_SCALE_COUNTER,
  MIN_SCALE_COUNTER,
  SCALE_COUNTER_SHIFT,
  SCALE_DOWN_FACTOR,
} from '../constants';
import { Dispatch, RefObject, SetStateAction, useMemo } from 'react';

export const useZoomDown = (
  canvasRef: RefObject<HTMLCanvasElement>,
  setTotalScaleFactor: Dispatch<SetStateAction<number>>,
  redraw: () => void,
) => {
  const zoomDown = useMemo(
    () => () => {
      const targetScaleFactorDecrement = INITIAL_SCALE_COUNTER - SCALE_COUNTER_SHIFT + CANVAS_ZOOM_SHIFT;

      let zoomScale = INITIAL_SCALE_COUNTER;

      function step() {
        if (!canvasRef.current) {
          return;
        }
        const context = canvasRef.current.getContext('2d');

        if (!context) {
          return;
        }

        if (zoomScale >= targetScaleFactorDecrement) {
          setTotalScaleFactor(prev =>
            prev - CANVAS_ZOOM_SHIFT >= MIN_SCALE_COUNTER ? prev - CANVAS_ZOOM_SHIFT : prev,
          );

          context.scale(SCALE_DOWN_FACTOR, SCALE_DOWN_FACTOR);

          redraw();

          window.requestAnimationFrame(step);

          zoomScale = zoomScale - CANVAS_ZOOM_SHIFT;
        }
      }

      window.requestAnimationFrame(step);
    },
    [redraw],
  );

  return zoomDown;
};
