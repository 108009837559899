import { Dispatch, SetStateAction } from 'react';

import { CameraResponseDto } from '@shared/services/apiService/apiService_videowall';
import { ContextMenuItemsProps } from '../ContextMenu/types';

export enum StreamVariantUrlEnum {
  DEFAULT = 'default',
  MASKED = 'masked',
  PREDATOR = 'predator',
}

export type ActiveStreamProps = { streamVariant: StreamVariantUrlEnum; streamUrl: string };

export type StreamHeaderProps = {
  stream: CameraResponseDto;
  contextMenuItems: ContextMenuItemsProps[];
  onSelectStream?: () => void;
  activeStream: ActiveStreamProps;
  setStream: Dispatch<SetStateAction<ActiveStreamProps>>;
};
