import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';

import { actions } from '../ducks/auth';

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(actions.signOut());
    }

    return next(action);
  };
