import {
  ArrowDown,
  ArrowUp,
  Attention36,
  Danger36,
  Done36,
  NotificationAttention20,
  NotificationAttention24,
  NotificationDanger20,
  NotificationDanger24,
  NotificationDone20,
  NotificationDone24,
} from '@shared/assets/icons/components';
import { Dispatch, FC, SetStateAction } from 'react';

import { Button } from '@shared/ui';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getSuspiciousActivityReason } from '@pages/Notifications/helpers';
import styles from './alert.module.scss';
import { useClientSize } from '@shared/hooks';
import { useSelectNotification } from '@pages/Notifications/hooks';
import { VerifyNotification } from '@features/VerifyNotification';

export enum AlertVariantEnum {
  DONE,
  DANGER,
  ATTENTION,
}

type AlertProps = {
  id?: string;
  activeId?: string;
  createdAt?: string;
  variant: AlertVariantEnum;
  notification?: any | undefined | null;
  onSetActiveAlert: Dispatch<SetStateAction<string | undefined>>;
  onSuccess?: () => void;
};

export const Alert: FC<AlertProps> = ({ id, activeId, createdAt, notification, variant, onSetActiveAlert }) => {
  const { getIsBreakpoint } = useClientSize();
  const isDesktopL = getIsBreakpoint('desktopL');
  const isdesktopUltraHD = getIsBreakpoint('desktopUltraHD');

  const { activeNotification, isVisibleModalVerify, toggleModalVerify, handleCommentClick } = useSelectNotification();

  const alertInfo = {
    [AlertVariantEnum.DONE]: {
      icon: isdesktopUltraHD ? <Done36 /> : isDesktopL ? <NotificationDone24 /> : <NotificationDone20 />,
      heading: 'Пропуск заказан',
    },
    [AlertVariantEnum.DANGER]: {
      icon: isdesktopUltraHD ? <Danger36 /> : isDesktopL ? <NotificationDanger24 /> : <NotificationDanger20 />,
      heading: 'Несанкционированный проход',
    },
    [AlertVariantEnum.ATTENTION]: {
      icon: isdesktopUltraHD ? <Attention36 /> : isDesktopL ? <NotificationAttention24 /> : <NotificationAttention20 />,
      heading: 'Подозрительный проход',
    },
  };

  const isTodayPrefix = dayjs(createdAt).isToday() ? 'Сегодня в' : null;
  const isYesterdayPrefix = dayjs(createdAt).isYesterday() ? 'Вчера в' : null;
  const date = isTodayPrefix
    ? `${isTodayPrefix} ${dayjs(createdAt).format('HH:mm')}`
    : isYesterdayPrefix
    ? `${isYesterdayPrefix} ${dayjs(createdAt).format('HH:mm')}`
    : dayjs(createdAt).format('D MMMM HH:mm');

  const isActive = activeId === id;

  const handleAlertClick = () => {
    isActive ? onSetActiveAlert(undefined) : onSetActiveAlert(id);
  };

  const classesRoot = classNames(styles.root, { [styles.rootActive]: isActive });

  return (
    <div className={classesRoot}>
      <div className={styles.header} onClick={handleAlertClick}>
        {alertInfo[variant].icon}
        <div className={styles.headingConteiner}>
          <p className={styles.heading}>{alertInfo[variant].heading}</p>
          <p className={styles.timeshamp}>{date}</p>
        </div>
        {isActive ? <ArrowUp /> : <ArrowDown />}
      </div>
      <div className={styles.content}>
        <p className={styles.text}>
          {getSuspiciousActivityReason({
            suspicion_biometric_undefined: Boolean(notification.notification?.suspicion_biometric_undefined),
            suspicion_bluetooth_undefined: Boolean(notification.notification?.suspicion_bluetooth_undefined),
          })}
        </p>
        {isActive && (
          <div className={styles.actions}>
            <Button onClick={() => handleCommentClick(notification.notification)}>Отметить как проверенный</Button>
          </div>
        )}
      </div>
      {activeNotification && (
          <VerifyNotification
            isModalVisible={isVisibleModalVerify}
            notification={activeNotification}
            closeCallback={toggleModalVerify}
            onSuccess={()=>{console.log('okay')}}
          />
        )}
    </div>
    
  );
};
