import { ConditionalWrapper, Tooltip } from '@shared/ui';
import { FC, useId } from 'react';

import classNames from 'classnames';
import styles from './cameraCard.module.scss';

export type CameraCardProps = {
  id: string;
  name: string;
  imgUrl: string;
  isStatusError: boolean;
  onSelect: () => void;
};

const CAMERA_NAME_MAX_LENGHT = 28;

export const CameraCard: FC<CameraCardProps> = ({ id, name, imgUrl, isStatusError, onSelect }) => {
  const maxCameraNameLenght = CAMERA_NAME_MAX_LENGHT;
  const cameraName = name;
  const isLongChildren = cameraName.length > maxCameraNameLenght;
  const substringCameraName = cameraName.substring(0, maxCameraNameLenght) + '...';

  const classesCameraIndicator = classNames(styles.cameraIndicator, {
    [styles.cameraIndicatorInactive]: isStatusError,
  });

  return (
    <div className={styles.root} onClick={onSelect}>
      <img src={imgUrl} alt="preview image" className={styles.cameraImg} />
      <div className={styles.cameraInfo}>
        <div className={classesCameraIndicator} />
        <ConditionalWrapper
          condition={isLongChildren}
          wrapper={children => {
            return (
              <Tooltip id={useId()} content={cameraName}>
                {children}
              </Tooltip>
            );
          }}>
          <p className={styles.cameraName}>{isLongChildren ? substringCameraName : cameraName}</p>
        </ConditionalWrapper>
      </div>
    </div>
  );
};
