import { Burger, Notification, ShevronDown20 } from '@shared/assets/icons/components';
import { IconButton, NavLink, SidebarModal } from '@shared/ui';
import { Link, useLocation } from 'react-router-dom';
import {
  SecurityUserOfficeResponseDto,
  useSecurityUsersV2ControllerGetMeQuery,
} from '@shared/services/apiService/apiService_base';
import { useClientSize, useElementSize, useLocalStorage, useOutsideClick, useToggle } from '@shared/hooks';
import { useEffect, useRef } from 'react';

import { APP_NAVIGATION } from './constants';
import { Emergency } from '@features/Emergency';
import { NotificationsSidebar } from '@pages/Dashboard/ui';
import { SignOut } from '@features/Auth/SignOut';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import classNames from 'classnames';
import { getShortenFullName } from '@shared/utils/getShortenFullname';
import predatorLogo from '@shared/assets/icons/origin/logo.svg';
import styles from './appHeader.module.scss';

export type AppHeaderProps = {
  isActionBarVisible?: boolean;
  onClickActionBar?: () => void;
};

export const AppHeader = ({ isActionBarVisible = false, onClickActionBar }: AppHeaderProps) => {
  const [activeOffice, setOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(
    StorageKeys.UP_OFFICE,
    undefined,
  );

  const { data: getMe, refetch: refetchGetMe } = useSecurityUsersV2ControllerGetMeQuery();

  useEffect(() => {
    const office = getMe?.offices && getMe?.offices.filter(office => office.id === activeOffice?.id)[0];
    office && setOffice(office);
  }, [getMe]);

  const name = getShortenFullName({
    firstName: getMe?.firstName,
    lastName: getMe?.lastName,
    middleName: getMe?.middleName,
  });
  const isEmergency = activeOffice && activeOffice.emergencyModeActive;

  const { getIsBreakpoint } = useClientSize();
  const isLaptop = getIsBreakpoint('laptop');

  const location = useLocation();

  const activeNavLink = APP_NAVIGATION.filter(navigation => location?.pathname.includes(navigation.parent))[0];

  const { state: isMenuOpened, toggle: toggleMenu } = useToggle();
  const { state: isSidebarModalOpen, toggle: toggleSidabarModal } = useToggle();

  const contextMenuRef = useRef(null);

  const navRef = useRef(null);
  const [navRefSize] = useElementSize(navRef, undefined);

  useOutsideClick(contextMenuRef, undefined, toggleMenu);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Link to={'/'} title="На главную">
          <img className={styles.logo} src={predatorLogo} alt="Logo" />
        </Link>
        {isLaptop ? (
          <nav className={styles.nav}>
            {APP_NAVIGATION.map(({ title, to, parent }) => (
              <NavLink key={title} to={to} className={styles.link} parent={parent}>
                {title}
              </NavLink>
            ))}
          </nav>
        ) : (
          <div className={styles.mobileMenu}>
            {isActionBarVisible && (
              <IconButton onClick={onClickActionBar} variant="secondary">
                <Burger />
              </IconButton>
            )}
            <div className={styles.dropdownMenu}>
              <button className={styles.nav} onClick={toggleMenu} ref={navRef}>
                <p className={styles.navLink}>{activeNavLink.title}</p>
                <ShevronDown20 className={classNames({ [styles.navLinkIcon]: isMenuOpened })} />
              </button>
              {isMenuOpened && (
                <div
                  className={styles.menu}
                  ref={contextMenuRef}
                  style={{ width: 160, left: -(80 - navRefSize.width / 2) }}>
                  {APP_NAVIGATION.filter(navigation => navigation.title !== activeNavLink.title).map(
                    ({ title, to, parent }, index) => (
                      <NavLink
                        key={title}
                        to={to}
                        parent={parent}
                        className={classNames(styles.anchor, {
                          [styles.anchorLast]: APP_NAVIGATION.length - 2 === index,
                        })}>
                        {title}
                      </NavLink>
                    ),
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div className={styles.actions}>
          <Emergency isEmergency={isEmergency} office={activeOffice} onSuccess={refetchGetMe} />
          <SignOut name={name} />
          {!isLaptop && (
            <IconButton variant="secondary" className={styles.notification} onClick={toggleSidabarModal}>
              <Notification />
              {/* <div className={styles.counter}>
                <span className={styles.number}>3</span>
              </div> */}
            </IconButton>
          )}
        </div>
      </div>
      <SidebarModal isVisible={isSidebarModalOpen} closeCallback={toggleSidabarModal} containerPosition="right">
        <NotificationsSidebar className={styles.sidebar} />
      </SidebarModal>
    </div>
  );
};
