import { memo, useEffect, useRef } from 'react';

import Player from 'video.js/dist/types/player';
import { v4 as uuidv4 } from 'uuid';
import videojs from 'video.js';

type VideoJSPlayerProps = {
  options: any;
  onReady?: (player: Player) => void;
  className?: string;
};

const VideoJSPlayer = memo(({ options, onReady, className }: VideoJSPlayerProps) => {
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.setAttribute('id', uuidv4());
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;

      player.src(options.sources);

      const hideTextTracks = (player: any) => {
        const tracks = player.textTracks();

        if (tracks.length) {
          for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            track.mode = 'hidden';
          }
        }
      };

      player.one('loadeddata', () => {
        try {
          hideTextTracks(player);
        } catch (err) {
          console.error('Error ', (err as Error).message);
        }
      });
    }
  }, [videoRef, options]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player className={className}>
      <div ref={videoRef} />
    </div>
  );
});

export default VideoJSPlayer;
