import { AuthLayout } from '@shared/layouts';
import { MobileScreen } from '@shared/ui/MobileScreen/MobileScreen';
import { SignInForm } from '@features/Auth';
import { Titles } from '@shared/constants/titles';
import { useClientSize } from '@shared/hooks';
import { useTitle } from '@shared/hooks';

export const SignInPage = () => {
  useTitle(Titles.SignIn);
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('mobile');

  return <AuthLayout>{isMobile ? <SignInForm /> : <MobileScreen />}</AuthLayout>;
};
