import {
  SecurityUserOfficeResponseDto,
  useLazySecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsQuery,
} from '@shared/services/apiService/apiService_base';
import { SortOrderEnum, VisitorAttendanceLogsSortFieldsEnum } from '@shared/store/types';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useEffect } from 'react';
import { useLocalStorage } from '@shared/hooks';

type UseGetVisitorAttendanceLogProps = {
  visitorId?: string;
  page: number;
  order: SortOrderEnum;
  sort: VisitorAttendanceLogsSortFieldsEnum;
};

const DEFAULT_GET_VISITORS_ATTENDANCE_LOG_LIMIT = 14;

export const useGetVisitorAttendanceLog = ({ page, order, sort, visitorId }: UseGetVisitorAttendanceLogProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const [triggerGetVisitorAttendanceLogs, visitorAttendanceLogsData] =
    useLazySecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsQuery();

  useEffect(() => {
    if (visitorId && officeId) {
      triggerGetVisitorAttendanceLogs(
        {
          officeId: officeId,
          visitorId: visitorId,
          sort: sort,
          order: order,
          limit: DEFAULT_GET_VISITORS_ATTENDANCE_LOG_LIMIT,
          page: page,
        },
        true,
      );
    }
  }, [officeId, visitorId, order, page, sort, visitorId]);

  const isLoading = visitorAttendanceLogsData.isLoading;
  const visitorAttendanceLogs = visitorAttendanceLogsData.data?.data;
  const pageCount = visitorAttendanceLogsData.data?.meta.pageCount || 0;
  const hasPagination = pageCount ? pageCount > 1 : false;

  return {
    isLoading,
    visitorAttendanceLogs,
    hasPagination,
    pageCount,
  };
};
