import { FC, useRef } from 'react';

import { CloseIconWhite24 } from '@shared/assets/icons/components';
import { Portal } from '@shared/hocs/Portal';
import { SidebarModalProps } from './types';
import classNames from 'classnames';
import styles from './sidebarModal.module.scss';
import { useLockedBody } from '@shared/hooks';

export const SidebarModal: FC<SidebarModalProps> = ({
  isVisible,
  children,
  containerPosition = 'left',
  closeCallback,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useLockedBody(isVisible);

  const classesContainer = classNames(styles.container, styles[containerPosition]);

  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.root}>
        <div className={classesContainer} ref={ref}>
          <button className={styles.closeButton} onClick={closeCallback}>
            <CloseIconWhite24 />
          </button>
          {children && children}
        </div>
        <div className={styles.background} onClick={closeCallback} />
      </div>
    </Portal>
  );
};
