import { AlertVariantEnum } from '../Alert/Alert';
import { v4 as uuidv4 } from 'uuid';

type AlertProps = {
  id: string;
  createdAt: string;
  variant: AlertVariantEnum;
};

export const mockAlerts: AlertProps[] = [
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.DANGER,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.DONE,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
  {
    id: uuidv4(),
    createdAt: '2023-08-30T15:51:53.862Z',
    variant: AlertVariantEnum.ATTENTION,
  },
];

export const mockEvents: AlertProps[] = [];
