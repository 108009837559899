import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFire = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.39 12.438c-2.149 2.083-5.631 2.083-7.78 0A5.235 5.235 0 0 1 2 8.667c0-1.365.537-2.73 1.61-3.772 0 0 .453 1.105 1.828 1.772C5.438 5.333 6.29 2.333 8 1c1 2.5 2.313 2.852 3.39 3.895A5.235 5.235 0 0 1 13 8.667c0 1.365-.537 2.73-1.61 3.77Z"
    />
  </svg>
);
export default SvgFire;
