import { Button } from '@shared/ui';
import { CreateVideoWall } from '@features/CreateVideoWall';
import { FC } from 'react';
import { Plus } from '@shared/assets/icons/components';
import { UpdateVideoWallStreams } from '@features/UpdateVideoWallStreams';
import styles from './videoWallActions.module.scss';
import { useToggle } from '@shared/hooks';
import { useUpdateVideoWallStreams } from '@features/UpdateVideoWallStreams/hooks';

type VideoWallActionsProps = {
  isEmptyWall: boolean;
  isUpdateVideoWallCameraDisable: boolean;
  refetchVideoWallsList: () => void;
};

export const VideoWallActions: FC<VideoWallActionsProps> = ({
  isEmptyWall,
  isUpdateVideoWallCameraDisable,
  refetchVideoWallsList,
}) => {
  const { state: isUpdateVideoWallCameraModalVisible, toggle: toggleUpdateVideoWallCameraModal } = useToggle();

  const { isSuccessUpdateVideoWall, handleUpdateVideoWallStreams } = useUpdateVideoWallStreams();

  return (
    <>
    {isEmptyWall ? 
    <>
        <CreateVideoWall isEmptyWall={isEmptyWall} onSuccess={refetchVideoWallsList} />
        <UpdateVideoWallStreams
        isSuccess={isSuccessUpdateVideoWall}
        isModalVisible={isUpdateVideoWallCameraModalVisible}
        closeCallback={toggleUpdateVideoWallCameraModal}
        onSelectStream={handleUpdateVideoWallStreams}
      />
      </>
      :
      
      <>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          onClick={toggleUpdateVideoWallCameraModal}
          leftIcon={<Plus />}
          disabled={isUpdateVideoWallCameraDisable}>
          Добавить камеру
        </Button>
        <CreateVideoWall onSuccess={refetchVideoWallsList} />
      </div>
      <UpdateVideoWallStreams
        isSuccess={isSuccessUpdateVideoWall}
        isModalVisible={isUpdateVideoWallCameraModalVisible}
        closeCallback={toggleUpdateVideoWallCameraModal}
        onSelectStream={handleUpdateVideoWallStreams}
      />
      </>
    }
    </>
  );
};