import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; 

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatTimezone = (date?: any) => {
  const createdAt = dayjs.utc(date, "YYYY-MM-DD HH:mm:ss");
  const newCreatedAt = createdAt.add(3, 'hour');
  
  date = newCreatedAt.format("YYYY-MM-DD HH:mm:ss");
  
  return date;
};
