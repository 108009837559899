import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTarget20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} clipPath="url(#Target20_svg__a)">
      <path d="M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666ZM18.333 10H15M5 10H1.667M10 5V1.667M10 18.333V15" />
    </g>
    <defs>
      <clipPath id="Target20_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTarget20;
