import * as React from 'react';
import type { SVGProps } from 'react';
const SvgShevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#9B9DA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m5 10 5.698 4.884a2 2 0 0 0 2.604 0L19 10"
    />
  </svg>
);
export default SvgShevronDown;
