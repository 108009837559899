import { ReactElement, RefObject, useCallback } from 'react';

import { Coords } from '../types';
import { useGetCursorCoords } from './useGetCursorCoords';
import { useGetMarkerChild } from './useGetMarkerChild';

export const useHandleMarkerClick = (
  canvasRef: RefObject<HTMLCanvasElement>,
  cursorRef: RefObject<Coords | null>,
  draggingMarkerKeyRef: RefObject<string | null>,
  markersRef: RefObject<ReactElement[]>,
  onClick?: (pt: Coords) => void,
) => {
  const handleClick = useCallback(() => {
    const pt = useGetCursorCoords(canvasRef, cursorRef);

    if (pt === null) {
      return;
    }

    if (!canvasRef.current) {
      return;
    }

    const context = canvasRef.current.getContext('2d');
    if (!context) {
      return;
    }

    let clickedMarker: ReactElement | undefined;

    if (draggingMarkerKeyRef.current) {
      clickedMarker = useGetMarkerChild(draggingMarkerKeyRef.current, markersRef);
    }

    if (clickedMarker) {
      if (typeof clickedMarker.props.onClick === 'function') {
        clickedMarker.props.onClick();
      }
    } else if (typeof onClick === 'function') {
      onClick(pt);
    }
  }, [onClick]);

  return handleClick;
};
