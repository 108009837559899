import { Dispatch, RefObject, SetStateAction, useMemo } from 'react';

export const useHandleImageLoad = (
  imageInitialised: boolean,
  containInitialImage: boolean,
  containUpdatedImage: boolean,
  containmentScale: number,
  canvasRef: RefObject<HTMLCanvasElement>,
  mapImageRef: RefObject<HTMLImageElement>,
  updateContainmentScale: () => void,
  redraw: () => void,
  setImageInitialised: Dispatch<SetStateAction<boolean>>,
) => {
  const handleImageLoad = useMemo(
    () => () => {
      if (!canvasRef.current || !mapImageRef.current) {
        return;
      }

      const context = canvasRef.current.getContext('2d');

      if (!context) {
        return;
      }

      const imgWidth = mapImageRef.current.width;
      const imgHeight = mapImageRef.current.height;

      if (imgWidth && imgHeight) {
        const containing = (!imageInitialised && containInitialImage) || (imageInitialised && containUpdatedImage);
        const widthScaledHeight = (imgHeight / imgWidth) * canvasRef.current.width;
        const heightScaledWidth = (imgWidth / imgHeight) * canvasRef.current.height;

        let newContainmentScale = 1;

        if (widthScaledHeight > canvasRef.current.height) {
          newContainmentScale = canvasRef.current.height / imgHeight;
          if (containing) {
            let transform = context.getTransform();
            const scaleAdjust = newContainmentScale / transform.d;
            context.scale(scaleAdjust, scaleAdjust);
            transform = context.getTransform();
            context.translate(
              (-transform.e + canvasRef.current.width / 2 - heightScaledWidth / 2) / transform.a,
              -transform.f / transform.d,
            );
          }
        } else {
          newContainmentScale = canvasRef.current.width / imgWidth;
          if (containing) {
            let transform = context.getTransform();
            const scaleAdjust = containmentScale / transform.a;
            context.scale(scaleAdjust, scaleAdjust);
            transform = context.getTransform();
            context.translate(
              -transform.e / transform.a,
              (-transform.f + canvasRef.current.height / 2 - widthScaledHeight / 2) / transform.d,
            );
          }
        }

        updateContainmentScale();
        redraw();

        if (!imageInitialised) {
          setImageInitialised(true);
        }
      }
    },
    [imageInitialised, containInitialImage, containUpdatedImage, redraw, containmentScale],
  );

  return handleImageLoad;
};
