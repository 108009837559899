import { Button, Dropzone, InputField, Modal } from '@shared/ui';
import { getFileExtension, getFileName } from '@shared/utils';

import { FormProvider } from 'react-hook-form';
import { Plus } from '@shared/assets/icons/components';
import styles from './createArea.module.scss';
import { useCreateArea } from '../hooks';
import { useEffect } from 'react';
import { useFileStoragesControllerGetSecurityPresignedPutUrlMutation } from '@shared/services/apiService/apiService_base';
import { useToggle } from '@shared/hooks';

type CreateAreaProps = {
  onSuccess: () => void;
};

export const CreateArea = ({ onSuccess }: CreateAreaProps) => {
  const { state: isModalCreateAreaOpen, toggle: toggleCreateAreaModal } = useToggle();

  const { formMethods, onSubmit, isSuccess, isLoading } = useCreateArea();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = formMethods;

  const [getPresignedPutUrl] = useFileStoragesControllerGetSecurityPresignedPutUrlMutation();

  const handleFileRead = async (data: File[]) => {
    if (data.length === 0) {
      return null;
    }

    const file = data[0];
    const fileName = getFileName(file);
    const fileExtension = getFileExtension(file);

    const getPresignedPutUrlData = await getPresignedPutUrl({
      getPresignedPutUrlDto: { fileName: fileName, fileExtension: fileExtension },
    });

    return getPresignedPutUrlData;
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      onSuccess();
      toggleCreateAreaModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    reset();
  }, [isModalCreateAreaOpen]);

  return (
    <>
      <Button onClick={toggleCreateAreaModal} leftIcon={<Plus />}>
        Добавить локацию
      </Button>
      <Modal
        title="Новая локация"
        containerVariant="largeContainer"
        isVisible={isModalCreateAreaOpen}
        closeCallback={toggleCreateAreaModal}>
        <div className={styles.root}>
          <p className={styles.heading}>
            Новая локация будет доступна в разделах: Посетители, Камеры и Маячки. Вы всегда можете удалить созданные
            локации
          </p>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                name="name"
                control={control}
                type="text"
                className={styles.input}
                placeholder="Введите название локации"
                label="Название локации"
                error={errors.name}
              />
              <Dropzone
                name="plan"
                className={styles.dropzone}
                label="План локации"
                onFileRead={handleFileRead}
                error={errors.plan}
              />
              <Button type="submit" variant="primary" size="large" isLoading={isLoading} disabled={!isValid}>
                Создать локацию
              </Button>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
