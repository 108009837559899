import { Button } from '../Button';
import { FC } from 'react';
import styles from './mobilescreen.module.scss';
import { MobileScreenSupport } from '@shared/assets/icons/components';

export const MobileScreen: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <MobileScreenSupport/>
        <p className={styles.title}>Мобильные устройства<br/> пока не поддерживаются</p>
        <p className={styles.subtitle}>Пожалуйста, используйте планшет<br/> или ПК для доступа к Urbanpredator</p>
      </div>
    </div>
  );
};
