import { Button, Modal, Spinner, Table } from '@shared/ui';
import { CloseIcon, Done16 } from '@shared/assets/icons/components';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { SortOrderEnum, VisitorAttendanceLogsSortFieldsEnum } from '@shared/store/types';
import { formatPhoneString, getFullName, showDate } from '@shared/utils';
import { useGetVisitorAttendanceLog, useGetVisitorInfoById } from '../hooks';

import { ModalProps } from '@shared/ui/Modal/types';
import styleModal from '@shared/ui/Modal/modal.module.scss';
import styles from './visitorAttendanceLogsModal.module.scss';

type VisitorAttendanceLogsModalProps = {
  visitorId?: string;
} & ModalProps;

const DEFAULT_PAGE_VISITOR_ATTENDANCE_LOG = 1;

export const VisitorAttendanceLogsModal: FC<VisitorAttendanceLogsModalProps> = ({
  visitorId,
  isVisible,
  closeCallback,
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE_VISITOR_ATTENDANCE_LOG);
  const [order, setOrder] = useState<SortOrderEnum>(SortOrderEnum.DESC);
  const [sort, setSort] = useState<VisitorAttendanceLogsSortFieldsEnum>(VisitorAttendanceLogsSortFieldsEnum.CREATED_AT);

  const isSortOrderDesc = order === SortOrderEnum.DESC;
  const newSortOrder = isSortOrderDesc ? SortOrderEnum.ASC : SortOrderEnum.DESC;

  const handleSort = (sortField: VisitorAttendanceLogsSortFieldsEnum) => {
    setPage(DEFAULT_PAGE_VISITOR_ATTENDANCE_LOG);
    setSort(sortField);
    sort !== sortField ? setOrder(order) : setOrder(newSortOrder);
  };

  const {
    isLoading: isLoadingData,
    visitorAttendanceLogs,
    hasPagination,
    pageCount,
  } = useGetVisitorAttendanceLog({ visitorId, page, order, sort });

  const { visitorInfo, isSuccessVisitorInfo, isLoadingVisitorInfo, isFetchingVisitorInfo } = useGetVisitorInfoById({
    visitorId,
  });

  const isLoading = !visitorAttendanceLogs || isLoadingData || isLoadingVisitorInfo || isFetchingVisitorInfo;
  const biometricsDate = '2023-09-01T02:57:29.523Z'

  const defaultUserAvatar = '/avatars/user-avatar-empty.png'

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);

  const hasSpinner = loading || !isSuccessVisitorInfo;

  return (
    <Modal
      width={1426}
      containerVariant="extraLargeContainer"
      isVisible={isVisible}
      hasHeader={hasSpinner}
      closeCallback={closeCallback}>
      {hasSpinner ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className={styles.root}>
            <div className={styles.sidebar}>
              <div className={styles.avatar}>
                {visitorInfo?.avatarKey ? <img className={styles.image} src={visitorInfo?.avatarKey} /> : <img className={styles.image} src={defaultUserAvatar} />}
                
              </div>
              <p className={styles.fullname}>
                {getFullName({
                  firstName: visitorInfo?.firstName,
                  lastName: visitorInfo?.lastName,
                  middleName: visitorInfo?.middleName,
                })}
              </p>
              <div className={styles.pass}>
                <Done16 />
                <span className={styles.pass_text}>Пропуск действующий</span>
              </div>
              <div className={styles.info}>
                <div className={styles.info_row}>
                  <p className={styles.info_name}>Телефон</p>
                  <p className={styles.info_data}>{formatPhoneString(visitorInfo?.phone)}</p>
                </div>
                <div className={styles.info_row}>
                  <p className={styles.info_name}>Должность</p>
                  <p className={styles.info_data}>Ведущий инженер-программист</p>
                </div>
                <div className={styles.info_row}>
                  <p className={styles.info_name}>Группа</p>
                  <p className={styles.info_data}>Группа МТС</p>
                </div>
                <div className={styles.info_row}>
                  <p className={styles.info_name}>Рабочее место</p>
                  <p className={styles.info_data}>Этаж 6, кабинет 102</p>
                </div>
                <div className={styles.info_row}>
                  <p className={styles.info_name}>Идентификация</p>
                  <p className={styles.info_data}>Пройдена {showDate(biometricsDate)}</p>
                </div>
              </div>
              <div className={styles.buttons}>
                <Button variant="primary" size="large">
                  Идентифицировать
                </Button>
                <Button size="large">Заблокировать пропуск</Button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styleModal.headerContainer}>
                <p className={styleModal.title}>История посещения офиса</p>
                <button className={styleModal.closeButton} onClick={closeCallback}>
                  <CloseIcon />
                </button>
              </div>
              <div className={styles.table}>
                <Table
                  hasPaginator={hasPagination}
                  paginator={{
                    initialPage: page,
                    pageCount: pageCount,
                    onPageChange: setPage,
                    backgroundVariant: 'secondary',
                    classNameTablePaginator: styles.paginator,
                  }}>
                  <Table.THead backgroundVariant="secondary">
                    <Table.TSortableHeadCell
                      defaultIsSortedDesc={sort === VisitorAttendanceLogsSortFieldsEnum.CREATED_AT ? true : undefined}
                      isSortedDesc={
                        sort === VisitorAttendanceLogsSortFieldsEnum.CREATED_AT ? isSortOrderDesc : undefined
                      }
                      onSort={() => handleSort(VisitorAttendanceLogsSortFieldsEnum.CREATED_AT)}
                      width={150}>
                      Дата и время входа
                    </Table.TSortableHeadCell>
                    <Table.THeadCell>Дата и время выхода</Table.THeadCell>
                    <Table.THeadCell>Пропуск</Table.THeadCell>
                    <Table.THeadCell>Рабочее время</Table.THeadCell>
                    <Table.THeadCell>Действия</Table.THeadCell>
                  </Table.THead>
                  <Table.TBody>
                    {visitorAttendanceLogs &&
                      visitorAttendanceLogs.map(visitor => {
                        return (
                          <Table.TRow key={visitor.id}>
                            <Table.TCellFullDate date={visitor.createdAt} />
                            <Table.TCellFullDate date={visitor.createdAt} />
                            <Table.TCell>Постоянный</Table.TCell>
                            <Table.TCell>89%</Table.TCell>
                            <Table.TCell>
                              <Button variant="secondary">Смотреть на карте</Button>
                            </Table.TCell>
                          </Table.TRow>
                        );
                      })}
                  </Table.TBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
