import * as React from 'react';
function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#Settings_svg__clip0_2738_5787)"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M8 10a2 2 0 100-4 2 2 0 000 4z" />
        <path d="M12.933 10a1.1 1.1 0 00.22 1.214l.04.04a1.332 1.332 0 01-.432 2.176 1.334 1.334 0 01-1.454-.29l-.04-.04a1.1 1.1 0 00-1.214-.22 1.1 1.1 0 00-.666 1.007V14a1.333 1.333 0 11-2.667 0v-.06A1.1 1.1 0 006 12.934a1.099 1.099 0 00-1.213.22l-.04.04a1.334 1.334 0 11-1.887-1.887l.04-.04a1.1 1.1 0 00.22-1.213 1.1 1.1 0 00-1.007-.667H2A1.333 1.333 0 112 6.72h.06A1.1 1.1 0 003.067 6a1.1 1.1 0 00-.22-1.213l-.04-.04A1.333 1.333 0 114.693 2.86l.04.04a1.1 1.1 0 001.214.22H6a1.1 1.1 0 00.667-1.006V2a1.333 1.333 0 112.666 0v.06A1.1 1.1 0 0010 3.067a1.1 1.1 0 001.213-.22l.04-.04a1.333 1.333 0 012.177 1.454 1.334 1.334 0 01-.29.433l-.04.04a1.1 1.1 0 00-.22 1.213V6a1.1 1.1 0 001.007.667H14a1.333 1.333 0 010 2.667h-.06a1.1 1.1 0 00-1.007.666z" />
      </g>
      <defs>
        <clipPath id="Settings_svg__clip0_2738_5787">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgSettings;
