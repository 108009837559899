import { useEffect, useState } from "react";

export const useOnlineStatus = () => {
  const [online, setOnline] = useState(typeof window !== "undefined" ? window.navigator.onLine : true);

  useEffect(() => {
    // объявляем хандлер
    const handleStatusChange = () => {
      setOnline(navigator.onLine);
    };

    // слушаем онлайн/оффлайн ивенты
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    // удаляем подписки, для лучшего перфоманса
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  return online;
};
