import { UnauthPath } from '@shared/constants/routes';
import { actions } from '@shared/store/ducks';
import { useAppDispatch } from '@shared/store';
import { useNavigate } from 'react-router-dom';

export const useSignOut = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLogout = async () => {
    await dispatch(actions.auth.signOut());
    navigate(UnauthPath.SIGN_IN);
  };

  return onLogout;
};
