import { VideoWallGridViewEnum } from '../types';
import { isInRange } from '@shared/utils';

type VideoWallGridReturnProps = {
  gridType: VideoWallGridViewEnum;
  gridEmptyCellCount: number;
};

export const MAX_GRID_CELL = 16;

export function useGetVideoWallGrid(length?: number): VideoWallGridReturnProps {
  const defaultLenght = length ? length : 0;

  const isFour = isInRange(defaultLenght, 0, 4);
  const isNine = isInRange(defaultLenght, 5, 9);

  const cellCount: number = isFour ? 4 : isNine ? 9 : MAX_GRID_CELL;

  const gridType = isFour
    ? VideoWallGridViewEnum.FOUR
    : isNine
    ? VideoWallGridViewEnum.NINE
    : VideoWallGridViewEnum.SIXTEEN;

  const gridEmptyCellCount: number = cellCount - defaultLenght;

  return { gridType, gridEmptyCellCount };
}
