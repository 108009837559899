import { PopoverProps, RefPopoverAnchorElement } from './types';
import { forwardRef, useEffect, useState } from 'react';

import classNames from 'classnames';
import { mergeRefs } from 'react-merge-refs';
import styles from './popover.module.scss';
import { usePopper } from 'react-popper';

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ isVisible, anchorElement, children, position = 'bottom-start', className }, ref) => {
    const [popperElement, setPopperElement] = useState<RefPopoverAnchorElement>(null);
    const [referenceElement, setReferenceElement] = useState<RefPopoverAnchorElement>(anchorElement);

    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: position });

    const classesRoot = classNames(styles.root, className);

    useEffect(() => {
      setReferenceElement(anchorElement);
    }, [anchorElement]);

    if (!isVisible) {
      return null;
    }

    return (
      <div
        ref={mergeRefs([ref, setPopperElement])}
        className={classesRoot}
        style={{
          ...popperStyles.popper,
          ...(popperStyles.popper?.transform ? null : { visibility: 'hidden' }),
        }}
        {...attributes.popper}>
        <div className={styles.inner}>{children}</div>
      </div>
    );
  },
);
