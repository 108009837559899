import * as auth from './auth';

import { apiService_base } from '../../services/apiService/apiService_base';
import { apiService_integration } from '@shared/services/apiService/apiService_integration';
import { apiService_log } from '@shared/services/apiService/apiService_log';
import { apiService_videowall } from '@shared/services/apiService/apiService_videowall';
import { combineReducers } from '@reduxjs/toolkit';

export const combinedReducer = combineReducers({
  auth: auth.reducer,
  [apiService_base.reducerPath]: apiService_base.reducer,
  [apiService_videowall.reducerPath]: apiService_videowall.reducer,
  [apiService_log.reducerPath]: apiService_log.reducer,
  [apiService_integration.reducerPath]: apiService_integration.reducer,
});

export const selectors = {
  auth: auth.selectors,
};

export const actions = {
  auth: auth.actions,
};

export type ReducerState = ReturnType<typeof combineReducers>;
