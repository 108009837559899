import { Button, Modal } from '@shared/ui';

import { RadioButtonOffice } from '@pages/Visitors/ui';
import styles from './changeOffice.module.scss';
import { useChangeOffice } from '../hooks';
import { useToggle } from '@shared/hooks';

export const ChangeOffice = () => {
  const { state: isModalChangeOfficeOpen, toggle: toggleChangeOfficeModal } = useToggle();

  const { activeOffice, offices, hasOffices, handleSelectOffice } = useChangeOffice();

  return (
    <>
      <Button onClick={toggleChangeOfficeModal} variant="primary">
        Сменить офис
      </Button>
      <Modal
        title="Выбрать офис"
        containerVariant="largeContainer"
        isVisible={isModalChangeOfficeOpen}
        width={514}
        containerStyles={styles.modalContainer}
        closeCallback={toggleChangeOfficeModal}>
        <div className={styles.root}>
          <div className={styles.list}>
            {hasOffices &&
              offices.map((office, index) => (
                <RadioButtonOffice
                  key={office.id}
                  checked={office.id === activeOffice?.id}
                  officeName={office.name!}
                  address={office.address!}
                  onClick={() => handleSelectOffice(office)}
                  withBorder={offices.length - 1 !== index}
                />
              ))}
          </div>
          <Button type="submit" variant="primary" size="large" onClick={toggleChangeOfficeModal}>
            Подтвердить
          </Button>
        </div>
      </Modal>
    </>
  );
};
