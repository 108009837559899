export const formatPhoneString = (inputString?: string | null) => {
  if (!inputString) {
    return '—';
  }

  const digits = inputString.replace(/[^\d+]/g, '');

  const groups = [];
  let i = 0;
  while (i < digits.length) {
    let group;
    if (i === 2 || i === 5) {
      group = digits.slice(i, i + 3);
    } else {
      group = digits.slice(i, i + 2);
    }
    groups.push(group);
    i += group.length;
  }

  const formattedString = groups.join(' ');

  return formattedString;
};
