import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCloseIconWhite24 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g stroke="#fff" strokeLinecap="round" strokeWidth={2}>
      <path d="m7 7 10 10M17 7 7 17" />
    </g>
  </svg>
);
export default SvgCloseIconWhite24;
