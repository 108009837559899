import { SortOrderEnum, VisitorsSortFieldsEnum } from '@shared/store/types';

import { DEFAULT_LIMIT_ITEMS_ON_PAGE } from '@shared/constants/pagination';
import { SecurityUserVisitorsListItemResponseDto } from '@shared/services/apiService/apiService_base';

export const useGetVisitorsList = (
  visitors: SecurityUserVisitorsListItemResponseDto[],
  page: number,
  sort: VisitorsSortFieldsEnum,
  order: SortOrderEnum,
) => {
  let visitorsList = [...visitors];

  const isSortOrderDesc = order === SortOrderEnum.DESC;

  const orderDesc = isSortOrderDesc ? 1 : -1;
  const orderAsc = isSortOrderDesc ? -1 : 1;

  if (sort === VisitorsSortFieldsEnum.ATTENDED_AT) {
    visitorsList = visitorsList.sort((a, b) => (a.attendedAt! < b.attendedAt! ? orderAsc : orderDesc));
  }

  if (sort === VisitorsSortFieldsEnum.NAME) {
    visitorsList = visitorsList.sort((a, b) => (a.lastName! < b.lastName! ? orderAsc : orderDesc));
  }

  if (sort === VisitorsSortFieldsEnum.PHONE) {
    visitorsList = visitorsList.sort((a, b) => (a.phone! < b.phone! ? orderAsc : orderDesc));
  }

  if (sort === VisitorsSortFieldsEnum.COMPANY_NAME) {
    visitorsList = visitorsList.sort((a, b) => (a.companyName! < b.companyName! ? orderAsc : orderDesc));
  }

  if (sort === VisitorsSortFieldsEnum.DURATION) {
    visitorsList = visitorsList.sort((a, b) => (a.attendedAt! < b.attendedAt! ? orderAsc : orderDesc));
  }

  if (sort === VisitorsSortFieldsEnum.ROLE) {
    visitorsList = visitorsList.sort((a, b) => (a.role! < b.role! ? orderAsc : orderDesc));
  }

  const sliceStart = DEFAULT_LIMIT_ITEMS_ON_PAGE * (page - 1);
  const sliceEnd = DEFAULT_LIMIT_ITEMS_ON_PAGE * page;

  const visitorsListPaginated = visitorsList?.slice(sliceStart, sliceEnd);

  return visitorsListPaginated;
};
