import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFireOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M4.11 12.438c2.149 2.083 5.631 2.083 7.78 0a5.236 5.236 0 0 0 1.61-3.771c0-.563-.091-1.127-.274-1.667m-1.607-2.35C10.599 3.78 9.418 3.294 8.5 1 6.791 2.333 5.938 5.333 5.938 6.667 4.563 6 4.11 4.895 4.11 4.895A5.236 5.236 0 0 0 2.5 8.667a5.2 5.2 0 0 0 .305 1.756M13.225 7a5.189 5.189 0 0 0-.125-.333l.126.333ZM14.5 2l-12 12" />
    </g>
  </svg>
);
export default SvgFireOff;
