import { Button, IconButton, Modal } from '@shared/ui';
import { Fire, FireOff } from '@shared/assets/icons/components';
import { useClientSize, useToggle } from '@shared/hooks';
import { useEffect, useState } from 'react';

import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { TextareaField } from '@shared/ui/Textarea/TextareaField';
import classNames from 'classnames';
import styles from './emergency.module.scss';
import { useEmergency } from '../hooks';

type EmergencyProps = {
  office?: SecurityUserOfficeResponseDto;
  isEmergency?: boolean;
  onSuccess: () => void;
};

export const Emergency = ({ isEmergency, office, onSuccess }: EmergencyProps) => {
  const { state: isEmergencyModalVisible, setTrue: openEmergencyModal, setFalse: closeEmergencyModal } = useToggle();

  const {
    isActivateLoading,
    isActivateSuccess,
    activateEmergency,
    isDeactivateLoading,
    isDeactivateSuccess,
    deactivateEmergency,
  } = useEmergency();

  const handleActivateEmergency = () => {
    office &&
      activateEmergency({ officeId: office.id, activateEmergencyModeDto: { emergencyDescription: emergencyText } });
  };

  const handleDeactivateEmergency = () => {
    office && deactivateEmergency({ officeId: office.id });
  };

  useEffect(() => {
    if (isActivateSuccess || isDeactivateSuccess) {
      onSuccess();
      closeEmergencyModal();
    }
  }, [isActivateSuccess, isDeactivateSuccess]);

  const [emergencyText, setEmergencyText] = useState(
    'В офисе объявлена эвакуация. Пожалуйста, не игнорируйте это сообщение, сохраняйте спокойствие и покидайте здание через пожарные лестницы согласно планам эвакуации.',
  );
  const buttonText = isEmergency ? 'Отменить режим ЧС' : 'Оповестить о ЧС';
  const modalTitle = isEmergency ? 'Оповещение посетителей о режиме ЧС' : 'Оповещение посетителей о режиме ЧС';
  const modalContent = isEmergency
    ? 'Мы отправим СМС-оповещение всем посетителям, которые находятся в офисе. Текст оповещения можно изменить ниже'
    : 'Мы отправим СМС-оповещение всем посетителям, которые находятся в офисе. Текст оповещения можно изменить ниже';

  const classesRoot = classNames(styles.root, { [styles.rootIsEmergency]: isEmergency });

  const { getIsBreakpoint } = useClientSize();

  const isDesktopL = getIsBreakpoint('desktopL');

  return (
    <>
      <div className={classesRoot}>
        {isDesktopL ? (
          <Button
            variant={isEmergency ? 'secondary' : 'primary'}
            onClick={openEmergencyModal}
            leftIcon={isEmergency ? <FireOff /> : <Fire />}>
            {buttonText}
          </Button>
        ) : (
          <IconButton onClick={openEmergencyModal} variant={isEmergency ? 'secondary' : 'negative'}>
            {isEmergency ? <FireOff /> : <Fire />}
          </IconButton>
        )}
      </div>
      <Modal
        title={modalTitle}
        width={514}
        containerVariant="largeContainer"
        isVisible={isEmergencyModalVisible}
        closeCallback={closeEmergencyModal}>
        <div className={styles.container}>
          <p className={styles.content}>{modalContent}</p>
          {!isEmergency && (
            <>
              <label className={styles.emergencyTextLabel} htmlFor="emergencyText">
                Текст оповещения
              </label>
              <textarea
                className={styles.emergencyTextarea}
                name="emergencyText"
                defaultValue={emergencyText}
                // label="Текст оповещения"
                placeholder="Напишите текст оповещения"
                required={true}
                onChange={e => {
                  setEmergencyText(e.target.value);
                }}
              />
            </>
          )}

          <div className={styles.buttons}>
            {isEmergency ? (
              <>
                <Button
                  size="large"
                  variant="primary"
                  onClick={handleDeactivateEmergency}
                  isLoading={isDeactivateLoading}>
                  Да, угрозы больше нет
                </Button>
                <Button size="large" variant="secondary" onClick={closeEmergencyModal}>
                  Нет, не хочу
                </Button>
              </>
            ) : (
              <>
                <Button size="large" variant="primary" onClick={handleActivateEmergency} isLoading={isActivateLoading}>
                  Оповестить
                </Button>
                <Button size="large" variant="secondary" onClick={closeEmergencyModal}>
                  Отмена
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
