import { useCallback, useEffect, useRef, useState } from 'react';

import { CoordinatesDataProps } from '../lib/types';
import { getShortlyCues } from '../lib/utils/getShortlyCues';
import { isInArea } from '../lib/utils/isInArea';

export const usePredatorStreamCoordinatesStore = (playerWrapEl: HTMLDivElement | null) => {
  const [coordinatesData, setCoordinatesData] = useState<CoordinatesDataProps[]>([]);
  const [checkedPersonId, setCheckedPersonId] = useState<string | number | null>(null);
  const [drawCoordinates, setDrawCoordinates] = useState<CoordinatesDataProps | null>(null);

  const widthScaleCoefficient = useRef<number>(1);
  const heightScaleCoefficient = useRef<number>(1);

  const VIDEO_STANDART_WIDTH = 1920;
  const VIDEO_STANDART_HEIGHT = 1080;

  const refreshCurrentCoordinatesData = useCallback(
    (cues: VTTCue[], currentTime: number) => {
      const shortlyCue = getShortlyCues([...cues], currentTime);

      if (shortlyCue) {
        const parsedCue = JSON.parse(shortlyCue.text) as CoordinatesDataProps[];
        const scaledCoordinates = parsedCue.map(cue => ({
          id: cue.id,
          x: cue.x * widthScaleCoefficient.current,
          y: cue.y * heightScaleCoefficient.current,
          xmax: cue.xmax * widthScaleCoefficient.current,
          xmin: cue.xmin * widthScaleCoefficient.current,
          ymax: cue.ymax * heightScaleCoefficient.current,
          ymin: cue.ymin * heightScaleCoefficient.current,
        }));
        setCoordinatesData(scaledCoordinates);
      }
    },
    [setCoordinatesData],
  );

  function getIdByCoordinates(x: number, y: number) {
    const clickedCoordinates = coordinatesData.find(coordinates => isInArea(x, y, coordinates));

    if (clickedCoordinates) {
      setCheckedPersonId(clickedCoordinates?.id);
      setDrawCoordinates(clickedCoordinates);
    }
  }

  const clearCheckedPerson = useCallback(() => {
    setCheckedPersonId(null);
    setDrawCoordinates(null);
  }, [setCheckedPersonId, setDrawCoordinates]);

  useEffect(() => {
    if (checkedPersonId) {
      const coordinatesById = coordinatesData.find(coordinates => coordinates.id === checkedPersonId);

      if (coordinatesById) {
        setDrawCoordinates(coordinatesById);
      } else {
        setDrawCoordinates(null);
      }
    }
  }, [coordinatesData, checkedPersonId]);

  useEffect(() => {
    if (playerWrapEl) {
      widthScaleCoefficient.current = playerWrapEl.clientWidth / VIDEO_STANDART_WIDTH;
      heightScaleCoefficient.current = playerWrapEl.clientHeight / VIDEO_STANDART_HEIGHT;
    }
  }, [playerWrapEl]);

  return {
    coordinatesData,
    refreshCurrentCoordinatesData,
    getIdByCoordinates,
    drawCoordinates,
    checkedPersonId,
    clearCheckedPerson,
  };
};
