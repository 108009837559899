import { DEFAULT_VISITORS_MAP_POOLING_INTERVAL, VisitorsMapPath } from '../constants';
import { useEffect, useState } from 'react';

import { MAP_MARKER_DEFAULT_RADIUS } from '@shared/lib/react-canvas/constants';
import { REACT_APP_VISITORS_MOCK_URL } from '@shared/constants/environments';
import { VisitorPositionResponseDto } from '../types';
import axios from 'axios';
import { checkSum } from '../utils';
import { useParams } from 'react-router-dom';

const removeOverlaps = require('remove-overlaps');

export const useVisitorsPosition = () => {
  const { areaId } = useParams();

  const [visitorsPositions, setVisitorsPositions] = useState<VisitorPositionResponseDto[]>([]);

  //mocked visitors and position
  // const mockUrl = REACT_APP_VISITORS_MOCK_URL || 'localhost';
  const visitorsPositionProd = VisitorsMapPath.VISITORS_POSITION(areaId);

  useEffect(() => {
    const timer = setInterval(async () => {
      axios.get(visitorsPositionProd).then(function (response) {
        const visitorsPositions: VisitorPositionResponseDto[] = response.data.map(
          (item: VisitorPositionResponseDto) => ({
            visitorUuid: item.visitorUuid,
            seenTime: item.seenTime,
            x: (item.x + Math.sin(checkSum(item.visitorUuid))) * 100,
            y: (item.y + Math.cos(checkSum(item.visitorUuid))) * 100,
            r: MAP_MARKER_DEFAULT_RADIUS,
          }),
        );

        const overlapsOptions = {
          maxMove: 200,
          maxIterations: 100,
          method: 'circle',
        };

        for (let i = 0; i < 20; ++i) {
          const r = removeOverlaps(visitorsPositions, overlapsOptions);
          if (r < 1) {
            break;
          }
        }

        setVisitorsPositions(visitorsPositions);
      });
    }, DEFAULT_VISITORS_MAP_POOLING_INTERVAL);

    return () => clearInterval(timer);
  }, [areaId]);

  const visitorsIds = visitorsPositions.map(item => item.visitorUuid);

  return { visitorsPositions, visitorsIds };
};
