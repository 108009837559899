import {
  SecurityUserOfficeResponseDto,
  useLazySecurityUsersOfficesV2ControllerGetVisitorInfoQuery,
} from '@shared/services/apiService/apiService_base';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useEffect } from 'react';
import { useLocalStorage } from '@shared/hooks';

type GetVisitorInfoByIdProps = {
  visitorId?: string;
};

export const useGetVisitorInfoById = ({ visitorId }: GetVisitorInfoByIdProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const [triggerGetVisitorInfoById, dataVisitorInfo] = useLazySecurityUsersOfficesV2ControllerGetVisitorInfoQuery();

  useEffect(() => {
    if (!officeId || !visitorId) {
      return;
    }

    triggerGetVisitorInfoById({ officeId: officeId, visitorId: visitorId }, false);
  }, [officeId, visitorId]);

  const visitorInfo = dataVisitorInfo.data;
  const isSuccessVisitorInfo = dataVisitorInfo.isSuccess;
  const isLoadingVisitorInfo = dataVisitorInfo.isLoading;
  const isFetchingVisitorInfo = dataVisitorInfo.isFetching;

  return { visitorInfo, isSuccessVisitorInfo, isLoadingVisitorInfo, isFetchingVisitorInfo };
};
