import { VisitorRoleEnum } from '@pages/Visitors/types';
import { markerColors } from './markerColors';

export function getMarkerPrimaryColor(role: VisitorRoleEnum) {
  const primaryMarkerColorMapping = {
    [VisitorRoleEnum.EMPLOYEE]: markerColors.employee_primary,
    [VisitorRoleEnum.GUEST]: markerColors.guest_primary,
    [VisitorRoleEnum.CONTRACTOR]: markerColors.contractor_primary,
    [VisitorRoleEnum.UNIDENTIFIED]: markerColors.unidentified_primary,
    [VisitorRoleEnum.SECURITY]: markerColors.security_primary,
  };

  return primaryMarkerColorMapping[role];
}
