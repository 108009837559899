import { Button, IconButton } from '@shared/ui';
import { CloseIcon, Guests, Profile, Target, Vision } from '@shared/assets/icons/components';
import { memo, useRef } from 'react';

import { SecurityUserVisitorInfoResponseDto } from '@shared/services/apiService/apiService_base';
import { VisitorRoleEnum } from '@pages/Visitors/types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getShortenFullName } from '@shared/utils/getShortenFullname';
import styles from './visitorCard.module.scss';
import { useDebounce } from '@shared/hooks/useDebounce';
import { useHover } from '@shared/hooks';

type VisitorCardProps = {
  className?: string;
  cardClassName?: string;
  visitorInfo?: SecurityUserVisitorInfoResponseDto;
  closeCallback: () => void;
};
//TODO: need api
const VisitorCard = memo(({ visitorInfo, closeCallback, className, cardClassName }: VisitorCardProps) => {
  const fullname = getShortenFullName({
    firstName: visitorInfo?.firstName,
    lastName: visitorInfo?.lastName,
    middleName: visitorInfo?.middleName,
  });
  const company = visitorInfo?.companyName;
  const accessTimestamp = visitorInfo?.accessFrom;
  const accessFrom = accessTimestamp ? dayjs(accessTimestamp).format('HH:MM') : '-';
  const avatarEmployee = visitorInfo?.avatarKey || '';
  const avatarGuest = '/avatars/guest.jpg';
  const avatarContractor = '/avatars/Staff.jpg';
  const avatarUnidentified = '/avatars/unidentified.jpg';
  const avatarSecurity = '/avatars/security.jpg';
  const visitorRole = visitorInfo?.role ? visitorInfo?.role : VisitorRoleEnum.UNIDENTIFIED;
  const hostFullname = 'Пригласитель А. В.';
  const hostAvatar = 'https://i.pravatar.cc/300';
  const contractorCompanyName = 'ИП Хачатурян И. Г.';

  const EmployeeContent = () => (
    <>
      <div className={styles.info}>
        <div className={styles.heading}>
          <Profile />
          <p className={styles.fullname}>{fullname}</p>
        </div>
        <p className={styles.company}>{company}</p>
        <p className={styles.accessFrom}>Находится в офисе с {accessFrom}</p>
      </div>
      <Button variant="primary" className={styles.button}>
        Посмотреть профиль
      </Button>
    </>
  );

  const GuestContent = () => (
    <>
      <div className={styles.info}>
        <div className={styles.heading}>
          <Guests className={styles.headingGuestIcon} />
          <p className={styles.fullname}>{fullname} (гость)</p>
        </div>
        <p className={styles.accessFrom}>Находится в офисе с {accessFrom}</p>
        <span className={styles.divider} />
        <div className={styles.host}>
          <div className={styles.hostAvatar}>
            <img src={hostAvatar} />
          </div>
          <div className={styles.hostInfo}>
            <p className={styles.hostHeading}>Пригласил сотрудник</p>
            <p className={styles.hostFullname}>{hostFullname}</p>
          </div>
        </div>
      </div>
      <Button variant="primary" className={styles.button}>
        Посмотреть профиль
      </Button>
    </>
  );

  const ContractorContent = () => (
    <>
      <div className={styles.info}>
        <div className={styles.heading}>
          <Profile />
          <p className={styles.fullname}>{fullname}</p>
        </div>
        <p className={styles.company}>{contractorCompanyName}</p>
        <p className={styles.accessFrom}>Находится в офисе с {accessFrom}</p>
      </div>
      <Button variant="primary" className={styles.button}>
        Посмотреть профиль
      </Button>
    </>
  );

  const UnidentifiedContent = () => (
    <>
      <div className={styles.info}>
        <div className={styles.heading}>
          <Target className={styles.headingTargetIcon} />
          <p className={styles.fullname}>Неизвестный</p>
        </div>
        <p className={styles.accessFrom}>Находится в офисе с {accessFrom}</p>
      </div>
      <Button variant="primary" className={styles.button}>
        Идентифицировать
      </Button>
    </>
  );

  const SecurityContent = () => (
    <>
      <div className={styles.info}>
        <div className={styles.heading}>
          <Vision className={styles.headingVisionIcon} />
          <p className={styles.fullname}>{fullname}</p>
        </div>
        <p className={styles.company}>Служба безопасности</p>
        <p className={styles.accessFrom}>Находится в офисе с {accessFrom}</p>
      </div>
      <Button variant="primary" className={styles.button}>
        Идентифицировать
      </Button>
    </>
  );

  const visitorAvatarMapping = {
    [VisitorRoleEnum.EMPLOYEE]: avatarEmployee,
    [VisitorRoleEnum.GUEST]: avatarGuest,
    [VisitorRoleEnum.CONTRACTOR]: avatarContractor,
    [VisitorRoleEnum.UNIDENTIFIED]: avatarUnidentified,
    [VisitorRoleEnum.SECURITY]: avatarSecurity,
  };

  const visitorContentMapping = {
    [VisitorRoleEnum.EMPLOYEE]: <EmployeeContent />,
    [VisitorRoleEnum.GUEST]: <GuestContent />,
    [VisitorRoleEnum.CONTRACTOR]: <ContractorContent />,
    [VisitorRoleEnum.UNIDENTIFIED]: <UnidentifiedContent />,
    [VisitorRoleEnum.SECURITY]: <SecurityContent />,
  };

  const visitorCardRef = useRef(null);
  const buttonCloseRef = useRef(null);

  const isHoverCard = useHover(visitorCardRef);
  const isHoverButton = buttonCloseRef ? useHover(buttonCloseRef) : false;

  const debouncedHoverCard = useDebounce<boolean>(isHoverCard, 150);
  const debouncedHoverButton = useDebounce<boolean>(isHoverButton, 150);

  const hasCloseButtonVisible = isHoverCard || debouncedHoverCard || isHoverButton || debouncedHoverButton;

  const classesButtonClose = classNames(styles.buttonClose, {
    [styles.buttonCloseVisible]: hasCloseButtonVisible,
    [styles.buttonCloseAnimationIn]: hasCloseButtonVisible,
    [styles.buttonCloseAnimationOut]: !hasCloseButtonVisible,
  });

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.card} ref={visitorCardRef}>
        <div className={classNames(styles.user, cardClassName)}>
          <div className={styles.avatar}>
            <img src={visitorAvatarMapping[visitorRole]} />
          </div>
          {visitorContentMapping[visitorRole]}
        </div>
      </div>
      <div className={styles.buttonContainer} ref={buttonCloseRef}>
        <IconButton className={classesButtonClose} onClick={closeCallback} variant="secondary">
          <CloseIcon width={12} height={12} />
        </IconButton>
      </div>
    </div>
  );
});

export default VisitorCard;
