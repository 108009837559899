import React, { useEffect, useState } from 'react';
import ReactOvenPlayer, { ReactOvenPlayerState } from 'react-ovenplayer';

export const TestPage = () => {
  const [state, setState] = useState(null);

  return (
    <div>
      {/* wss://ll-live.alien.urbanpredator.tech/raw/afd52543-8a41-4cca-97c9-99cd955294d9 */}
      <ReactOvenPlayer
        wrapperStyles={{
          minWidth: 500,
        }}
        setState={setState}
        config={{
          autoStart: true,
          autoFallback: true,
          controls: false,
          showBigPlayButton: false,
          mute: true,
          webrtcConfig: {
            timeoutMaxRetry: 5,
            connectionTimeout: 10000,
          },
          sources: [
            {
              label: 'ap-webrtc',
              type: 'webrtc',
              file: `wss://ll-live.alien.urbanpredator.tech/predator/afd52543-8a41-4cca-97c9-99cd955294d9`,
            },
          ],
        }}
      />
    </div>
  );
};
