import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDropzoneFileUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <path
      fill="#717680"
      fillRule="evenodd"
      d="M30.048 2.75A.75.75 0 0 0 29 2.061l-8.337 3.6-9.562-3.613a.75.75 0 0 0-.562.013l-8.616 3.72a.75.75 0 0 0-.452.69v19.664a.75.75 0 0 0 1.047.688l8.337-3.6 3.936 1.487a9.836 9.836 0 0 1-.246-1.696l-2.959-1.118V3.836l8.346 3.153v6.663a9.79 9.79 0 0 1 1.5-.61V6.964l7.116-3.072v9.63c.527.247 1.029.54 1.5.872V2.75ZM10.086 3.89v18.031l-7.115 3.073V6.964l7.115-3.073Z"
      clipRule="evenodd"
    />
    <g stroke="#717680" strokeWidth={1.5}>
      <circle cx={24.375} cy={22.442} r={6.154} strokeLinejoin="round" />
      <path strokeLinecap="round" d="M24.375 19.98v4.924M26.836 22.442h-4.923" />
    </g>
  </svg>
);
export default SvgDropzoneFileUpload;
