import { LogOut as LogOutIcon, Profile } from '@shared/assets/icons/components';

import { IconButton } from '@shared/ui';
import styles from './signOut.module.scss';
import { useClientSize } from '@shared/hooks';
import { useSignOut } from '../hooks/useSignOut';

type SignOutProps = {
  name: string;
};

export const SignOut = ({ name }: SignOutProps) => {
  const onLogout = useSignOut();

  const { getIsBreakpoint } = useClientSize();
  const isDesktopL = getIsBreakpoint('desktopL');

  const profileIconSize = isDesktopL ? 24 : 20;
  const logoutIconSize = isDesktopL ? 20 : 16;

  return (
    <div className={styles.root}>
      <Profile width={profileIconSize} height={profileIconSize} />
      <span className={styles.user}>{name}</span>
      <IconButton onClick={onLogout} variant="secondary">
        <LogOutIcon width={logoutIconSize} height={logoutIconSize} />
      </IconButton>
    </div>
  );
};
