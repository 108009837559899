import Select, { DropdownIndicatorProps, StylesConfig, components } from 'react-select';

import { SelectProps } from './types';
import { ShevronDown } from '@shared/assets/icons/components';
import { useToggle } from '@shared/hooks';

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <ShevronDown />
  </components.DropdownIndicator>
);

export const BaseSelect = <T extends unknown>({ options, selected, onChange, onFocus, onBlur }: SelectProps<T>) => {
  const { state: isOpen, setTrue: open, setFalse: close } = useToggle(false);

  const customStyles: StylesConfig = {
    indicatorSeparator: () => ({}),
    option: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      height: 'auto',
      position: 'relative',
      fontFamily: 'Aeroport',
      fontSize: '16px',
      lineHeight: '21px',
      backgroundColor: 'var(--background-dark-inputs)',
      color: 'var(--text-dark-primary)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'var(--background-dark-stroke)',
      },
      '&:active': {
        backgroundColor: 'var(--background-dark-inputs)',
      },
    }),
    control: provided => ({
      ...provided,
      width: '180px',
      minHeight: '36px',
      flexWrap: 'nowrap',
      borderRadius: '8px',
      borderColor: 'var(--background-dark-stroke)',
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: '12px 0px 12px 12px',
      height: 28,
      outline: 'none',
      boxShadow: 'none',
      caretColor: 'none',
      backgroundColor: 'var(--background-dark-inputs)',
      '&:hover': {
        cursor: 'pointer',
        borderColor: 'var(--background-dark-stroke)',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '0px',
      fontFamily: 'Aeroport',
      fontSize: '16px',
      lineHeight: '21px',
    }),
    singleValue: provided => ({
      ...provided,
      padding: 0,
      color: 'var(--text-dark-primary)',
    }),
    menu: provided => ({
      ...provided,
      width: '100%',
      borderRadius: '8px',
      overflow: 'hidden',
      marginTop: 10,
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: 'var(--background-dark-inputs)',
      border: '1px solid var(--background-dark-stroke)',
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      margin: 0,
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      transform: isOpen ? 'rotate(180deg)' : 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      padding: '0px',
    }),
  };

  return (
    <Select
      value={selected}
      styles={customStyles}
      options={options}
      tabIndex={0}
      onMenuOpen={open}
      onMenuClose={close}
      isSearchable={false}
      // @ts-ignore
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      defaultValue={selected}
      components={{
        ...components,
        DropdownIndicator: DropdownIndicator || components.DropdownIndicator,
      }}
    />
  );
};
