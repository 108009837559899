import { MouseEvent, useCallback, useEffect, useRef } from 'react';

import { CoordinatesDataProps } from '@features/PredatorStream/lib/types';
import styles from './canvasStream.module.scss';

interface CanvasStreamProps {
  onChooseRect?: (x: number, y: number) => void;
  drawCoordinates: CoordinatesDataProps | null;
}

export const CanvasStream = ({ onChooseRect, drawCoordinates }: CanvasStreamProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawQuadrilateral = useCallback(
    (ctx: CanvasRenderingContext2D | null, xmin: number, ymin: number, xmax: number, ymax: number) => {
      if (!ctx) return;
      if (!canvasRef.current) return;

      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      ctx.strokeStyle = 'red';
      ctx.lineWidth = 3;

      ctx.beginPath();
      ctx.moveTo(xmin, ymin);
      ctx.lineTo(xmin, ymax);
      ctx.lineTo(xmax, ymax);
      ctx.lineTo(xmax, ymin);
      ctx.closePath();
      ctx.stroke();
    },
    [canvasRef.current],
  );

  const handleCanvasClick = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    const rect = canvasRef.current.getBoundingClientRect();

    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;

    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    onChooseRect && onChooseRect(x, y);
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const parent = canvas.parentElement;
    const width = parent?.clientWidth as number;
    const height = parent?.clientHeight as number;

    canvas.width = width;
    canvas.height = height;
  }, [canvasRef.current]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    if (drawCoordinates) {
      const { xmin, xmax, ymax, ymin } = drawCoordinates;
      drawQuadrilateral(ctx, xmin, ymin, xmax, ymax);
    } else {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  }, [drawCoordinates, drawQuadrilateral]);

  return <canvas ref={canvasRef} className={styles.root} onClick={handleCanvasClick} />;
};
