import { AuthPath, JoutnalPath, NotificationsPath, VideoWallPath, VisitorsPath } from '@shared/constants/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PassagesSuspicious, PassagesVerified } from '@pages/Notifications/pages';
import {
  SecurityUserOfficeResponseDto,
  useSecurityUsersV2ControllerGetMeQuery,
} from '@shared/services/apiService/apiService_base';
import { StreamPage, VideoWall } from '@pages/VideoWalls/pages';

import { Dashboard } from '@pages/Dashboard';
import { Journal } from '@pages/Journal/pages';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { TestPage } from '@pages/TestPage/TestPage';
import { Player } from '@pages/Player/Player';
import { Visitors } from '@pages/Visitors/pages';
import { useEffect } from 'react';
import { useLocalStorage } from '@shared/hooks';

export const AuthorizedRoutes = () => {
  const [office, setOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(
    StorageKeys.UP_OFFICE,
    undefined,
  );

  const { data: getMe } = useSecurityUsersV2ControllerGetMeQuery();

  useEffect(() => {
    if (office === undefined) {
      getMe?.offices && setOffice(getMe?.offices[0]);
    }
  }, [getMe]);

  return (
    <Routes>
      <Route path={'/test'} element={<TestPage />} />
      <Route path={'/player'} element={<Player />} />
      <Route path={AuthPath.DASHBOARD} element={<Dashboard />} />
      <Route path={AuthPath.JOURNAL}>
        <Route path={JoutnalPath.ALL} element={<Journal />} />
        <Route path={AuthPath.JOURNAL} element={<Navigate to={JoutnalPath.ALL} replace />} />
      </Route>
      <Route path={AuthPath.NOTIFICATIONS}>
        <Route path={NotificationsPath.SUSPICIOUS} element={<PassagesSuspicious />} />
        <Route path={NotificationsPath.VERIFIED} element={<PassagesVerified />} />
        <Route path={AuthPath.NOTIFICATIONS} element={<Navigate to={NotificationsPath.SUSPICIOUS} replace />} />
      </Route>
      <Route path={AuthPath.VISITORS}>
        <Route path={AuthPath.VISITORS} element={<Visitors />} />
        <Route path={VisitorsPath.VISITORS_BY_AREA_ID()} element={<Visitors />} />
        <Route path={AuthPath.VISITORS} element={<Navigate to={AuthPath.VISITORS} replace />} />
      </Route>
      <Route path={AuthPath.VIDEO_WALLS}>
        <Route path={AuthPath.VIDEO_WALLS} element={<VideoWall />} />
        <Route path={VideoWallPath.VIDEO_WALL_BY_ID()} element={<VideoWall />} />
        <Route path={VideoWallPath.STREAM_BY_ID()} element={<StreamPage />} />
      </Route>
      <Route path="*" element={<Navigate to={AuthPath.DASHBOARD} replace />} />
    </Routes>
  );
};
