import * as React from 'react';
function SvgSpinnerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="spinner-icon_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={16}
        y={4}
        width={7}
        height={8}>
        <path
          d="M16.767 6.45L18.6 4.073A9.992 9.992 0 0122.5 12h-3a6.991 6.991 0 00-2.733-5.55z"
          fill="url(#spinner-icon_svg__paint0_linear_74516_411593)"
        />
      </mask>
      <g mask="url(#spinner-icon_svg__a)">
        <path fill="#fff" d="M1.5 1h22v22h-22z" />
      </g>
      <mask
        id="spinner-icon_svg__b"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={12}
        y={12}
        width={11}
        height={10}>
        <path
          d="M12.5 19a7 7 0 007-7h3c0 .173-.005.348-.014.523-.28 5.34-4.7 9.481-9.986 9.477v-3z"
          fill="url(#spinner-icon_svg__paint1_linear_74516_411593)"
        />
      </mask>
      <g mask="url(#spinner-icon_svg__b)">
        <path fill="#fff" d="M1.5 1h22v22h-22z" />
      </g>
      <mask
        id="spinner-icon_svg__c"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={12}
        width={11}
        height={10}>
        <path
          d="M12.134 18.99c.122.006.245.01.366.01v3c-.174 0-.348-.005-.523-.014-5.34-.28-9.481-4.7-9.477-9.986h3a7 7 0 006.634 6.99z"
          fill="url(#spinner-icon_svg__paint2_linear_74516_411593)"
        />
      </mask>
      <g mask="url(#spinner-icon_svg__c)">
        <path fill="#fff" d="M1.5 1h22v22h-22z" />
      </g>
      <mask
        id="spinner-icon_svg__d"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={1}
        width={13}
        height={11}>
        <path
          d="M12.866 5.01A7 7 0 005.5 12h-3c0-.174.005-.348.014-.524.289-5.515 4.994-9.752 10.51-9.463a1.5 1.5 0 01-.158 2.996z"
          fill="url(#spinner-icon_svg__paint3_linear_74516_411593)"
        />
      </mask>
      <g mask="url(#spinner-icon_svg__d)">
        <path fill="#fff" d="M1.5 1h22v22h-22z" />
      </g>
      <defs>
        <linearGradient
          id="spinner-icon_svg__paint0_linear_74516_411593"
          x1={19.5}
          y1={12}
          x2={16.5}
          y2={4}
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity={0.08} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="spinner-icon_svg__paint1_linear_74516_411593"
          x1={12.5}
          y1={19}
          x2={19.5}
          y2={12}
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity={0.35} />
          <stop offset={1} stopOpacity={0.08} />
        </linearGradient>
        <linearGradient
          id="spinner-icon_svg__paint2_linear_74516_411593"
          x1={5.5}
          y1={12}
          x2={12.5}
          y2={19}
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity={0.65} />
          <stop offset={1} stopOpacity={0.35} />
        </linearGradient>
        <linearGradient
          id="spinner-icon_svg__paint3_linear_74516_411593"
          x1={14.5}
          y1={5}
          x2={5.5}
          y2={12}
          gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset={1} stopOpacity={0.65} />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgSpinnerIcon;
