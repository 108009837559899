import { BreakpointsEnum, BreakpointsType } from '@shared/types';
import { useCallback, useEffect, useState } from 'react';

import { throttle } from 'throttle-debounce';

export const useClientSize = () => {
  const [width, setWidth] = useState<number | undefined>(
    typeof window !== 'undefined' ? document.documentElement.clientWidth : undefined,
  );
  const [height, setHeight] = useState<number | undefined>(
    typeof window !== 'undefined' ? document.documentElement.clientHeight : undefined,
  );

  const getIsBreakpoint = (breakpoint: BreakpointsType) => {
    if (!width) return false;

    const breakpointWidth = BreakpointsEnum[breakpoint];

    return breakpointWidth <= width;
  };

  const getBreakpoint = useCallback(
    (screenWidth?: number): BreakpointsType | null => {
      const targetWidth = screenWidth || width || 0;

      const enums = Object.keys(BreakpointsEnum) as BreakpointsType[];

      const targetEnum = enums.find(enumItem => targetWidth >= BreakpointsEnum[enumItem]);

      if (!targetEnum || !targetWidth) return null;

      return targetEnum;
    },
    [height, width],
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(document.documentElement.clientWidth || undefined);
      setHeight(document.documentElement.clientHeight || undefined);
    };

    handleResize();

    const throttleHandleResize = throttle(500, handleResize);

    window.addEventListener('resize', throttleHandleResize);
    return () => window.removeEventListener('resize', throttleHandleResize);
  }, []);

  return { width, height, getIsBreakpoint, getBreakpoint };
};
