import { FC, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import styles from './radioButton.module.scss';

export const RadioButton: FC<InputHTMLAttributes<HTMLInputElement>> = ({ checked, ...props }) => {
  const classesLabel = classNames(styles.root, styles.rootInitial, styles.checkbox, { [styles.rootChecked]: checked });

  const classesActiveAction = classNames(styles.active, styles.activeInitial, {
    [styles.activeChecked]: checked,
  });

  return (
    <label htmlFor={props.name} className={classesLabel}>
      {checked && <span className={classesActiveAction} />}
      <input
        id={props.name}
        type="radio"
        className={classNames(styles.hidden, styles.checkbox)}
        defaultChecked={checked}
        {...props}
      />
    </label>
  );
};
