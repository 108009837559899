import 'src/shared/styles/fonts.scss';
import 'src/shared/styles/colors.scss';
import 'src/shared/styles/common.scss';
import 'video.js/dist/video-js.css';

import { persistor, store } from '@shared/store';

import { DayjsProvider } from './dayjs';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from './router';

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <DayjsProvider>
        <Router />
      </DayjsProvider>
    </PersistGate>
  </Provider>
);

export default App;
