import * as React from 'react';
function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#Download_svg__clip0_2738_6585)"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M5.333 11.334L8 14l2.667-2.666M8 8v6" />
        <path d="M13.92 12.06A3.333 3.333 0 0012 6h-.84A5.334 5.334 0 102 10.86" />
      </g>
      <defs>
        <clipPath id="Download_svg__clip0_2738_6585">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgDownload;
