import { schemaResolverUpdateVideoWall } from '../constants';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useVideoWallsControllerUpdateByIdMutation } from '@shared/services/apiService/apiService_videowall';
import { yupResolver } from '@hookform/resolvers/yup';

export type UpdateVideoWallFormValue = {
  name: string;
  description: string;
};

export const useUpdateVideoWall = () => {
  const { videoWallId } = useParams();

  const [updateVideoWall, { isSuccess, isLoading }] = useVideoWallsControllerUpdateByIdMutation();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<UpdateVideoWallFormValue>({
    resolver: yupResolver(schemaResolverUpdateVideoWall),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data: UpdateVideoWallFormValue) => {
    if (!videoWallId) {
      return;
    }

    await updateVideoWall({
      id: videoWallId,
      updateVideoWallRequestDto: {
        name: data.name,
        description: data.description,
      },
    });
  });

  return {
    setValue,
    control,
    onSubmit,
    reset,
    errors,
    isValid,
    isLoading,
    isSuccess,
  };
};
