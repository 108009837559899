import { Position, RefPopoverAnchorElement } from '../Popover';

import { ReactNode } from 'react';

export enum ContextMenuVariantEnum {
  DEFAULT = 'default',
  ACTION = 'action',
  SWITCH = 'switch',
  SELECT = 'select',
}

export type ContextMenuItemsProps = {
  icon: ReactNode;
  title: string;
  onClick: () => void;
  variant?: ContextMenuVariantEnum;
  value?: boolean;
};

type ContextMenuBackgroundVariant = 'primary' | 'secondary';

export type ContextMenuProps = {
  className?: string;
  anchorElement: RefPopoverAnchorElement;
  isVisible: boolean;
  menu: ContextMenuItemsProps[];
  variant?: ContextMenuBackgroundVariant;
  position?: Position;
};
