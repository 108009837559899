import { DEFAULT_INITIAL_SEARCH_PAGE, DEFAULT_LIMIT_ITEMS_ON_PAGE } from '@shared/constants/pagination';
import {
  SecurityUserOfficeResponseDto,
  useLazySecurityUsersOfficesV2ControllerSearchVisitorsQuery,
} from '@shared/services/apiService/apiService_base';
import { useEffect, useState } from 'react';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useLocalStorage } from '@shared/hooks';

type SearchVisitorsProps = {
  search: string;
};

export const MIN_LENGHT_SEARCH_QUERY_VISITORS = 3;

export const useSearchVisitors = ({ search }: SearchVisitorsProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const [page, setPage] = useState(DEFAULT_INITIAL_SEARCH_PAGE);

  const [triggerSearchVisitors, dataSearchVisitors] = useLazySecurityUsersOfficesV2ControllerSearchVisitorsQuery();

  useEffect(() => {
    officeId &&
      search.length >= MIN_LENGHT_SEARCH_QUERY_VISITORS &&
      triggerSearchVisitors({
        officeId: officeId,
        limit: DEFAULT_LIMIT_ITEMS_ON_PAGE,
        page: page,
        searchQuery: search,
      });
  }, [page, search]);

  const searchVisitors = dataSearchVisitors.data?.data || [];
  const pageCount = dataSearchVisitors.data?.meta.pageCount;
  const isEmpty = search.length === 0 || (searchVisitors?.length === 0 && !dataSearchVisitors.isLoading);
  const total = dataSearchVisitors.data?.meta.total;

  return { searchVisitors, total, pageCount, isEmpty, page, setPage };
};
