import { EntryTypeEnum, PassageTypeEnum } from '@shared/store/types';
import { JORNAL_TABS, JOURNAL_PAGE_HEADING } from '@pages/Journal/constants';
import { LayoutContent, LayoutHeader } from '@shared/widgets';
import { formatTimezone, getFullName } from '@shared/utils';
import { useGetAttendanceLog, useSearchParamsJournal, useSearchVisitors } from '@pages/Journal/hooks';
import { useTitle, useToggle } from '@shared/hooks';

import { MainLayout } from '@shared/layouts';
import { SearchForm } from '@features/SearchForm';
import { SearchResults } from '@features/SearchResults';
import { Spinner } from '@shared/ui';
import { Table } from '@shared/ui';
import { Titles } from '@shared/constants/titles';
import { VisitorAttendanceLogsModal } from '@features/VisitorAttendanceLogsModal/ui';
import { formatPhoneString } from '@shared/utils';
import { useState } from 'react';

export const Journal = () => {
  useTitle(Titles.Journal);

  const [activeVisitorId, setVisitorId] = useState<string | undefined>();

  const { state: isVisitorLogModalVisible, toggle: toggleVisitorLogModal } = useToggle();

  const { page, search, handleSearch, setPage } = useSearchParamsJournal();

  const { attendanceLogs, pageCount, isLoading, isEmpty, hasPagination } = useGetAttendanceLog({
    page,
    search,
  });

  const {
    searchVisitors,
    total: totalSearchVisitors,
    pageCount: pageCountSearchVisitors,
    isEmpty: isEmptySearchVisitors,
    page: pageSearchVisitors,
    setPage: setPageSearchVisitors,
  } = useSearchVisitors({
    search: search,
  });

  const handleVisitorClick = (visitorId: string | undefined) => {
    if (!visitorId) {
      return;
    }
    setVisitorId(visitorId);
    toggleVisitorLogModal();
  };

  const closeVisitorLogsModal = () => {
    setVisitorId(undefined);
    toggleVisitorLogModal();
  };

  const hasEmptySearchAttendanceLogs = isEmpty || !attendanceLogs;
  if (isLoading) {
    return (
      <MainLayout>
        <LayoutHeader title={JOURNAL_PAGE_HEADING} tabs={[]} />
        <LayoutContent alignContentVariant="center">
          <Spinner />
        </LayoutContent>
      </MainLayout>
    );
  }

  if (isEmpty && search.length === 0 && !!attendanceLogs) {
    return (
      <MainLayout>
        <LayoutHeader title={JOURNAL_PAGE_HEADING} tabs={[]} />
        <LayoutContent alignContentVariant="center" emptyHeading="Посещений офиса не было" />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {/*[PNINE-1167] Searchform temporarily hidden */}
      <LayoutHeader
        title={JOURNAL_PAGE_HEADING}
        tabs={[]}
        // rightAddons={
        //   <SearchForm
        //     defaultSearchValue={search}
        //     onSearch={handleSearch}
        //     childrenResults={
        //       <SearchResults
        //         search={search}
        //         data={searchVisitors}
        //         pageCount={pageCountSearchVisitors || 0}
        //         total={totalSearchVisitors || 0}
        //         isEmpty={isEmptySearchVisitors}
        //         currentPage={pageSearchVisitors}
        //         onPageChange={setPageSearchVisitors}
        //         onVisitorClick={handleVisitorClick}
        //       />
        //     }
        //   />
        // }
      />
      {hasEmptySearchAttendanceLogs ? (
        <LayoutContent alignContentVariant="center" emptyHeading="Подозрительных проходов не было" />
      ) : (
        <LayoutContent>
          <Table
            hasPaginator={hasPagination}
            paginator={{ initialPage: page, pageCount: pageCount, onPageChange: setPage }}>
            <Table.THead>
              <Table.THeadCell width={150}>Время прохода (GMT+3)</Table.THeadCell>
              <Table.THeadCell>Тип прохода</Table.THeadCell>
              <Table.THeadCell width={150}>ФИО</Table.THeadCell>
              <Table.THeadCell>Телефон</Table.THeadCell>
              <Table.THeadCell>Организация</Table.THeadCell>
              <Table.THeadCell>Турникет</Table.THeadCell>
              <Table.THeadCell>Статус прохода</Table.THeadCell>
            </Table.THead>
            <Table.TBody>
              {attendanceLogs.map(visitor => {
                return (
                  <Table.TRow
                    key={visitor.attendanceLog?.id}
                    // onClick={() => handleVisitorClick(visitor.attendanceLog?.visitorId)}
                    hasAction={true}>
                    <Table.TCellFullDate date={formatTimezone(visitor.attendanceLog?.createdAt)} />
                    <Table.TCellEntryType entryType={visitor.accessPointInfo?.type as EntryTypeEnum.ENTRANCE} />
                    <Table.TCell>
                      {getFullName({
                        firstName: visitor.visitorInfo?.firstName,
                        lastName: visitor.visitorInfo?.lastName,
                        middleName: visitor.visitorInfo?.middleName,
                      })}
                    </Table.TCell>
                    <Table.TCell>{formatPhoneString(visitor.visitorInfo?.phone)} </Table.TCell>
                    <Table.TCellTooltip maxStringChildrenLenght={40}>МТС</Table.TCellTooltip>
                    <Table.TCell>{visitor.accessPointInfo?.name || '—'}</Table.TCell>
                    <Table.TCellPassageType
                      passageType={
                        visitor.attendanceLog?.suspiciousActivity === null
                          ? PassageTypeEnum.DEFAULT
                          : PassageTypeEnum.SUSPICIOUS
                      }
                    />
                  </Table.TRow>
                );
              })}
            </Table.TBody>
          </Table>
        </LayoutContent>
      )}
      {activeVisitorId && (
        <VisitorAttendanceLogsModal
          visitorId={activeVisitorId}
          isVisible={isVisitorLogModalVisible}
          closeCallback={closeVisitorLogsModal}
        />
      )}
    </MainLayout>
  );
};
