import * as React from 'react';
function SvgCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.248.752a1.325 1.325 0 010 1.873L6.873 5l2.375 2.376a1.325 1.325 0 11-1.873 1.873L4.999 6.874 2.625 9.249A1.325 1.325 0 01.75 7.375L3.126 5 .751 2.626A1.325 1.325 0 012.625.752l2.374 2.375L7.375.752a1.325 1.325 0 011.873 0z"
        fill="#626C77"
      />
    </svg>
  );
}
export default SvgCloseIcon;
