import { schemaResolverVerifyAndCommentNotificationForm } from '../constants';
import { useForm } from 'react-hook-form';
import { useSecurityUserControllerVerifyNotificationMutation } from '@shared/services/apiService/apiService_log';
import { yupResolver } from '@hookform/resolvers/yup';

type VerifyNotificationFormValues = {
  comment: string;
};

type VerifyNotificationProps = {
  notificationId: string;
  isViolated: boolean;
};

export const useVerifyNotification = ({ notificationId, isViolated }: VerifyNotificationProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<VerifyNotificationFormValues>({
    resolver: yupResolver(schemaResolverVerifyAndCommentNotificationForm),
    mode: 'onTouched',
    defaultValues: {
      comment: '',
    },
  });

  const [verify, { isLoading, isSuccess }] = useSecurityUserControllerVerifyNotificationMutation();

  const onSubmit = async (data: VerifyNotificationFormValues) => {
    await verify({
      attendanceLogId: notificationId,
      notificationUpdateRequestDto: {
        isViolated: isViolated,
        comment: data.comment,
      },
    });
  };

  return { onSubmit, reset, setValue, handleSubmit, isSuccess, isLoading, isDirty, control, errors };
};
