import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTarget15 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.125}
      d="M8 14.25a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5ZM14.25 8h-2.5M4.25 8h-2.5M8 4.25v-2.5M8 14.25v-2.5"
    />
  </svg>
);
export default SvgTarget15;
