import * as React from 'react';
function SvgRotateLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.333 3.333v4h4" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.923 10.216a5.672 5.672 0 002.218 2.834 5.726 5.726 0 006.84-.391 5.66 5.66 0 001.877-3.068 5.635 5.635 0 00-.356-3.574 5.68 5.68 0 00-2.445-2.643 5.73 5.73 0 00-6.785.949L1.333 7.068"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgRotateLeft;
