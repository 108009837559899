import * as React from 'react';
function SvgRotateRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.667 3.333v4h-4" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.077 10.22a5.672 5.672 0 01-2.221 2.833 5.728 5.728 0 01-6.842-.398 5.661 5.661 0 01-1.873-3.07 5.635 5.635 0 01.36-3.573A5.68 5.68 0 014.947 3.37a5.73 5.73 0 016.786.956l2.933 2.746"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgRotateRight;
