import { ReactElement, ReactNode } from 'react';

export const useGetAllChildren = (children: ReactNode | undefined) => {
  const allChildren: ReactElement[] = [];

  const getChildren = (child: ReactNode) => {
    if (Array.isArray(child)) {
      child.forEach(getChildren);
    } else {
      const asElement = child as ReactElement;
      if (asElement) {
        if (asElement.props && asElement.props.children) {
          getChildren(asElement.props.children);
        } else {
          allChildren.push(asElement);
        }
      }
    }
  };

  getChildren(children);

  return allChildren;
};
