import { FC } from 'react';
import { RadioButton } from '@shared/ui';
import classNames from 'classnames';
import plural from 'plural-ru';
import styles from './radioButtonArea.module.scss';

type RadioButtonAreaProps = {
  checked: boolean;
  areaName: string;
  visitorsCount: number;
  withBorder?: boolean;
  onClick: () => void;
};

export const RadioButtonArea: FC<RadioButtonAreaProps> = ({
  checked,
  areaName,
  visitorsCount,
  onClick,
  withBorder = false,
}) => {
  const classesContent = classNames(styles.content, { [styles.contentWithBorder]: withBorder });

  const visitorsPlural = plural(visitorsCount, 'посетитель', 'посетителя', 'посетителей');

  const hasVisitorsCount = visitorsCount !== 0;
  const visitorsText = hasVisitorsCount ? `${visitorsCount} ${visitorsPlural}` : 'Нет посетителей';

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.radioButton}>
        <RadioButton name={areaName} checked={checked} />
      </div>
      <div className={classesContent}>
        <p className={styles.area}>{areaName}</p>
        <p className={styles.visitors}>{visitorsText}</p>
      </div>
    </div>
  );
};
