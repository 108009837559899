import { FC, TdHTMLAttributes, useContext } from 'react';

import { EntryTypeEnum } from '@shared/store/types';
import { TableContext } from '../table-context';
import classnames from 'classnames';
import { entryTypeMapping } from '@pages/Journal/constants';
import styles from './index.module.scss';

export type TCellEntryTypeProps = TdHTMLAttributes<HTMLTableCellElement> & {
  entryType: EntryTypeEnum;
  className?: string;
  index?: number;
};

export const TCellEntryType: FC<TCellEntryTypeProps> = ({ className, entryType, index }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const classesCell = classnames(styles.component, className);
  const classesTriangle = classnames(styles.triangle, styles[entryType]);

  return (
    <td className={classesCell} style={{ width }}>
      <div className={styles.inner}>
        <span className={classesTriangle} />
        <p className={styles.entryType}>{entryTypeMapping[entryType]}</p>
      </div>
    </td>
  );
};
