import { FC } from 'react';
import { RadioButton } from '@shared/ui';
import classNames from 'classnames';
import styles from './radioButtonOffice.module.scss';

type RadioButtonOfficeProps = {
  checked: boolean;
  officeName: string;
  address: string;
  withBorder?: boolean;
  onClick: () => void;
};

export const RadioButtonOffice: FC<RadioButtonOfficeProps> = ({
  checked,
  officeName,
  address,
  onClick,
  withBorder = false,
}) => {
  const classesContent = classNames(styles.content, { [styles.contentWithBorder]: withBorder });

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.radioButton}>
        <RadioButton name={officeName} checked={checked} />
      </div>
      <div className={classesContent}>
        <p className={styles.office}>{officeName}</p>
        <p className={styles.address}>{address}</p>
      </div>
    </div>
  );
};
