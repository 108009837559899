import { ArrowLeft } from '@shared/assets/icons/components';
import { FC } from 'react';
import classNames from 'classnames';
import styles from './sidebarHeader.module.scss';
import { useNavigate } from 'react-router-dom';

type SidebarHeaderProps = {
  title: string;
  subtitle: string | null;
  hasBackOption: boolean;
  hasActionNotAllowed?: boolean;
  action?: () => void;
};

export const SidebarHeader: FC<SidebarHeaderProps> = ({
  title,
  subtitle,
  hasBackOption,
  hasActionNotAllowed = false,
  action,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleSidebarHeaderClick = () => {
    action && !hasActionNotAllowed && action();
  };

  const classesRoot = classNames(styles.root, {
    [styles.rootHover]: action && !hasActionNotAllowed,
    [styles.rootActionNotAllowed]: action && hasActionNotAllowed,
    [styles.rootWithNavigation]: hasBackOption,
  });

  return (
    <div className={classesRoot} onClick={handleSidebarHeaderClick}>
      {hasBackOption && (
        <div className={styles.navigationContainer} onClick={handleNavigate}>
          <div className={styles.navigationInner}>
            <ArrowLeft className={styles.navigationIcon} />
            <span className={styles.navigationLink}>Вернуться назад</span>
          </div>
        </div>
      )}
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{!!subtitle && subtitle}</p>
    </div>
  );
};
