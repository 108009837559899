import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationDone20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <g clipPath="url(#NotificationDone20_svg__a)">
      <path
        fill="#54AB57"
        fillOpacity={0.25}
        fillRule="evenodd"
        d="M.772 7.693c.216-2.906.324-4.36 1.783-5.819C4.014.416 5.467.307 8.375.091a31.23 31.23 0 0 1 2.307-.09c.777 0 1.555.034 2.308.09 2.907.216 4.36.325 5.82 1.783 1.458 1.46 1.566 2.913 1.783 5.82.056.753.09 1.53.09 2.307 0 .778-.034 1.555-.09 2.309-.217 2.906-.325 4.36-1.784 5.818-1.459 1.46-2.912 1.567-5.819 1.784-.753.056-1.53.09-2.308.09-.777 0-1.554-.034-2.308-.09-2.907-.217-4.36-.325-5.819-1.784C1.096 16.67.988 15.216.772 12.31c-.056-.753-.09-1.53-.09-2.308 0-.777.034-1.554.09-2.308Z"
        clipRule="evenodd"
      />
      <path
        stroke="#63C966"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m6.517 9.853 2.208 2.027a1 1 0 0 0 1.353 0L14.85 7.5"
      />
    </g>
    <defs>
      <clipPath id="NotificationDone20_svg__a">
        <path fill="#fff" d="M.684 0h20v20h-20z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotificationDone20;
