import { createSlice } from '@reduxjs/toolkit';

export type AuthStateType = {
  accessToken: string | null;
};

const initialState: AuthStateType = {
  accessToken: null,
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    signIn: (state, action) => {
      state.accessToken = action.payload;
    },
    signOut: () => initialState,
  },
});

export const actions = {
  ...authSlice.actions,
};

export const { reducer } = authSlice;
