import {
  useLazyCamerasControllerFindByIdQuery,
  useLazyVideoWallsControllerFindByIdQuery,
  useLazyVideoWallsControllerGetCamerasListQuery,
} from '@shared/services/apiService/apiService_videowall';

import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useEffect } from 'react';
import { useLocalStorage } from '@shared/hooks';
import { useParams } from 'react-router-dom';

export const useGetCameraById = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const { videoWallId, streamId } = useParams();

  const officeAddress = activeOffice && activeOffice.address;

  const [triggerGetCamerasById, cameraData] = useLazyCamerasControllerFindByIdQuery();
  const [triggerVideoWallDataById, videoWall] = useLazyVideoWallsControllerFindByIdQuery();
  const [triggerVideoWallCamerasList, camerasList] = useLazyVideoWallsControllerGetCamerasListQuery();

  useEffect(() => {
    if (videoWallId) {
      triggerVideoWallDataById(
        {
          id: videoWallId,
        },
        true,
      );
      triggerVideoWallCamerasList(
        {
          id: videoWallId,
        },
        true,
      );
    }
  }, [videoWallId]);

  useEffect(() => {
    if (streamId) {
      triggerGetCamerasById(
        {
          id: streamId,
        },
        true,
      );
    }
  }, [streamId]);

  const cameraInfo = cameraData.data;
  const isLoading = cameraData.isLoading;
  const videoWallData = videoWall.data;
  const camerasListData = camerasList.data;

  return {
    camerasListData,
    videoWallData,
    cameraInfo,
    isLoading,
    officeAddress,
  };
};
