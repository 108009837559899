import {
  VideoWallsListItemDto,
  useLazyVideoWallsControllerFindByIdQuery,
  useLazyVideoWallsControllerGetCamerasListQuery,
  useOfficesControllerGetOfficeVideoWallsQuery,
} from '@shared/services/apiService/apiService_videowall';
import { useEffect, useMemo, useState } from 'react';

import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { VideoWallPath } from '@shared/constants/routes';
import { useLocalStorage } from '@shared/hooks';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

export const useVideoWall = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);
  const [activeVideoWall, setActiveVideoWall] = useState<VideoWallsListItemDto>();

  const navigate = useNavigate();

  const { videoWallId } = useParams();

  const officeId = activeOffice && activeOffice.id;

  const {
    data: videoWallsData,
    isLoading: isVideoWallsDataLoading,
    refetch: refetchVideoWallsList,
  } = useOfficesControllerGetOfficeVideoWallsQuery(
    { officeId: officeId! },
    { refetchOnMountOrArgChange: true, skip: !officeId },
  );

  const videoWallsList = videoWallsData?.videoWalls;
  const initialVideoWall = videoWallsList && videoWallsList[0];

  useEffect(() => {
    if (initialVideoWall && !videoWallId) {
      navigate(VideoWallPath.VIDEO_WALL_BY_ID(initialVideoWall.id), { replace: true });
    }
  }, [videoWallsList, videoWallId]);

  const [triggerVideoWallDataById, videoWall] = useLazyVideoWallsControllerFindByIdQuery();
  const [triggerVideoWallCamerasList, camerasList] = useLazyVideoWallsControllerGetCamerasListQuery();

  useEffect(() => {
    if (videoWallId) {
      triggerVideoWallDataById({
        id: videoWallId,
      });
      triggerVideoWallCamerasList({
        id: videoWallId,
      });
    }
  }, [videoWallId]);

  useEffect(() => {
    if (videoWall.isError && initialVideoWall) {
      navigate(VideoWallPath.VIDEO_WALL_BY_ID(initialVideoWall.id));
    }
  }, [initialVideoWall, videoWall]);

  useEffect(() => {
    if (!videoWallData) {
      return;
    }
    const initialVideoWallByFirstItem = videoWallsList && videoWallsList[0];

    const selectedVideoWallString = localStorage.getItem('selectedVideoWall');
    const selectedVideoWall = selectedVideoWallString
      ? JSON.parse(selectedVideoWallString)
      : initialVideoWallByFirstItem;

    if (selectedVideoWall) {
      setActiveVideoWall(selectedVideoWall);
      navigate(VideoWallPath.VIDEO_WALL_BY_ID(selectedVideoWall.id));
    } else {
      setActiveVideoWall(initialVideoWallByFirstItem);
    }
  }, [videoWall]);

  const handleSelectVideoWall = (videoWall: VideoWallsListItemDto) => {
    navigate(VideoWallPath.VIDEO_WALL_BY_ID(videoWall.id));
    localStorage.setItem('selectedVideoWall', JSON.stringify(videoWall));
  };

  const videoWallData = videoWall.data;
  const camerasListData = camerasList.data;
  const isLoading = isVideoWallsDataLoading;
  const isLoadingVideoWallData = videoWall.isFetching || camerasList.isFetching || isVideoWallsDataLoading;

  return {
    videoWallsList,
    videoWallData,
    camerasListData,
    isLoading,
    isLoadingVideoWallData,
    handleSelectVideoWall,
    refetchVideoWallsList,
  };
};
