import { FC, ThHTMLAttributes } from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

export type THeadCellProps = ThHTMLAttributes<HTMLHeadingElement> & {
  className?: string;
  width?: number;
  rowVariant?: 'primary' | 'secondary';
};

export const THeadCell: FC<THeadCellProps> = ({
  children,
  className,
  style,
  width,
  rowVariant = 'primary',
  ...props
}) => {
  return (
    <th
      className={classnames(styles.component, styles.sticky, styles[rowVariant], className)}
      style={{ ...style, width }}
      {...props}>
      {children}
    </th>
  );
};
