import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <g stroke="#717680" strokeLinecap="round" strokeWidth={3}>
      <path d="M14 5v18M23 14H5" />
    </g>
  </svg>
);
export default SvgClose;
