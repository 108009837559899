import * as React from 'react';
function SvgShowPassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#show-pass-icon_svg__clip0_81_3117)" fill="#969FA8">
        <path d="M10.5 12a1.5 1.5 0 113 .001 1.5 1.5 0 01-3 0z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.485 5.471c-2.11.361-2.896.943-4.469 2.105a25.731 25.731 0 00-2.432 2.061c-1.053 1.005-1.58 1.507-1.58 2.364 0 .856.527 1.358 1.58 2.363a25.729 25.729 0 002.432 2.061c1.573 1.163 2.36 1.744 4.469 2.105.658.113 2.38.113 3.038 0 2.109-.361 2.895-.942 4.468-2.105a25.731 25.731 0 002.433-2.061c1.053-1.005 1.58-1.507 1.58-2.363 0-.857-.527-1.359-1.58-2.364a25.734 25.734 0 00-2.433-2.061c-1.573-1.162-2.36-1.744-4.468-2.105-.658-.112-2.38-.112-3.038 0zM12 8.501a3.5 3.5 0 100 7 3.5 3.5 0 000-7z"
        />
      </g>
      <defs>
        <clipPath id="show-pass-icon_svg__clip0_81_3117">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgShowPassIcon;
