type UserProps = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};

export const getFullName = (props?: UserProps) => {
  if (!props?.lastName && !props?.lastName && !props?.firstName) {
    return '—';
  }
  return `${!!props?.lastName ? props?.lastName : ''} ${!!props?.firstName ? props?.firstName : ''} ${
    !!props?.middleName ? props?.middleName : ''
  }`;
};
