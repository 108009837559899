type SuspiciousActivityProps = {
  suspicion_biometric_undefined: boolean;
  suspicion_bluetooth_undefined: boolean;
};

export const getSuspiciousActivityReason = ({
  suspicion_biometric_undefined,
  suspicion_bluetooth_undefined,
}: SuspiciousActivityProps) => {
  if (suspicion_biometric_undefined && suspicion_bluetooth_undefined) {
    return 'Не пройдена идентификация и не определен Bluetooth';
  }

  if (suspicion_biometric_undefined) {
    return 'Не пройдена идентификация';
  }

  if (suspicion_bluetooth_undefined) {
    return 'Не определен Bluetooth';
  }

  return '—';
};

export const getSuspiciousAccessPoint = ({ access_point }: any) => {
  if (access_point) {
    return access_point;
  }

  return '—';
};
