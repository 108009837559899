import * as React from 'react';
function SvgImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#717680" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M11.047 1.744h-8.14c-.642 0-1.163.52-1.163 1.163v8.14c0 .642.52 1.163 1.163 1.163h8.14c.642 0 1.162-.521 1.162-1.163v-8.14c0-.642-.52-1.163-1.162-1.163z"
          strokeWidth={1.5}
        />
        <path d="M4.942 5.814a.872.872 0 100-1.744.872.872 0 000 1.744z" fill="#717680" strokeWidth={1.163} />
        <path d="M12.21 8.721l-2.2-2.2a1 1 0 00-1.414 0L2.907 12.21" strokeWidth={1.5} />
      </g>
    </svg>
  );
}
export default SvgImage;
