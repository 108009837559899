import {
  MAP_MARKER_ACTIVE_RADIUS,
  MAP_MARKER_AVATAR_BORDER,
  MAP_MARKER_AVATAR_RADIUS,
  MAP_MARKER_DEFAULT_RADIUS,
} from '../constants';
import { ReactElement, RefObject, useCallback } from 'react';
import { getMarkerAvatar, getMarkerPrimaryColor, getMarkerSecondaryColor } from '../utils/';

import { useExtendContext } from './useExtendContext';

export const useRedraw = (
  markersRef: RefObject<ReactElement[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  mapImageRef: RefObject<HTMLImageElement>,
  updateCursor: () => void,
) => {
  const redraw = useCallback(() => {
    if (!canvasRef.current || !markersRef.current || !mapImageRef.current) {
      return;
    }

    const context = useExtendContext(canvasRef.current.getContext('2d'));

    if (!context) {
      return;
    }

    const p1 = context.transformedPoint(0, 0);
    const p2 = context.transformedPoint(canvasRef.current.width, canvasRef.current.height);

    context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    context.save();
    context.setTransform(1, 0, 0, 1, 0, 0);
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.restore();

    try {
      context.drawImage(
        mapImageRef.current,
        -mapImageRef.current.width / 2,
        -mapImageRef.current.height / 2,
        mapImageRef.current.width,
        mapImageRef.current.height,
      );
    } catch (error) {}

    const renderMarkers = (child: ReactElement) => {
      if (!mapImageRef.current) {
        return;
      }

      const { coords, role, isActive, isFocused, isAvatar } = child.props;

      const mapImageXOffset = mapImageRef.current?.width / 2;
      const mapImageYOffset = mapImageRef.current?.height / 2;

      const markerDefaultPositionX = coords.x - mapImageXOffset;
      const markerDefaultPositionY = coords.y - mapImageYOffset;

      const markerAvatarWidth = 2 * (MAP_MARKER_AVATAR_RADIUS - MAP_MARKER_AVATAR_BORDER);
      const markerAvatarOffset = (markerAvatarWidth - MAP_MARKER_AVATAR_BORDER) / 2;

      const markerAvatarPositionX = markerDefaultPositionX - markerAvatarOffset;
      const markerAvatarPositionY = markerDefaultPositionY - markerAvatarOffset;

      const markerDefaultRadius = isAvatar ? MAP_MARKER_AVATAR_RADIUS : MAP_MARKER_DEFAULT_RADIUS;
      const markerActiveRadius = !isAvatar && isActive ? MAP_MARKER_ACTIVE_RADIUS : 0;

      const markerImage = new Image();
      markerImage.src = getMarkerAvatar(role);

      context.beginPath();
      context.fillStyle = isActive || isFocused ? getMarkerPrimaryColor(role) : getMarkerSecondaryColor(role);
      context.ellipse(
        markerDefaultPositionX,
        markerDefaultPositionY,
        markerDefaultRadius,
        markerDefaultRadius,
        Math.PI,
        0,
        Math.PI * 3,
      );
      context.fill();
      context.fillStyle = getMarkerSecondaryColor(role);
      context.ellipse(
        markerDefaultPositionX,
        markerDefaultPositionY,
        markerActiveRadius,
        markerActiveRadius,
        Math.PI,
        0,
        Math.PI * 3,
      );
      context.fill();
      context.closePath();

      if (isAvatar) {
        try {
          context.drawImage(
            markerImage,
            markerAvatarPositionX,
            markerAvatarPositionY,
            markerAvatarWidth - MAP_MARKER_AVATAR_BORDER,
            markerAvatarWidth - MAP_MARKER_AVATAR_BORDER,
          );
        } catch (error) {}
      }
    };

    markersRef.current.map(renderMarkers);

    updateCursor();
  }, [updateCursor]);

  return redraw;
};
