import { ReactNode } from 'react';
import { NavLinkProps as RouterLinkProps } from 'react-router-dom';

export enum NavLinkVariant {
  TAB_NAVIGATION = 'navigation',
  ROUTER = 'router',
}

export interface NavLinkProps extends RouterLinkProps {
  isTabActive?: boolean;
  parent?: string;
  className?: string;
  children: ReactNode;
  variant?: NavLinkVariant;
  onNavigation?: () => void;
  hasCounter?: boolean;
  countValue?: number;
}
