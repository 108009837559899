import { Button, Modal } from '@shared/ui';

import { RadioButtonOffice } from '@pages/Visitors/ui';
import SvgInfo from '@shared/assets/icons/components/Info';
import SvgNotification from '@shared/assets/icons/components/Notification';
import styles from './forgotLoginOrPassword.module.scss';
import { useToggle } from '@shared/hooks';

export const ForgotLoginOrPassword = () => {
  const { state: isModalChangeOfficeOpen, toggle: toggleChangeOfficeModal } = useToggle();

  return (
    <>
      <a onClick={toggleChangeOfficeModal} className={styles.button}>
        Забыл логин или пароль
      </a>
      <Modal
        title="Выбрать офис"
        containerVariant="smallContainer"
        isVisible={isModalChangeOfficeOpen}
        width={359}
        containerStyles={styles.modalContainer}
        hasHeader={false}
        closeCallback={toggleChangeOfficeModal}>
        <div className={styles.root}>
          <div className={styles.icon}>
            <SvgInfo />
          </div>
          <p className={styles.title}>Забыли логин или пароль?</p>
          <p className={styles.description}>Обратитесь к своему руководителю или напишите нам о своей проблеме</p>

          <Button className={styles.closeButton} variant="primary" size="large" onClick={toggleChangeOfficeModal}>
            Хорошо
          </Button>

          <a href="mailto:urbanpredator@mts.ru" className={styles.writeToTheDevelopersButton}>
            Написать разработчикам
          </a>
        </div>
      </Modal>
    </>
  );
};
