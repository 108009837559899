import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useLocalStorage } from '@shared/hooks';
import { useOfficesControllerGetTargetCameraQuery } from '@shared/services/apiService/apiService_videowall';

export const useGetTargetCamera = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const {
    data: targetCamera,
    isLoading: isLoadingtargetCamera,
    refetch: refetchTargetCamera,
  } = useOfficesControllerGetTargetCameraQuery(
    {
      officeId: officeId!,
    },
    { skip: !officeId },
  );

  return {
    targetCamera,
    isLoadingtargetCamera,
    refetchTargetCamera,
  };
};
