import * as yup from 'yup';

export const schemaResolverSignInForm = yup.object({
  login: yup.string().required('Логин является обязательным'),
  password: yup
    .string()
    .required('Пароль является обязательным')
    .test(
      'Пароль должен содержать только латинские буквы',
      'Пароль должен содержать только латинские буквы',
      value => !/[А-я]+/.test(value),
    )
    .min(8, 'Пароль должен состоять из 8 символов')
    .matches(/\d+/, 'Пароль должен содержать хотя бы 1 цифру')
    .matches(/[a-z]+/, 'Пароль должен содержать строчную букву')
    .matches(/[A-Z]+/, 'Пароль должен содержать заглавную букву')
    .test('Пароль не должен содержать пробелов', 'Пароль не должен содержать пробелов', value => !/\s+/.test(value)),
});
