import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  PersistConfig,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { apiService_base } from '../services/apiService/apiService_base';
import { apiService_integration } from '@shared/services/apiService/apiService_integration';
import { apiService_log } from '@shared/services/apiService/apiService_log';
import { apiService_videowall } from '@shared/services/apiService/apiService_videowall';
import { combinedReducer } from './ducks';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { unauthenticatedMiddleware } from './middlewares/authMiddlewares';

type State = ReturnType<typeof combinedReducer>;

const persistConfig: PersistConfig<State> = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/signOut') {
    state = {};
  }
  return combinedReducer(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const rootMiddleware = getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiService_base.middleware)
      .concat(apiService_videowall.middleware)
      .concat(apiService_log.middleware)
      .concat(apiService_integration.middleware)
      .concat(unauthenticatedMiddleware);

    return rootMiddleware;
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
