import * as yup from 'yup';

export const schemaResolverCreateVideoWall = yup.object({
  name: yup
    .string()
    .max(72, 'Название должно быть не более 72 символов')
    .required('Это поле обязательно к заполнению'),
  description: yup
    .string()
    .max(255, 'Описание должно быть не более 255 символов')
    .required('Это поле обязательно к заполнению'),
});
