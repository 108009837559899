import * as React from 'react';
function SvgProfile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20 21a6 6 0 00-6-6h-4a6 6 0 00-6 6" stroke="#717680" strokeWidth={2} strokeLinecap="round" />
      <path
        d="M12 11a4 4 0 100-8 4 4 0 000 8z"
        stroke="#717680"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgProfile;
