import { ConditionalWrapperProps } from './types';
import { ReactElement } from 'react';

export const ConditionalWrapper = <T extends ReactElement>({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps<T>): ReactElement => {
  return condition ? wrapper(children) : children;
};
