import React, { HTMLAttributes } from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

export type TBodyProps = HTMLAttributes<HTMLTableSectionElement> & {
  className?: string;
};

export const TBody: React.FC<TBodyProps> = ({ className, children, ...props }) => (
  <tbody className={classnames(styles.component, className)} {...props}>
    {children}
  </tbody>
);
