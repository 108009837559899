import { FC, TdHTMLAttributes, useContext } from 'react';

import { TableContext } from '../table-context';
import { VisitorRoleEnum } from '@pages/Visitors/types';
import { VisitorRoleKind } from '@shared/services/apiService/apiService_base';
import classnames from 'classnames';
import styles from './index.module.scss';
import { visitorRoleMapping } from '@pages/Visitors/constants';

export type TCellRoleProps = TdHTMLAttributes<HTMLTableCellElement> & {
  role?: VisitorRoleKind;
  className?: string;
  index?: number;
};

export const TCellRole: FC<TCellRoleProps> = ({ className, role, index }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;
  const visitorRole = !role ? VisitorRoleEnum.UNIDENTIFIED : role;

  const classesCell = classnames(styles.component, className);
  const classesMarker = classnames(styles.marker, styles[visitorRole]);

  return (
    <td className={classesCell} style={{ width }}>
      <div className={styles.inner}>
        <span className={classesMarker} />
        <p className={styles.role}>{visitorRoleMapping[visitorRole]}</p>
      </div>
    </td>
  );
};
