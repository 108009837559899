import { FC, ReactNode } from 'react';

import { AppHeader } from '@shared/widgets';
import { AppHeaderProps } from '@shared/widgets/AppHeader/AppHeader';
import { useClientSize } from '@shared/hooks';
import { MobileScreen } from '@shared/ui/MobileScreen/MobileScreen';
import classNames from 'classnames';
import styles from './mainLayout.module.scss';

type MainLayoutProps = {
  children: ReactNode;
  variantDashboard?: 'primaryDashboard' | 'secondaryDashboard';
  appHeaderProps?: AppHeaderProps;
};

export const MainLayout: FC<MainLayoutProps> = ({
  children,
  variantDashboard = 'primaryDashboard',
  appHeaderProps,
}) => {
  const classesDashdoard = classNames(styles.dashboard, styles[variantDashboard]);

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('mobile');

  return (
    <div className={styles.app}>
      {isMobile ? (
        <div className={styles.wrapper}>
          <AppHeader {...appHeaderProps} />
          <div className={styles.root}>
            <div className={classesDashdoard}>{children}</div>
          </div>
        </div>
      ) : (
        <MobileScreen/>
      ) }
    </div>
  );
};
