import { DEFAULT_INITIAL_PAGE, DEFAULT_LIMIT_CAMERAS_ON_PAGE } from '@shared/constants/pagination';
import {
  SecurityUserOfficeResponseDto,
  useSecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasQuery,
} from '@shared/services/apiService/apiService_base';
import { useEffect, useState } from 'react';
import {
  useLazyAreasControllerGetAreaCamerasQuery,
  useLazyVideoWallsControllerGetCamerasListQuery,
} from '@shared/services/apiService/apiService_videowall';

import { OptionProps } from '@shared/ui/Select/types';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from '@shared/hooks';
import { useParams } from 'react-router-dom';

type SearchFormValue = {
  searchValue: string;
};

type UseGetCamerasProps = {
  isModalVisible: boolean;
};

export const MIN_LENGHT_SEARCH_QUERY_CAMERAS = 3;

export const useGetCameras = ({ isModalVisible }: UseGetCamerasProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);
  const [activeArea, setActiveArea] = useLocalStorage<OptionProps<string>>(StorageKeys.ACTIVE_AREA, {
    label: '',
    value: '',
  });

  const formMethods = useForm<SearchFormValue>({
    mode: 'onChange',
    defaultValues: {
      searchValue: '',
    },
  });

  const { videoWallId } = useParams();

  const [page, setPage] = useState<number>(DEFAULT_INITIAL_PAGE);

  const officeId = activeOffice && activeOffice?.id;

  const { data: getAreasData } = useSecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasQuery(
    { officeId: officeId! },
    { skip: !isModalVisible || !officeId },
  );

  const areas = getAreasData?.areas || [];
  const areasOptions: OptionProps<string>[] = areas
    .map(area => ({ label: area.name, value: area.id }))
    .sort((a, b) => {
      let numA = parseInt(a.label.split(' ')[0]);
      let numB = parseInt(b.label.split(' ')[0]);
      return numA - numB;
    });

  const initialArea = areasOptions[0];

  useEffect(() => {
    if (isModalVisible) {
      setPage(DEFAULT_INITIAL_PAGE);
      initialArea && setActiveArea(activeArea);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (activeArea.value === '') {
      initialArea && setActiveArea(initialArea);
    }
  }, [areas]);

  const [triggerGetAreaCameras, { data: camerasData, isFetching: isCamerasLoading }] =
    useLazyAreasControllerGetAreaCamerasQuery();

  const [triggerVideoWallCamerasList, camerasList] = useLazyVideoWallsControllerGetCamerasListQuery();

  const camerasIds = camerasList.data?.map(camera => camera.id);

  useEffect(() => {
    if (videoWallId) {
      triggerVideoWallCamerasList(
        {
          id: videoWallId,
        },
        true,
      );
    }
  }, [videoWallId]);

  useEffect(() => {
    setPage(DEFAULT_INITIAL_PAGE);
    formMethods.setValue('searchValue', '');
  }, [activeArea]);

  useEffect(() => {
    if (!activeArea.value) {
      return;
    }

    triggerGetAreaCameras(
      {
        areaId: activeArea.value,
        limit: DEFAULT_LIMIT_CAMERAS_ON_PAGE,
        excludeCameraIds: camerasIds,
        page: page,
      },
      true,
    );
  }, [activeArea, page]);

  const handleSearchCameras = async (searchValue: string) => {
    if (!activeArea.value) {
      return;
    }

    if (searchValue.length < MIN_LENGHT_SEARCH_QUERY_CAMERAS) {
      await triggerGetAreaCameras(
        {
          areaId: activeArea.value,
          limit: DEFAULT_LIMIT_CAMERAS_ON_PAGE,
          excludeCameraIds: camerasIds,
          page: page,
        },
        true,
      );
    } else {
      await triggerGetAreaCameras(
        {
          areaId: activeArea.value,
          limit: DEFAULT_LIMIT_CAMERAS_ON_PAGE,
          excludeCameraIds: camerasIds,
          page: page,
          searchQuery: searchValue,
        },
        true,
      );
    }
  };

  const cameras = camerasData?.data || undefined;
  const isCamerasEmpty = cameras?.length === 0;
  const isLoading = isCamerasLoading;
  const pageCount = camerasData?.meta.pageCount;

  return {
    activeArea,
    isLoading,
    areasOptions,
    cameras,
    isCamerasEmpty,
    page,
    pageCount,
    formMethods,
    setPage,
    setActiveArea,
    handleSearchCameras,
  };
};
