import { FC } from 'react';
import { Tooltip as TooltipComponent } from 'react-tooltip';
import { TooltipProps } from './types';
import classNames from 'classnames';
import styles from './tooltip.module.scss';

export const Tooltip: FC<TooltipProps> = ({ id, children, content, className }) => {
  const classesTooltip = classNames(styles.root, className);

  return (
    <>
      <div data-tooltip-id={`tooltip-${id}`} className={styles.item}>
        {children}
      </div>
      <TooltipComponent
        id={`tooltip-${id}`}
        className={classesTooltip}
        classNameArrow={styles.arrow}
        style={{ borderRadius: 0 }}>
        <span className={styles.content}>{content}</span>
      </TooltipComponent>
    </>
  );
};
