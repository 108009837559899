import { ActiveStreamProps, StreamVariantUrlEnum } from '@shared/ui/StreamHeader/types';
import { ContextMenuItemsProps, ContextMenuVariantEnum } from '@shared/ui/ContextMenu/types';
import { FC, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CameraResponseDto } from '@shared/services/apiService/apiService_videowall';
import { CanvasStream } from '@shared/lib/react-canvas/ui/CanvasStream';
import Player from 'video.js/dist/types/player';
import { StreamHeader } from '@shared/ui/StreamHeader';
import TextTrack from 'video.js/dist/types/tracks/text-track';
import { Trash } from '@shared/assets/icons/components';
import UnrecognizedVisitors from '@entities/UnrecognizedVisitors';
import VideoJSPlayer from '@features/VideoJSPlayer';
import { VideoWallPath } from '@shared/constants/routes';
import VisitorCard from '@entities/VisitorCard';
import { VisitorRoleKind } from '@shared/services/apiService/apiService_base';
import { WebRtcPlayer } from '@entities/WebRtcPlayer/WebRtcPlayer';
import styles from './predatorStream.module.scss';
import { usePredatorStreamCoordinatesStore } from '../hooks/usePredatorStreamCoordinatesStore';
import { useUpdateVideoWallStreams } from '@features/UpdateVideoWallStreams/hooks';

type PredatorStreamProps = {
  stream: CameraResponseDto;
  hasStockVideoWall: boolean;
};

export const PredatorStream: FC<PredatorStreamProps> = ({ stream, hasStockVideoWall }) => {
  const navigate = useNavigate();

  const { streamId, videoWallId } = useParams();

  const { defaultStreamUrl } = stream;

  const isWebRtcStream = defaultStreamUrl.startsWith('wss://');
  const videoPlayerRef = useRef(null);

  const [activeStream, setStream] = useState<ActiveStreamProps>({
    streamVariant: StreamVariantUrlEnum.DEFAULT,
    streamUrl: defaultStreamUrl,
  });

  const [isVisibleUnrecognizedUsers, setIsVisibleUnrecognizedUsers] = useState(false);

  const playerWrapperRef = useRef<HTMLDivElement | null>(null);

  const { refreshCurrentCoordinatesData, checkedPersonId, drawCoordinates, getIdByCoordinates, clearCheckedPerson } =
    usePredatorStreamCoordinatesStore(playerWrapperRef.current);

  const playerOptions = useMemo(
    () => ({
      debug: true,
      autoplay: 'muted',
      bigPlayButton: false,
      controls: false,
      muted: true,
      preload: 'auto',
      fluid: false,
      useCueTags: true,
      aspectRatio: '16:9',
      sources: [
        {
          src: activeStream.streamUrl,
          type: 'application/x-mpegurl',
        },
      ],
    }),
    [activeStream],
  );

  const { handleDeleteStream } = useUpdateVideoWallStreams();

  const handleDelete = async () => {
    if (streamId) {
      await handleDeleteStream(streamId);
      navigate(VideoWallPath.VIDEO_WALL_BY_ID(videoWallId));
    }
  };

  const contextMenu: ContextMenuItemsProps[] = [
    {
      icon: <Trash />,
      title: 'Удалить камеру',
      onClick: handleDelete,
      variant: ContextMenuVariantEnum.DEFAULT,
    },
  ];

  const contextMenuStockVideoWall: ContextMenuItemsProps[] = [];

  const handleCloseUnidentifiedCard = useCallback(() => {
    setIsVisibleUnrecognizedUsers(false);
  }, [setIsVisibleUnrecognizedUsers]);

  const handlePlayerReady = useCallback(
    (player: Player) => {
      player.on('timeupdate', (event: SyntheticEvent) => {
        const textTrack: TextTrack = (player as any).textTracks().getTrackById('subtitle_0');
        const activeCues = textTrack.activeCues_;
        const currentTime = Number(player.currentTime());

        refreshCurrentCoordinatesData(activeCues, currentTime);
      });

      player.one('loadeddata', () => {
        try {
          hideTextTracks(player);
        } catch (err) {
          console.error('Error ', (err as Error).message);
        }
      });

      function hideTextTracks(player: any) {
        const tracks = player.textTracks();

        if (tracks.length) {
          for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            track.mode = 'hidden';
          }
        }
      }
    },
    [refreshCurrentCoordinatesData],
  );

  const visitorInfo = useMemo(
    () => ({
      id: String(checkedPersonId),
      phone: '88005553535',
      firstName: String(checkedPersonId),
      middleName: String(checkedPersonId),
      lastName: String(checkedPersonId),
      role: 'guest' as VisitorRoleKind,
      companyName: 'NaN',
    }),
    [checkedPersonId],
  );

  return (
    <div className={styles.root} ref={playerWrapperRef}>
      <StreamHeader
        stream={stream}
        contextMenuItems={hasStockVideoWall ? contextMenuStockVideoWall : contextMenu}
        activeStream={activeStream}
        setStream={setStream}
      />
      {isWebRtcStream ? (
        <WebRtcPlayer activeStream={activeStream} playerRef={videoPlayerRef} index={0} />
      ) : (
        <VideoJSPlayer options={playerOptions} onReady={handlePlayerReady} />
      )}

      <CanvasStream onChooseRect={getIdByCoordinates} drawCoordinates={drawCoordinates} />
      {checkedPersonId && (
        <VisitorCard
          className={styles.visitor}
          cardClassName={styles.visitorInner}
          closeCallback={clearCheckedPerson}
          visitorInfo={visitorInfo}
        />
      )}
      {isVisibleUnrecognizedUsers && (
        <UnrecognizedVisitors
          className={styles.unrecognizedVisitors}
          dateTime={'10.10.2023'}
          unrecognizedVisitorsCount={3}
          description="Вам следует уточнить имеют ли они право находиться в данном помещении"
          onClose={handleCloseUnidentifiedCard}
        />
      )}
    </div>
  );
};
