import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAttention36 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7900_67885)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.158696 13.8476C0.548102 8.61542 0.742805 5.99933 3.36866 3.37348C5.99451 0.747622 8.61061 0.55292 13.8428 0.163516C15.1991 0.0625724 16.5982 0.00140381 17.9973 0.00140381C19.3964 0.00140381 20.7955 0.0625735 22.1518 0.163518C27.384 0.552924 30.0001 0.747627 32.626 3.37348C35.2518 5.99933 35.4465 8.61543 35.8359 13.8476C35.9369 15.2039 35.998 16.603 35.998 18.0021C35.998 19.4012 35.9369 20.8004 35.8359 22.1567C35.4465 27.3888 35.2518 30.0049 32.626 32.6308C30.0001 35.2566 27.384 35.4514 22.1518 35.8408C20.7955 35.9417 19.3964 36.0029 17.9973 36.0029C16.5982 36.0029 15.1991 35.9417 13.8428 35.8408C8.6106 35.4513 5.99451 35.2566 3.36865 32.6308C0.742801 30.0049 0.548098 27.3888 0.158694 22.1567C0.0577507 20.8003 -0.00341797 19.4012 -0.00341797 18.0021C-0.00341797 16.603 0.0577518 15.2039 0.158696 13.8476Z"
        fill="#FAC031"
        fill-opacity="0.25"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0021 20.4127C18.9971 20.4127 19.8037 19.5607 19.8037 18.5098L19.8037 10.9029C19.8037 9.85195 18.997 9 18.002 9C17.007 9 16.2004 9.85196 16.2004 10.9029L16.2004 18.5098C16.2004 19.5608 17.007 20.4127 18.0021 20.4127ZM20.25 26.1235C20.25 24.8111 19.2426 23.7471 18 23.7471C16.7574 23.7471 15.75 24.8111 15.75 26.1235C15.75 27.436 16.7574 28.5 18 28.5C19.2426 28.5 20.25 27.436 20.25 26.1235Z"
        fill="#FAC031"
      />
    </g>
    <defs>
      <clipPath id="clip0_7900_67885">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAttention36;
