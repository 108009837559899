import { FC, useMemo } from 'react';
import { SortAsc, SortDesc, SortUnset } from '@shared/assets/icons/components';
import { THeadCell, THeadCellProps } from '../thead-cell';

import classnames from 'classnames';
import styles from './index.module.scss';

export type TSortableHeadCellProps = THeadCellProps & {
  isSortedDesc?: boolean;
  defaultIsSortedDesc?: boolean;
  onSort?: () => void;
};

export const TSortableHeadCell: FC<TSortableHeadCellProps> = ({
  children,
  className,
  defaultIsSortedDesc,
  isSortedDesc,
  onSort,
  ...props
}) => {
  const SortIcon = useMemo(() => {
    let value = isSortedDesc;

    if (value === undefined) value = defaultIsSortedDesc;
    if (typeof value === 'boolean') return value ? SortDesc : SortAsc;

    return SortUnset;
  }, [defaultIsSortedDesc, isSortedDesc]);

  return (
    <THeadCell className={className} {...props} onClick={onSort}>
      <div className={styles.content}>
        {children}
        <SortIcon
          className={classnames(styles.icon, {
            [styles.sorted]: isSortedDesc !== undefined,
          })}
        />
      </div>
    </THeadCell>
  );
};
