import { ActiveStreamProps, StreamHeaderProps, StreamVariantUrlEnum } from './types';
import {
  ArrowRight,
  Menu,
  Menu15,
  Menu20,
  Predator16,
  Target15,
  Target16,
  Target20,
  Vision,
  Vision16,
} from '@shared/assets/icons/components';
import { ContextMenu, IconButton } from '@shared/ui';
import { ContextMenuItemsProps, ContextMenuVariantEnum } from '@shared/ui/ContextMenu/types';
import { FC, useRef } from 'react';
import { useClientSize, useOutsideClick, useToggle } from '@shared/hooks';

import styles from './streamHeader.module.scss';

export const StreamHeader: FC<StreamHeaderProps> = ({
  stream,
  contextMenuItems,
  activeStream,
  onSelectStream,
  setStream,
}) => {
  const contextMenuButtonRef = useRef(null);
  const visionMenuButtonRef = useRef(null);

  const contextMenuRef = useRef(null);
  const visionMenuRef = useRef(null);

  const { state: isContextMenuVisible, setFalse: setFalseContextMenu, toggle: toggleContextMenu } = useToggle();
  const { state: isVisionMenuVisible, setFalse: setFalseVisionMenu, toggle: toggleVisionMenu } = useToggle();

  useOutsideClick(contextMenuRef, contextMenuButtonRef, setFalseContextMenu);
  useOutsideClick(visionMenuRef, visionMenuButtonRef, setFalseVisionMenu);

  const { defaultStreamUrl, maskedStreamUrl, predatorStreamUrl } = stream;

  const handleSelectStream = ({ streamVariant, streamUrl }: ActiveStreamProps) => {
    setStream({ streamVariant, streamUrl });
    if (streamVariant !== activeStream.streamVariant) {
      setFalseVisionMenu();
    }
  };

  const visionMenuItems: ContextMenuItemsProps[] = [
    {
      icon: <Vision16 />,
      title: 'Обычный режим',
      onClick: () => handleSelectStream({ streamVariant: StreamVariantUrlEnum.DEFAULT, streamUrl: defaultStreamUrl }),
      variant: ContextMenuVariantEnum.SELECT,
      value: activeStream.streamVariant === StreamVariantUrlEnum.DEFAULT,
    },
    {
      icon: <Target16 />,
      title: 'Режим свой/чужой',
      onClick: () => handleSelectStream({ streamVariant: StreamVariantUrlEnum.MASKED, streamUrl: maskedStreamUrl }),
      variant: ContextMenuVariantEnum.SELECT,
      value: activeStream.streamVariant === StreamVariantUrlEnum.MASKED,
    },
    {
      icon: <Predator16 />,
      title: 'Режим хищника',
      onClick: () => handleSelectStream({ streamVariant: StreamVariantUrlEnum.PREDATOR, streamUrl: predatorStreamUrl }),
      variant: ContextMenuVariantEnum.SELECT,
      value: activeStream.streamVariant === StreamVariantUrlEnum.PREDATOR,
    },
  ];

  const { getIsBreakpoint } = useClientSize();
  const isDesktopFullHD = getIsBreakpoint('desktopFullHD');

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <div className={styles.leftIcons}>
          <IconButton ref={contextMenuButtonRef} onClick={toggleContextMenu}>
            {isDesktopFullHD ? <Menu20 /> : <Menu15 />}
          </IconButton>
          {contextMenuItems.length > 0 && (
            <ContextMenu
              ref={contextMenuRef}
              anchorElement={contextMenuButtonRef.current}
              menu={contextMenuItems}
              isVisible={isContextMenuVisible}
            />
          )}
          <IconButton ref={visionMenuButtonRef} onClick={toggleVisionMenu}>
            {isDesktopFullHD ? <Target20 /> : <Target15 />}
          </IconButton>
          <ContextMenu
            ref={visionMenuRef}
            anchorElement={visionMenuButtonRef.current}
            menu={visionMenuItems}
            isVisible={isVisionMenuVisible}
          />
        </div>
        {!!onSelectStream && (
          <IconButton onClick={onSelectStream}>
            <ArrowRight className={styles.arrowRight} />
          </IconButton>
        )}
      </div>
    </div>
  );
};
