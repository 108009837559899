import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { Textarea } from '.';
import { TextareaProps } from './types';

export function TextareaField<T extends FieldValues>(props: UseControllerProps<T> & TextareaProps) {
  const { name, control } = props;
  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name,
    control,
  });

  return <Textarea {...props} {...field} error={error} />;
}
