import { VisitorRoleEnum } from '@pages/Visitors/types';

export function getMarkerAvatar(role: VisitorRoleEnum) {
  const avatarMarkerMapping = {
    [VisitorRoleEnum.EMPLOYEE]: '/markers/avatars/employee.png',
    [VisitorRoleEnum.GUEST]: '/markers/avatars/guest.png',
    [VisitorRoleEnum.CONTRACTOR]: '/markers/avatars/contractor.png',
    [VisitorRoleEnum.UNIDENTIFIED]: '/markers/avatars/unidentified.png',
    [VisitorRoleEnum.SECURITY]: '/markers/avatars/security.png',
  };

  return avatarMarkerMapping[role];
}
