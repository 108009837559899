import { AuthPath } from '@shared/constants/routes';
import { ErrorI } from '@shared/store/types';
import { SignInFormValues } from '../types';
import { actions } from '@shared/store/ducks';
import { schemaResolverSignInForm } from '../ui/constants';
import { useAppDispatch } from '@shared/store';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSecurityUsersV2ControllerSecurityUserSignInMutation } from '@shared/services/apiService/apiService_base';
import { yupResolver } from '@hookform/resolvers/yup';

export const useSignIn = () => {
  const [signIn] = useSecurityUsersV2ControllerSecurityUserSignInMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, setError, setValue, ...formMethods } = useForm<SignInFormValues>({
    resolver: yupResolver(schemaResolverSignInForm),
    mode: 'onTouched',
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit(async data => {
    try {
      const response = await signIn({ securityUserSignInV2Dto: data }).unwrap();
      dispatch(actions.auth.signIn(response.accessToken));
      navigate(AuthPath.DASHBOARD);
    } catch (error: ErrorI | any) {
      setError('password', {
        type: 'server',
        message: error.data.message,
      });
    }
  });

  return {
    setValue,
    onSubmit,
    ...formMethods,
  };
};
