import { AuthorizedRoutes } from './AuthorizedRoutes';
import { BrowserRouter } from 'react-router-dom';
import { UnauthorizedRoutes } from './UnauthorizedRoutes';
import { selectors } from '@shared/store/ducks/auth';
import { useAppSelector } from '@shared/store';

export const Router = () => {
  const isAuth = useAppSelector(selectors.getAccessToken);

  return <BrowserRouter>{isAuth ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</BrowserRouter>;
};
