import { Dispatch, RefObject, SetStateAction } from 'react';

export const useUpdateContainmentScale = (
  canvasRef: RefObject<HTMLCanvasElement>,
  mapImageRef: RefObject<HTMLImageElement>,
  setContainmentScale: Dispatch<SetStateAction<number>>,
) => {
  const updateContainmentScale = () => {
    if (!canvasRef.current || !mapImageRef.current) {
      return;
    }

    if (!canvasRef.current || !mapImageRef.current) {
      return;
    }

    const context = canvasRef.current.getContext('2d');

    if (!context) {
      return;
    }

    const imgWidth = mapImageRef.current.width;
    const imgHeight = mapImageRef.current.height;

    if (imgWidth && imgHeight) {
      const widthScaledHeight = (imgHeight / imgWidth) * canvasRef.current.width;
      let updatedScale = 1;
      if (widthScaledHeight > canvasRef.current.height) {
        updatedScale = canvasRef.current.height / imgHeight;
      } else {
        updatedScale = canvasRef.current.width / imgWidth;
      }
      setContainmentScale(updatedScale);
    }
    context.translate(mapImageRef.current.width / 2, mapImageRef.current.height / 2);
  };

  return updateContainmentScale;
};
