import { FC, TdHTMLAttributes, useContext } from 'react';
import { showDate, showTime } from '@shared/utils';

import { TableContext } from '../table-context';
import classnames from 'classnames';
import styles from './index.module.scss';

export type TCellTimeProps = TdHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  index?: number;
  date?: string | null;
};

export const TCellFullDate: FC<TCellTimeProps> = ({ className, date, style, children, index, ...props }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const classesCell = classnames(styles.component, className);

  return (
    <td className={classesCell} style={{ ...style, width }} {...props}>
      <div className={styles.inner}>
        {date ? (
          <>
            {showDate(date)}
            <span className={styles.time}>{showTime(date)}</span>
          </>
        ) : (
          '—'
        )}
      </div>
    </td>
  );
};
