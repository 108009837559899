import { Marker, Marker7 } from '@shared/assets/icons/components';

import { FC } from 'react';
import { VisitorRoleEnum } from '@pages/Visitors/types';
import classNames from 'classnames';
import styles from './filterVisitorsRole.module.scss';
import { useClientSize } from '@shared/hooks';
import { visitorGroupNameMapping } from '@pages/Visitors/constants';

type FilterVisitorsRoleProps = {
  activeRole: VisitorRoleEnum | string;
  role: VisitorRoleEnum;
  visitors: number;
  onFilterClick: () => void;
};

export const FilterVisitorsRole: FC<FilterVisitorsRoleProps> = ({ activeRole, role, visitors, onFilterClick }) => {
  const isEmployee = activeRole === VisitorRoleEnum.EMPLOYEE && role === VisitorRoleEnum.EMPLOYEE;
  const isGuest = activeRole === VisitorRoleEnum.GUEST && role === VisitorRoleEnum.GUEST;
  const isContractor = activeRole === VisitorRoleEnum.CONTRACTOR && role === VisitorRoleEnum.CONTRACTOR;
  const isUnidentified = activeRole === VisitorRoleEnum.UNIDENTIFIED && role === VisitorRoleEnum.UNIDENTIFIED;
  const isSecurity = activeRole === VisitorRoleEnum.SECURITY && role === VisitorRoleEnum.SECURITY;

  const classesRoot = classNames(styles.root, {
    [styles.rootEmployee]: isEmployee,
    [styles.rootGuest]: isGuest,
    [styles.rootContractor]: isContractor,
    [styles.rootUnidentified]: isUnidentified,
    [styles.rootSecurity]: isSecurity,
  });
  const classesMarker = classNames(styles.marker, styles[role]);
  const classesGroupname = classNames(styles.groupname, { [styles.groupnameActive]: activeRole === role });

  const { getIsBreakpoint } = useClientSize();
  const isDesktopFullHD = getIsBreakpoint('desktopFullHD');

  return (
    <button className={classesRoot} onClick={onFilterClick}>
      {isDesktopFullHD ? <Marker className={classesMarker} /> : <Marker7 className={classesMarker} />}
      <p className={classesGroupname}>{visitorGroupNameMapping[role]}</p>
      <p className={styles.visitors}>{visitors}</p>
    </button>
  );
};
