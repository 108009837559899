import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPredator16 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <ellipse cx={5.594} cy={7.027} fill="#fff" rx={0.872} ry={0.745} transform="rotate(-23.049 5.594 7.027)" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M3.881 4.47C1.526 6.097 1.266 9.288 1.64 11.008c.374 1.72-1.12 0 2.616 2.065 3.736 2.064 6.821.158 8.967-.689 2.615-1.032 1.494-2.752-.374-2.064-2.416.89-10.088 0-6.725-.344 3.363-.344 2.615-1.033 5.23-2.409 2.093-1.1 3.612-.23 4.11.344.125-.688 0-2.477-1.494-4.129-1.868-2.064-1.494.688-3.363 0-1.494-.55-2.615 0-2.989.344-1.12-.688-2.241-.688-3.736.344Z"
    />
  </svg>
);
export default SvgPredator16;
