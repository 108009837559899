import { NotificationLogResponseDto } from '@shared/services/apiService/apiService_log';
import { useState } from 'react';
import { useToggle } from '@shared/hooks';

export const useSelectNotification = () => {
  const { state: isVisibleModalVerify, toggle: toggleModalVerify } = useToggle();

  const [activeNotification, setNotification] = useState<NotificationLogResponseDto>();

  const handleCommentClick = (notification?: NotificationLogResponseDto) => {
    setNotification(notification);
    toggleModalVerify();
  };

  return { activeNotification, isVisibleModalVerify, toggleModalVerify, handleCommentClick };
};
