import React, { FC, HTMLAttributes } from 'react';
import { TCell, TCellProps } from '../tcell';

import cn from 'classnames';
import styles from './index.module.scss';

type TCellElement = React.ReactElement<TCellProps, typeof TCell>;

export type TRowProps = HTMLAttributes<HTMLTableRowElement> & {
  children: TCellElement | TCellElement[];
  className?: string;
  hasAction?: boolean;
};

export const TRow: FC<TRowProps> = ({ children, className, hasAction = false, ...props }) => (
  <tr className={cn(styles.component, className, { [styles.hasAction]: hasAction })} {...props}>
    {React.Children.map(children, (child, index) => React.cloneElement(child, { index }))}
  </tr>
);
