import * as React from 'react';
function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.749 11.577a.817.817 0 010 1.179.874.874 0 01-1.212 0L5.25 8.589a.817.817 0 010-1.178l4.286-4.167a.874.874 0 011.212 0 .817.817 0 010 1.179L7.069 8l3.68 3.577z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgArrowLeft;
