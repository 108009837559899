import * as yup from 'yup';

export const schemaResolverUpdateVideoWall = yup.object({
  name: yup
    .string()
    .max(150, 'Название должно быть не более 150 символов')
    .required('Это поле обязательно к заполнению'),
  description: yup
    .string()
    .max(255, 'Описание должно быть не более 255 символов')
    .required('Это поле обязательно к заполнению'),
});
