import * as React from 'react';
function UfoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="111" height="110" viewBox="0 0 111 110" fill="none">
      <g clipPath="url(#clip0_7606_89776)">
        <path d="M60.1157 67.7809C60.4044 68.8704 59.7727 69.9474 58.7591 70.2221C57.7456 70.4968 56.666 69.8835 56.3774 68.794C56.0888 67.7045 56.7205 66.6275 57.734 66.3529C58.7476 66.0782 59.8271 66.6915 60.1157 67.7809Z" stroke="#696B77" strokeWidth="2"/>
        <path d="M79.0278 59.7853C79.3165 60.8748 78.6848 61.9518 77.6712 62.2265C76.6577 62.5011 75.5781 61.8879 75.2895 60.7984C75.0009 59.7089 75.6326 58.6319 76.6461 58.3572C77.6597 58.0826 78.7392 58.6959 79.0278 59.7853Z" stroke="#696B77" strokeWidth="2"/>
        <path d="M93.0708 49.2822C93.3594 50.3716 92.7277 51.4486 91.7142 51.7233C90.7006 51.998 89.6211 51.3847 89.3325 50.2952C89.0438 49.2058 89.6755 48.1287 90.6891 47.8541C91.7026 47.5794 92.7822 48.1927 93.0708 49.2822Z" stroke="#696B77" strokeWidth="2"/>
        <path d="M40.6558 70.184C40.9444 71.2735 40.3127 72.3505 39.2992 72.6252C38.2856 72.8998 37.2061 72.2865 36.9174 71.1971C36.6288 70.1076 37.2605 69.0306 38.274 68.7559C39.2876 68.4813 40.3671 69.0946 40.6558 70.184Z" stroke="#696B77" strokeWidth="2"/>
        <path d="M23.3013 68.1894C23.5899 69.2788 22.9582 70.3559 21.9447 70.6305C20.9311 70.9052 19.8516 70.2919 19.5629 69.2025C19.2743 68.113 19.906 67.036 20.9196 66.7613C21.9331 66.4866 23.0126 67.0999 23.3013 68.1894Z" stroke="#696B77" strokeWidth="2"/>
        <mask id="path-6-inside-1_7606_89776" fill="white">
          <path d="M15.3666 50.5934C5.37616 57.7549 0.0028345 65.7038 1.78609 72.4346C5.10809 84.9733 31.9372 88.5868 61.7212 80.5155C67.1224 79.0518 72.2501 77.3015 77.0053 75.3543"/>
        </mask>
        <path d="M14.2105 48.966C9.08826 52.6378 5.03212 56.5915 2.47908 60.6041C-0.0754662 64.6191 -1.2268 68.8718 -0.144282 72.9577L3.71647 71.9115C3.01572 69.2666 3.65943 66.1794 5.84334 62.7469C8.02876 59.3121 11.6545 55.7106 16.5227 52.2209L14.2105 48.966ZM-0.144282 72.9577C0.862979 76.7596 3.61519 79.7122 7.36671 81.8622C11.1089 84.0068 15.9757 85.4368 21.5548 86.1888C32.722 87.694 47.1486 86.5367 62.2334 82.4488L61.209 78.5822C46.5098 82.5656 32.6298 83.6373 22.1059 82.2188C16.8395 81.509 12.5146 80.1891 9.36137 78.382C6.21737 76.5801 4.3702 74.379 3.71647 71.9115L-0.144282 72.9577ZM62.2334 82.4488C67.714 80.9636 72.9203 79.1868 77.7525 77.208L76.258 73.5007C71.58 75.4163 66.5308 77.14 61.209 78.5822L62.2334 82.4488Z" fill="#696B77" mask="url(#path-6-inside-1_7606_89776)"/>
        <mask id="path-8-inside-2_7606_89776" fill="white">
          <path d="M87.0696 31.1625C99.2614 32.3101 107.848 36.4786 109.631 43.2095C111.834 51.5261 103.134 61.6802 88.3174 69.928"/>
        </mask>
        <path d="M87.2659 29.1685C86.1682 29.0652 85.1905 29.8742 85.0821 30.9755C84.9737 32.0768 85.7757 33.0533 86.8734 33.1566L87.2659 29.1685ZM87.3547 68.1781C86.3883 68.716 86.0359 69.9356 86.5676 70.9021C87.0994 71.8686 88.3138 72.216 89.2802 71.678L87.3547 68.1781ZM86.8734 33.1566C92.8141 33.7158 97.7351 35.0011 101.327 36.8708C104.916 38.7393 107 41.0878 107.701 43.7326L111.561 42.6863C110.479 38.6003 107.378 35.4991 103.181 33.3137C98.985 31.1295 93.517 29.7568 87.2659 29.1685L86.8734 33.1566ZM107.701 43.7326C108.566 46.9989 107.375 50.9558 103.812 55.3326C100.275 59.6779 94.6277 64.1293 87.3547 68.1781L89.2802 71.678C96.8233 67.4789 102.935 62.7294 106.901 57.8565C110.842 53.0151 112.899 47.7367 111.561 42.6863L107.701 43.7326Z" fill="#696B77" mask="url(#path-8-inside-2_7606_89776)"/>
        <mask id="path-10-inside-3_7606_89776" fill="white">
          <path d="M109.947 46.2087L109.953 46.2291C110.578 66.8111 92.2536 87.5385 65.4991 94.7888C38.7445 102.039 12.6305 93.3542 2.99661 75.2135L2.99121 75.1931"/>
        </mask>
        <path d="M109.953 46.2291L111.949 46.1583L111.942 45.9282L111.883 45.7059L109.953 46.2291ZM2.99661 75.2135L1.06624 75.7366L1.12512 75.9589L1.23283 76.1617L2.99661 75.2135ZM108.017 46.7318L108.022 46.7522L111.883 45.7059L111.878 45.6856L108.017 46.7318ZM107.956 46.2998C108.544 65.6348 91.1902 85.7546 64.9869 92.8555L66.0113 96.7221C93.3171 89.3224 112.612 67.9873 111.949 46.1583L107.956 46.2998ZM64.9869 92.8555C38.7837 99.9564 13.8107 91.307 4.7604 74.2653L1.23283 76.1617C11.4504 95.4014 38.7053 104.122 66.0113 96.7221L64.9869 92.8555ZM4.92699 74.6904L4.92159 74.67L1.06084 75.7162L1.06624 75.7366L4.92699 74.6904Z" fill="#696B77" mask="url(#path-10-inside-3_7606_89776)"/>
        <path d="M81.4181 43.5331L81.4178 43.5334C66.0177 57.3953 45.0181 62.8246 24.9451 58.1694L24.6505 56.7708L24.6504 56.7704C21.2968 40.8885 30.6888 25.2115 45.9693 21.0706C61.2522 16.929 77.1339 25.7569 82.0813 41.2069L82.0815 41.2074L82.5119 42.5493L81.4181 43.5331Z" stroke="#696B77" strokeWidth="2"/>
        <path d="M45.5152 32.3439L34.5726 46.6081C35.7442 40.4077 39.7665 35.0372 45.5152 32.3439Z" stroke="#696B77" strokeWidth="2"/>
      </g>
      <defs>
        <clipPath id="clip0_7606_89776">
          <rect width="110" height="110" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
}
export default UfoIcon;
