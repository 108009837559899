import { FC, TdHTMLAttributes, useContext } from 'react';

import { BeaconTypeEnum } from '@shared/store/types';
import { TableContext } from '../table-context';
import { beaconsTypeMapping } from '@pages/Journal/constants';
import classnames from 'classnames';
import styles from './index.module.scss';

export type TCellBeaconTypeProps = TdHTMLAttributes<HTMLTableCellElement> & {
  beaconType: BeaconTypeEnum;
  className?: string;
  index?: number;
};
export const TCellBeaconType: FC<TCellBeaconTypeProps> = ({ className, beaconType, index }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const classesCell = classnames(styles.component, className);
  const classesMarker = classnames(styles.marker, styles[beaconType]);

  return (
    <td className={classesCell} style={{ width }}>
      <div className={styles.inner}>
        <span className={classesMarker} />
        <p className={styles.beaconType}>{beaconsTypeMapping[beaconType]}</p>
      </div>
    </td>
  );
};
