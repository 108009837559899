import * as React from 'react';
function SvgTarget(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#Target_svg__clip0_190_2388)"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334zM14.667 8H12M4 8H1.333M8 4V1.333M8 14.667V12" />
      </g>
      <defs>
        <clipPath id="Target_svg__clip0_190_2388">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgTarget;
