import { baseApiBase as api } from '../../store/api/baseApi_base';
export const addTagTypes = [
  '[v1] Areas',
  '[v1] Users',
  '[v2] File Storage',
  'Omnichannel API',
  '[v2] Security Users',
  '[v2] Security Users Offices',
  '[v2] Security Users Areas',
  '[v2] Employee Offices',
  '[v2] Feedbacks',
  '[v2] Prometheus',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      areaControllerGetAllAreas: build.query<AreaControllerGetAllAreasApiResponse, AreaControllerGetAllAreasApiArg>({
        query: () => ({ url: `/v1/areas/all` }),
        providesTags: ['[v1] Areas'],
      }),
      usersControllerUserSignIn: build.mutation<UsersControllerUserSignInApiResponse, UsersControllerUserSignInApiArg>({
        query: queryArg => ({ url: `/v1/users/auth/sign-in`, method: 'POST', body: queryArg.userSignInDto }),
        invalidatesTags: ['[v1] Users'],
      }),
      usersControllerConfirmUserSignIn: build.mutation<
        UsersControllerConfirmUserSignInApiResponse,
        UsersControllerConfirmUserSignInApiArg
      >({
        query: queryArg => ({
          url: `/v1/users/auth/sign-in/confirm`,
          method: 'POST',
          body: queryArg.confirmUserSignInDto,
        }),
        invalidatesTags: ['[v1] Users'],
      }),
      usersControllerUserSignInSdk: build.mutation<
        UsersControllerUserSignInSdkApiResponse,
        UsersControllerUserSignInSdkApiArg
      >({
        query: queryArg => ({ url: `/v1/users/auth/sign-in-sdk`, method: 'POST', body: queryArg.userSignInDto }),
        invalidatesTags: ['[v1] Users'],
      }),
      usersControllerGetMe: build.query<UsersControllerGetMeApiResponse, UsersControllerGetMeApiArg>({
        query: () => ({ url: `/v1/users/me` }),
        providesTags: ['[v1] Users'],
      }),
      usersControllerUpdateMe: build.mutation<UsersControllerUpdateMeApiResponse, UsersControllerUpdateMeApiArg>({
        query: queryArg => ({ url: `/v1/users/me`, method: 'PATCH', body: queryArg.updateUserDto }),
        invalidatesTags: ['[v1] Users'],
      }),
      usersControllerGetUserByVisitorId: build.query<
        UsersControllerGetUserByVisitorIdApiResponse,
        UsersControllerGetUserByVisitorIdApiArg
      >({
        query: queryArg => ({ url: `/v1/users/by-visitor-id/${queryArg.visitorId}` }),
        providesTags: ['[v1] Users'],
      }),
      fileStoragesControllerGetPresignedPutUrl: build.mutation<
        FileStoragesControllerGetPresignedPutUrlApiResponse,
        FileStoragesControllerGetPresignedPutUrlApiArg
      >({
        query: queryArg => ({
          url: `/file-storages/get-presigned-put-url`,
          method: 'POST',
          body: queryArg.getPresignedPutUrlDto,
        }),
        invalidatesTags: ['[v2] File Storage'],
      }),
      fileStoragesControllerGetSecurityPresignedPutUrl: build.mutation<
        FileStoragesControllerGetSecurityPresignedPutUrlApiResponse,
        FileStoragesControllerGetSecurityPresignedPutUrlApiArg
      >({
        query: queryArg => ({
          url: `/file-storages/get-security-presigned-put-url`,
          method: 'POST',
          body: queryArg.getPresignedPutUrlDto,
        }),
        invalidatesTags: ['[v2] File Storage'],
      }),
      omnichannelCallbackControllerProcessMessageCallback: build.mutation<
        OmnichannelCallbackControllerProcessMessageCallbackApiResponse,
        OmnichannelCallbackControllerProcessMessageCallbackApiArg
      >({
        query: queryArg => ({
          url: `/omnichannel/message/callback`,
          method: 'POST',
          body: queryArg.omnichannelMessageCallbackRequestDto,
        }),
        invalidatesTags: ['Omnichannel API'],
      }),
      mtsMaintenanceEventsControllerCallback: build.mutation<
        MtsMaintenanceEventsControllerCallbackApiResponse,
        MtsMaintenanceEventsControllerCallbackApiArg
      >({
        query: () => ({ url: `/v1/mts-events/callback`, method: 'POST' }),
      }),
      securityUsersV2ControllerSecurityUserSignIn: build.mutation<
        SecurityUsersV2ControllerSecurityUserSignInApiResponse,
        SecurityUsersV2ControllerSecurityUserSignInApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/auth/sign-in`,
          method: 'POST',
          body: queryArg.securityUserSignInV2Dto,
        }),
        invalidatesTags: ['[v2] Security Users'],
      }),
      securityUsersV2ControllerGetMe: build.query<
        SecurityUsersV2ControllerGetMeApiResponse,
        SecurityUsersV2ControllerGetMeApiArg
      >({
        query: () => ({ url: `/v2/security-users/me` }),
        providesTags: ['[v2] Security Users'],
      }),
      securityUsersV2ControllerGetAccessibleCompanies: build.query<
        SecurityUsersV2ControllerGetAccessibleCompaniesApiResponse,
        SecurityUsersV2ControllerGetAccessibleCompaniesApiArg
      >({
        query: () => ({ url: `/v2/security-users/companies` }),
        providesTags: ['[v2] Security Users'],
      }),
      securityUsersV2ControllerGetAccessibleOffices: build.query<
        SecurityUsersV2ControllerGetAccessibleOfficesApiResponse,
        SecurityUsersV2ControllerGetAccessibleOfficesApiArg
      >({
        query: queryArg => ({ url: `/v2/security-users/companies/${queryArg.companyId}/offices` }),
        providesTags: ['[v2] Security Users'],
      }),
      securityUsersV2ControllerVerifyNotification: build.mutation<
        SecurityUsersV2ControllerVerifyNotificationApiResponse,
        SecurityUsersV2ControllerVerifyNotificationApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/notifications/${queryArg.notificationId}/verify-and-comment`,
          method: 'PATCH',
          body: queryArg.notificationUpdateRequestDto,
        }),
        invalidatesTags: ['[v2] Security Users'],
      }),
      securityUsersV2ControllerSearchVisitors: build.query<
        SecurityUsersV2ControllerSearchVisitorsApiResponse,
        SecurityUsersV2ControllerSearchVisitorsApiArg
      >({
        query: queryArg => ({ url: `/v2/security-users/visitors/by-ids`, params: { ids: queryArg.ids } }),
        providesTags: ['[v2] Security Users'],
      }),
      securityUsersOfficesV2ControllerGetAccessibleOfficeAreas: build.query<
        SecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasApiResponse,
        SecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasApiArg
      >({
        query: queryArg => ({ url: `/v2/security-users/offices/${queryArg.officeId}/areas` }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerGetVisitorInfo: build.query<
        SecurityUsersOfficesV2ControllerGetVisitorInfoApiResponse,
        SecurityUsersOfficesV2ControllerGetVisitorInfoApiArg
      >({
        query: queryArg => ({ url: `/v2/security-users/offices/${queryArg.officeId}/visitors/${queryArg.visitorId}` }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerGetVisitorsList: build.query<
        SecurityUsersOfficesV2ControllerGetVisitorsListApiResponse,
        SecurityUsersOfficesV2ControllerGetVisitorsListApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/visitors`,
          params: { ids: queryArg.ids },
        }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerSearchVisitors: build.query<
        SecurityUsersOfficesV2ControllerSearchVisitorsApiResponse,
        SecurityUsersOfficesV2ControllerSearchVisitorsApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/search-visitors`,
          params: { searchQuery: queryArg.searchQuery, limit: queryArg.limit, page: queryArg.page },
        }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerGetNotifications: build.query<
        SecurityUsersOfficesV2ControllerGetNotificationsApiResponse,
        SecurityUsersOfficesV2ControllerGetNotificationsApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/notifications`,
          params: {
            suspiciousActivityTab: queryArg.suspiciousActivityTab,
            notificationSortFields: queryArg.notificationSortFields,
            order: queryArg.order,
            limit: queryArg.limit,
            page: queryArg.page,
          },
        }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerGetAttendanceLogs: build.query<
        SecurityUsersOfficesV2ControllerGetAttendanceLogsApiResponse,
        SecurityUsersOfficesV2ControllerGetAttendanceLogsApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/attendance-logs`,
          params: {
            tabFilter: queryArg.tabFilter,
            searchField: queryArg.searchField,
            sort: queryArg.sort,
            order: queryArg.order,
            limit: queryArg.limit,
            page: queryArg.page,
          },
        }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerGetVisitorAttendanceLogs: build.query<
        SecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsApiResponse,
        SecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/visitors/${queryArg.visitorId}/attendance-logs`,
          params: { sort: queryArg.sort, order: queryArg.order, limit: queryArg.limit, page: queryArg.page },
        }),
        providesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerActivateEmergencyMode: build.mutation<
        SecurityUsersOfficesV2ControllerActivateEmergencyModeApiResponse,
        SecurityUsersOfficesV2ControllerActivateEmergencyModeApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/activate-emergency-mode`,
          method: 'POST',
          body: queryArg.activateEmergencyModeDto,
        }),
        invalidatesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersOfficesV2ControllerDeactivateEmergencyMode: build.mutation<
        SecurityUsersOfficesV2ControllerDeactivateEmergencyModeApiResponse,
        SecurityUsersOfficesV2ControllerDeactivateEmergencyModeApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/offices/${queryArg.officeId}/deactivate-emergency-mode`,
          method: 'POST',
        }),
        invalidatesTags: ['[v2] Security Users Offices'],
      }),
      securityUsersAreasV2ControllerCreate: build.mutation<
        SecurityUsersAreasV2ControllerCreateApiResponse,
        SecurityUsersAreasV2ControllerCreateApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/areas`,
          method: 'POST',
          body: queryArg.securityUserCreateAreaDto,
        }),
        invalidatesTags: ['[v2] Security Users Areas'],
      }),
      securityUsersAreasV2ControllerGetAreaInfo: build.query<
        SecurityUsersAreasV2ControllerGetAreaInfoApiResponse,
        SecurityUsersAreasV2ControllerGetAreaInfoApiArg
      >({
        query: queryArg => ({ url: `/v2/security-users/areas/${queryArg.areaId}` }),
        providesTags: ['[v2] Security Users Areas'],
      }),
      securityUsersAreasV2ControllerUpdate: build.mutation<
        SecurityUsersAreasV2ControllerUpdateApiResponse,
        SecurityUsersAreasV2ControllerUpdateApiArg
      >({
        query: queryArg => ({
          url: `/v2/security-users/areas/${queryArg.areaId}`,
          method: 'PATCH',
          body: queryArg.securityUserUpdateAreaDto,
        }),
        invalidatesTags: ['[v2] Security Users Areas'],
      }),
      securityUsersAreasV2ControllerDeleteOne: build.mutation<
        SecurityUsersAreasV2ControllerDeleteOneApiResponse,
        SecurityUsersAreasV2ControllerDeleteOneApiArg
      >({
        query: queryArg => ({ url: `/v2/security-users/areas/${queryArg.areaId}`, method: 'DELETE' }),
        invalidatesTags: ['[v2] Security Users Areas'],
      }),
      officeControllerGetAccessOffices: build.query<
        OfficeControllerGetAccessOfficesApiResponse,
        OfficeControllerGetAccessOfficesApiArg
      >({
        query: () => ({ url: `/v2/offices` }),
        providesTags: ['[v2] Employee Offices'],
      }),
      feedbackControllerSendFeedback: build.mutation<
        FeedbackControllerSendFeedbackApiResponse,
        FeedbackControllerSendFeedbackApiArg
      >({
        query: queryArg => ({ url: `/v2/feedbacks/send`, method: 'POST', body: queryArg.sendFeedbackRequestDto }),
        invalidatesTags: ['[v2] Feedbacks'],
      }),
      prometheusControllerGetMe: build.query<PrometheusControllerGetMeApiResponse, PrometheusControllerGetMeApiArg>({
        query: () => ({ url: `/metrics` }),
        providesTags: ['[v2] Prometheus'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as apiService_base };
export type AreaControllerGetAllAreasApiResponse = /** status 200  */ AreasListResponseDto;
export type AreaControllerGetAllAreasApiArg = void;
export type UsersControllerUserSignInApiResponse = /** status 200  */ UserSignInResponseDto;
export type UsersControllerUserSignInApiArg = {
  userSignInDto: UserSignInDto;
};
export type UsersControllerConfirmUserSignInApiResponse = /** status 200  */ UserSignInResponseDto2;
export type UsersControllerConfirmUserSignInApiArg = {
  confirmUserSignInDto: ConfirmUserSignInDto;
};
export type UsersControllerUserSignInSdkApiResponse = /** status 200  */ UserSignInResponseDto;
export type UsersControllerUserSignInSdkApiArg = {
  userSignInDto: UserSignInDto;
};
export type UsersControllerGetMeApiResponse = /** status 200  */ UserResponseDto;
export type UsersControllerGetMeApiArg = void;
export type UsersControllerUpdateMeApiResponse = /** status 200  */ UserResponseDto;
export type UsersControllerUpdateMeApiArg = {
  updateUserDto: UpdateUserDto;
};
export type UsersControllerGetUserByVisitorIdApiResponse = /** status 200  */ UserProfileResponseDto;
export type UsersControllerGetUserByVisitorIdApiArg = {
  visitorId: string;
};
export type FileStoragesControllerGetPresignedPutUrlApiResponse = /** status 200  */ undefined;
export type FileStoragesControllerGetPresignedPutUrlApiArg = {
  getPresignedPutUrlDto: GetPresignedPutUrlDto;
};
export type FileStoragesControllerGetSecurityPresignedPutUrlApiResponse = /** status 200  */ undefined;
export type FileStoragesControllerGetSecurityPresignedPutUrlApiArg = {
  getPresignedPutUrlDto: GetPresignedPutUrlDto;
};
export type OmnichannelCallbackControllerProcessMessageCallbackApiResponse = unknown;
export type OmnichannelCallbackControllerProcessMessageCallbackApiArg = {
  omnichannelMessageCallbackRequestDto: OmnichannelMessageCallbackRequestDto;
};
export type MtsMaintenanceEventsControllerCallbackApiResponse = unknown;
export type MtsMaintenanceEventsControllerCallbackApiArg = void;
export type SecurityUsersV2ControllerSecurityUserSignInApiResponse = /** status 200  */ SecurityUserSignInV2ResponseDto;
export type SecurityUsersV2ControllerSecurityUserSignInApiArg = {
  securityUserSignInV2Dto: SecurityUserSignInV2Dto;
};
export type SecurityUsersV2ControllerGetMeApiResponse = /** status 200  */ SecurityUserV2ResponseDto;
export type SecurityUsersV2ControllerGetMeApiArg = void;
export type SecurityUsersV2ControllerGetAccessibleCompaniesApiResponse =
  /** status 200  */ SecurityUserCompanyResponseDto[];
export type SecurityUsersV2ControllerGetAccessibleCompaniesApiArg = void;
export type SecurityUsersV2ControllerGetAccessibleOfficesApiResponse =
  /** status 200  */ SecurityUserOfficeResponseDto[];
export type SecurityUsersV2ControllerGetAccessibleOfficesApiArg = {
  companyId: string;
};
export type SecurityUsersV2ControllerVerifyNotificationApiResponse = /** status 200  */ NotificationResponseDto;
export type SecurityUsersV2ControllerVerifyNotificationApiArg = {
  notificationId: string;
  notificationUpdateRequestDto: NotificationUpdateRequestDto;
};
export type SecurityUsersV2ControllerSearchVisitorsApiResponse = /** status 200  */ SecurityUserVisitorResponseDto[];
export type SecurityUsersV2ControllerSearchVisitorsApiArg = {
  ids: string[];
};
export type SecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasApiResponse =
  /** status 200  */ SecurityUserAreasListResponseDto;
export type SecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasApiArg = {
  officeId: string;
};
export type SecurityUsersOfficesV2ControllerGetVisitorInfoApiResponse =
  /** status 200  */ SecurityUserVisitorInfoResponseDto;
export type SecurityUsersOfficesV2ControllerGetVisitorInfoApiArg = {
  visitorId: string;
  officeId: string;
};
export type SecurityUsersOfficesV2ControllerGetVisitorsListApiResponse =
  /** status 200  */ SecurityUserVisitorsListResponseDto;
export type SecurityUsersOfficesV2ControllerGetVisitorsListApiArg = {
  officeId: string;
  ids: string[];
};
export type SecurityUsersOfficesV2ControllerSearchVisitorsApiResponse =
  /** status 200  */ SecurityUserSearchVisitorsPaginateResponseDto;
export type SecurityUsersOfficesV2ControllerSearchVisitorsApiArg = {
  officeId: string;
  searchQuery: string;
  limit?: number;
  page?: number;
};
export type SecurityUsersOfficesV2ControllerGetNotificationsApiResponse =
  /** status 200  */ NotificationsPaginateResponseDto;
export type SecurityUsersOfficesV2ControllerGetNotificationsApiArg = {
  suspiciousActivityTab?: 'not_verified' | 'verified';
  notificationSortFields?: 'name' | 'phone' | 'created_at';
  order?: 'asc' | 'desc';
  limit?: number;
  page?: number;
  officeId: string;
};
export type SecurityUsersOfficesV2ControllerGetAttendanceLogsApiResponse =
  /** status 200  */ AttendanceLogsPaginateResponseDto;
export type SecurityUsersOfficesV2ControllerGetAttendanceLogsApiArg = {
  officeId: string;
  tabFilter?: 'all' | 'employee' | 'guest';
  searchField?: string;
  sort?: 'created_at' | 'last_name';
  order?: 'asc' | 'desc';
  limit?: number;
  page?: number;
};
export type SecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsApiResponse =
  /** status 200  */ AttendanceLogsPaginateResponseDto;
export type SecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsApiArg = {
  officeId: string;
  visitorId: string;
  sort?: 'created_at';
  order?: 'asc' | 'desc';
  limit?: number;
  page?: number;
};
export type SecurityUsersOfficesV2ControllerActivateEmergencyModeApiResponse = /** status 200  */
  | MessageResponseDto
  | /** status 201  */ MessageResponseDto;
export type SecurityUsersOfficesV2ControllerActivateEmergencyModeApiArg = {
  officeId: string;
  activateEmergencyModeDto: ActivateEmergencyModeDto;
};
export type SecurityUsersOfficesV2ControllerDeactivateEmergencyModeApiResponse = /** status 200  */
  | MessageResponseDto
  | /** status 201  */ MessageResponseDto;
export type SecurityUsersOfficesV2ControllerDeactivateEmergencyModeApiArg = {
  officeId: string;
};
export type SecurityUsersAreasV2ControllerCreateApiResponse = /** status 201  */ SecurityUserAreaResponseDto;
export type SecurityUsersAreasV2ControllerCreateApiArg = {
  securityUserCreateAreaDto: SecurityUserCreateAreaDto;
};
export type SecurityUsersAreasV2ControllerGetAreaInfoApiResponse = /** status 200  */ SecurityUserAreaResponseDto;
export type SecurityUsersAreasV2ControllerGetAreaInfoApiArg = {
  areaId: string;
};
export type SecurityUsersAreasV2ControllerUpdateApiResponse = /** status 200  */ SecurityUserAreaResponseDto;
export type SecurityUsersAreasV2ControllerUpdateApiArg = {
  areaId: string;
  securityUserUpdateAreaDto: SecurityUserUpdateAreaDto;
};
export type SecurityUsersAreasV2ControllerDeleteOneApiResponse = unknown;
export type SecurityUsersAreasV2ControllerDeleteOneApiArg = {
  areaId: string;
};
export type OfficeControllerGetAccessOfficesApiResponse = /** status 200  */ VisitorAccessResponseDto[];
export type OfficeControllerGetAccessOfficesApiArg = void;
export type FeedbackControllerSendFeedbackApiResponse = /** status 200  */
  | BaseStatusResponseDto
  | /** status 201  */ BaseStatusResponseDto;
export type FeedbackControllerSendFeedbackApiArg = {
  sendFeedbackRequestDto: SendFeedbackRequestDto;
};
export type PrometheusControllerGetMeApiResponse = unknown;
export type PrometheusControllerGetMeApiArg = void;
export type Point2ResponseDto = {
  x?: number;
  y?: number;
};
export type AreaResponseDto = {
  id: string;
  name: string;
  officeId: string;
  planUrl: string;
  zero?: Point2ResponseDto | null;
  scale?: Point2ResponseDto | null;
  isEntranceLocation: boolean;
};
export type AreasListResponseDto = {
  areas: AreaResponseDto[];
};
export type UserSignInResponseDto = {
  status: boolean;
  message?: string | null;
  nextSendAvailableAt: string;
  code: string | null;
};
export type UserSignInDto = {
  phone: string;
};
export type UserSignInResponseDto2 = {
  accessToken: string;
};
export type ConfirmUserSignInDto = {
  confirmationCode: string;
};
export type UserResponseDto = {
  id: string;
  phone: string;
  fullname?: string | null;
  deviceId: string;
  isPhotoPassed: boolean;
  isBiometricsPassed: boolean;
  lastBiometricsPassedAt?: string;
  createdAt: string;
  updatedAt: string;
};
export type UpdateUserDto = {
  deviceId?: string;
  lastBiometricsPassedAt?: string;
};
export type UserProfileResponseDto = {
  id: string;
  phone: string;
  fullname?: string | null;
};
export type GetPresignedPutUrlResponseDto = {
  presignedUrl: string;
};
export type GetPresignedPutUrlDto = {
  fileName: string;
  fileExtension: 'jpg' | 'jpeg' | 'png' | 'svg';
};
export type OmnichannelMessageCallbackItem = {
  message_id?: string;
  internal_id: string;
  naming: string;
  destination: string;
  status: 200 | 201 | 202 | 300 | 301 | 302 | 310 | 311 | 400 | 401 | 402;
  channel: number;
  total_parts?: number | null;
  received_at?: string | null;
  event_at: string;
};
export type OmnichannelMessageCallbackRequestDto = {
  items: OmnichannelMessageCallbackItem[];
};
export type SecurityUserSignInV2ResponseDto = {
  accessToken: string;
};
export type SecurityUserSignInV2Dto = {
  login: string;
  password: string;
};
export type SecurityUserOfficeResponseDto = {
  id: string;
  name?: string | null;
  address?: string | null;
  emergencyModeActive: boolean;
};
export type SecurityUserV2ResponseDto = {
  id: string;
  nickname: string;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  offices?: SecurityUserOfficeResponseDto[];
  createdAt: string;
  updatedAt: string;
};
export type SecurityUserCompanyResponseDto = {
  id: string;
  name: string;
};
export type PassageKind = 'entrance' | 'exit';
export type PassageStatusKind = 'default' | 'suspicious';
export type SuspiciousActivityKind =
  | 'bluetooth_undefined'
  | 'biometrics_undefined'
  | 'bluetooth_and_biometrics_undefined';
export type AttendanceLogResponseDto = {
  id: string;
  passageName: string;
  passageType: PassageKind;
  passageStatus: PassageStatusKind;
  suspiciousActivity: SuspiciousActivityKind | null;
  visitorId: string | null;
  visitorFirstName: string | null;
  visitorLastName: string | null;
  visitorMiddleName: string | null;
  visitorPhoneNumber: string | null;
  visitorAccessKey: string | null;
  visitorAvatarUrl: string | null;
  createdAt: string;
  updatedAt: string;
};
export type NotificationResponseDto = {
  id: string;
  commentAuthorName?: string | null;
  comment?: string | null;
  commentedAt?: string | null;
  violation: boolean | null;
  attendanceLogId: string;
  attendanceLogInfo: AttendanceLogResponseDto;
  createdAt: string;
  updatedAt: string;
};
export type NotificationUpdateRequestDto = {
  isViolated: boolean | null;
  comment?: string;
};
export type VisitorRoleKind = 'employee' | 'security' | 'guest' | 'contractor';
export type SecurityUserVisitorResponseDto = {
  id: string;
  phone: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: VisitorRoleKind;
  avatarKey?: string | null;
};
export type SecurityUserAreasListItemResponseDto = {
  id: string;
  name: string;
};
export type SecurityUserAreasListResponseDto = {
  areas: SecurityUserAreasListItemResponseDto[];
};
export type SecurityUserVisitorInviterResponseDto = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  role: VisitorRoleKind;
  avatarKey?: string | null;
};
export type SecurityUserVisitorInfoResponseDto = {
  id: string;
  phone: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: VisitorRoleKind;
  companyName: string;
  inviter?: SecurityUserVisitorInviterResponseDto | null;
  accessFrom?: string | null;
  accessTo?: string | null;
  avatarKey?: string | null;
};
export type SecurityUserVisitorsListItemResponseDto = {
  id: string;
  attendedAt?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  role?: VisitorRoleKind | null;
  avatarKey?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  hasBeenFound: boolean;
  hasAccess: boolean;
};
export type SecurityUserVisitorsListResponseDto = {
  visitors: SecurityUserVisitorsListItemResponseDto[];
};
export type SecurityUserSearchVisitorsPaginateItemDto = {
  id: string;
  phone: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: VisitorRoleKind;
  companyName: string;
  avatarKey?: string | null;
};
export type ResponseMetaDto = {
  count: number;
  total: number;
  page: number;
  pageCount: number;
  perPage: number;
};
export type SecurityUserSearchVisitorsPaginateResponseDto = {
  data: SecurityUserSearchVisitorsPaginateItemDto[];
  meta: ResponseMetaDto;
};
export type NotificationsPaginateResponseDto = {
  data: NotificationResponseDto[];
  meta: ResponseMetaDto;
};
export type AttendanceLogsPaginateResponseDto = {
  data: AttendanceLogResponseDto[];
  meta: ResponseMetaDto;
};
export type MessageResponseDto = {
  status: boolean;
  message?: string | null;
};
export type ActivateEmergencyModeDto = {
  emergencyDescription: string;
};
export type SecurityUserAreaResponseDto = {
  id: string;
  name: string;
  planUrl: string;
  zero?: Point2ResponseDto | null;
  scale?: Point2ResponseDto | null;
  isEntranceLocation: boolean;
};
export type SecurityUserCreatePoint2Dto = {
  x?: number;
  y?: number;
};
export type SecurityUserCreateAreaDto = {
  name: string;
  planFileKey: string;
  officeId: string;
  zero?: SecurityUserCreatePoint2Dto | null;
  scale?: SecurityUserCreatePoint2Dto | null;
  isEntranceLocation: boolean;
};
export type SecurityUserUpdateAreaDto = {
  name?: string;
  planFileKey?: string;
  zero?: SecurityUserCreatePoint2Dto | null;
  scale?: SecurityUserCreatePoint2Dto | null;
  isEntranceLocation?: boolean;
};
export type CompanyAccessResponseDto = {
  id: string;
  name?: string | null;
  backgroundCardUrl?: string | null;
  brandUrl?: string | null;
};
export type OfficeAccessResponseDto = {
  id: string;
  name?: string | null;
  address?: string | null;
  accessStrategy?: ('rus_guard' | 'trassir' | 'none') | null;
  accessKey?: string | null;
  accessFrom?: string | null;
  accessTo?: string | null;
};
export type VisitorAccessResponseDto = {
  id: string;
  firstName: string;
  lastName: string;
  role: VisitorRoleKind;
  company: CompanyAccessResponseDto;
  offices: OfficeAccessResponseDto[];
};
export type BaseStatusResponseDto = {
  status: number;
  ok: boolean;
};
export type SendFeedbackRequestDto = {
  text: string;
};
export const {
  useAreaControllerGetAllAreasQuery,
  useLazyAreaControllerGetAllAreasQuery,
  useUsersControllerUserSignInMutation,
  useUsersControllerConfirmUserSignInMutation,
  useUsersControllerUserSignInSdkMutation,
  useUsersControllerGetMeQuery,
  useLazyUsersControllerGetMeQuery,
  useUsersControllerUpdateMeMutation,
  useUsersControllerGetUserByVisitorIdQuery,
  useLazyUsersControllerGetUserByVisitorIdQuery,
  useFileStoragesControllerGetPresignedPutUrlMutation,
  useFileStoragesControllerGetSecurityPresignedPutUrlMutation,
  useOmnichannelCallbackControllerProcessMessageCallbackMutation,
  useMtsMaintenanceEventsControllerCallbackMutation,
  useSecurityUsersV2ControllerSecurityUserSignInMutation,
  useSecurityUsersV2ControllerGetMeQuery,
  useLazySecurityUsersV2ControllerGetMeQuery,
  useSecurityUsersV2ControllerGetAccessibleCompaniesQuery,
  useLazySecurityUsersV2ControllerGetAccessibleCompaniesQuery,
  useSecurityUsersV2ControllerGetAccessibleOfficesQuery,
  useLazySecurityUsersV2ControllerGetAccessibleOfficesQuery,
  useSecurityUsersV2ControllerVerifyNotificationMutation,
  useSecurityUsersV2ControllerSearchVisitorsQuery,
  useLazySecurityUsersV2ControllerSearchVisitorsQuery,
  useSecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasQuery,
  useLazySecurityUsersOfficesV2ControllerGetAccessibleOfficeAreasQuery,
  useSecurityUsersOfficesV2ControllerGetVisitorInfoQuery,
  useLazySecurityUsersOfficesV2ControllerGetVisitorInfoQuery,
  useSecurityUsersOfficesV2ControllerGetVisitorsListQuery,
  useLazySecurityUsersOfficesV2ControllerGetVisitorsListQuery,
  useSecurityUsersOfficesV2ControllerSearchVisitorsQuery,
  useLazySecurityUsersOfficesV2ControllerSearchVisitorsQuery,
  useSecurityUsersOfficesV2ControllerGetNotificationsQuery,
  useLazySecurityUsersOfficesV2ControllerGetNotificationsQuery,
  useSecurityUsersOfficesV2ControllerGetAttendanceLogsQuery,
  useLazySecurityUsersOfficesV2ControllerGetAttendanceLogsQuery,
  useSecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsQuery,
  useLazySecurityUsersOfficesV2ControllerGetVisitorAttendanceLogsQuery,
  useSecurityUsersOfficesV2ControllerActivateEmergencyModeMutation,
  useSecurityUsersOfficesV2ControllerDeactivateEmergencyModeMutation,
  useSecurityUsersAreasV2ControllerCreateMutation,
  useSecurityUsersAreasV2ControllerGetAreaInfoQuery,
  useLazySecurityUsersAreasV2ControllerGetAreaInfoQuery,
  useSecurityUsersAreasV2ControllerUpdateMutation,
  useSecurityUsersAreasV2ControllerDeleteOneMutation,
  useOfficeControllerGetAccessOfficesQuery,
  useLazyOfficeControllerGetAccessOfficesQuery,
  useFeedbackControllerSendFeedbackMutation,
  usePrometheusControllerGetMeQuery,
  useLazyPrometheusControllerGetMeQuery,
} = injectedRtkApi;
