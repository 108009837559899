import { Button, InputField, Modal } from '@shared/ui';
import { ToastContainer, toast } from 'react-toastify';
import { useClientSize, useToggle } from '@shared/hooks';

import { showToast } from '@shared/ui/Toast';
import styles from './createVideoWall.module.scss';
import { useCreateVideoWall } from '../hooks';
import { useEffect } from 'react';

type CreateVideoWallProps = {
  isEmptyWall?: boolean;
  onSuccess: () => void;
};

export const CreateVideoWall = ({ isEmptyWall, onSuccess }: CreateVideoWallProps) => {
  const { state: isVisibleCreateVideoWallModal, toggle: toggleCreateVideoWallModal } = useToggle();

  const { control, errors, isValid, onSubmit, reset, isLoading, isSuccess, isError } = useCreateVideoWall();

  useEffect(() => {
    if (isError) {
      showToast({ variant: 'error', message: 'Ошибка при добавлении', containerId: 'error-modal' });
    }
    if (isSuccess) {
      reset();
      toggleCreateVideoWallModal();
      onSuccess();
      showToast({ variant: 'success', message: 'Видеостена добавлена', containerId: 'success-common' });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    reset();
  }, [isVisibleCreateVideoWallModal]);

  const { getIsBreakpoint } = useClientSize();

  const isDesktopL = getIsBreakpoint('desktopL');

  return (
    <>
      <ToastContainer containerId="success-common" hideProgressBar closeOnClick closeButton={false} />
      <Button variant={isEmptyWall ? 'primary' : 'secondary'} onClick={toggleCreateVideoWallModal}>
        {isDesktopL ? 'Создать новую видеостену' : 'Создать видеостену'}
      </Button>
      <Modal
        containerVariant="largeContainer"
        isVisible={isVisibleCreateVideoWallModal}
        closeCallback={toggleCreateVideoWallModal}
        title="Новая видеостена">
        <ToastContainer containerId="error-modal" hideProgressBar closeOnClick closeButton={false} />
        <form className={styles.root} onSubmit={onSubmit}>
          <p className={styles.text}>
            Здесь вы можете отредактировать название и описание видеостены, или совсем её удалить, если она больше не
            актуальна
          </p>
          <InputField
            control={control}
            rootClassName={styles.input}
            placeholder="Введите название видеостены"
            name="name"
            type="text"
            label="Название"
            error={errors.name}
          />
          <InputField
            control={control}
            rootClassName={styles.input}
            placeholder="Введите краткое описание"
            name="description"
            type="text"
            label="Описание"
            error={errors.description}
          />
          <Button
            className={styles.button}
            variant="primary"
            type="submit"
            size="large"
            isLoading={isLoading}
            disabled={!isValid}>
            Создать видеостену
          </Button>
        </form>
      </Modal>
    </>
  );
};
