import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 6.333a4 4 0 1 0-8 0c0 4.667-2 6-2 6h12s-2-1.333-2-6ZM9.153 15a1.333 1.333 0 0 1-2.306 0"
    />
  </svg>
);
export default SvgNotification;
