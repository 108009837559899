import { MAX_SCALE_COUNTER, MIN_SCALE_COUNTER, SCALE_COUNTER_SHIFT } from '../constants';

export const useHandleWheel = (event: Event, totalScaleFactor: number, zoom: (clicks: number) => void) => {
  const handleWheel = (event: Event) => {
    // @ts-ignore
    let delta = event.deltaY;
    if (delta) {
      delta /= 40;
      // @ts-ignore
    } else if (event.detail) {
      // @ts-ignore
      delta = -event.detail;
    } else {
      delta = 0;
    }

    const isPreviousAnimatioComplete = totalScaleFactor % SCALE_COUNTER_SHIFT === 0;

    if (delta > 1 && totalScaleFactor > MIN_SCALE_COUNTER && isPreviousAnimatioComplete) {
      zoom(delta);
    }

    if (
      delta < 1 &&
      totalScaleFactor < MAX_SCALE_COUNTER &&
      isPreviousAnimatioComplete &&
      totalScaleFactor > MIN_SCALE_COUNTER
    ) {
      zoom(delta);
    }

    return false;
  };

  return handleWheel(event);
};
