import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationDone24 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#NotificationDone24_svg__a)">
      <path
        fill="#54AB57"
        fillOpacity={0.25}
        fillRule="evenodd"
        d="M.106 9.232C.366 5.744.496 4 2.246 2.249 3.996.499 5.74.369 9.229.109a37.476 37.476 0 0 1 2.77-.108c.932 0 1.865.04 2.769.108C18.256.37 20 .5 21.751 2.25c1.75 1.75 1.88 3.495 2.14 6.983.067.904.108 1.837.108 2.77 0 .932-.041 1.865-.108 2.77-.26 3.487-.39 5.231-2.14 6.982-1.75 1.75-3.495 1.88-6.983 2.14-.904.067-1.837.108-2.77.108-.932 0-1.865-.04-2.77-.108-3.488-.26-5.232-.39-6.982-2.14S.366 18.259.106 14.77a37.474 37.474 0 0 1-.108-2.77c0-.932.04-1.865.108-2.77Z"
        clipRule="evenodd"
      />
      <path
        stroke="#63C966"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m7 11.823 2.785 2.557a1 1 0 0 0 1.353 0L17 9"
      />
    </g>
    <defs>
      <clipPath id="NotificationDone24_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotificationDone24;
