import * as React from 'react';
function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.5 19a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM20 20l-3-3"
        stroke="#90919C"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSearch;
