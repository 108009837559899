import { Button, InputField, Modal } from '@shared/ui';
import { FC, useEffect } from 'react';
import { useDeleteVideoWall, useUpdateVideoWall } from '../hooks';

import styles from './updateVideoWall.module.scss';

type UpdateVideoWallProps = {
  name?: string;
  description?: string;
  isModalVisible: boolean;
  closeCallback: () => void;
  onSuccess: () => void;
};

export const UpdateVideoWall: FC<UpdateVideoWallProps> = ({
  name,
  description,
  isModalVisible,
  closeCallback,
  onSuccess,
}) => {
  const { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading, handleDeleteVideoWall } = useDeleteVideoWall();

  const {
    reset,
    errors,
    isValid,
    control,
    setValue,
    onSubmit,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateVideoWall();

  const setInitialFormValues = () => {
    name && setValue('name', name);
    description && setValue('description', description);
  };

  const handleCloseModal = () => {
    setInitialFormValues();
    closeCallback();
  };

  useEffect(() => {
    setInitialFormValues();
  }, [name, description]);

  useEffect(() => {
    if (isUpdateSuccess) {
      reset();
      closeCallback();
      onSuccess();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      closeCallback();
      onSuccess();
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Modal
        width={514}
        containerVariant="largeContainer"
        isVisible={isModalVisible}
        closeCallback={handleCloseModal}
        title={name}>
        <form className={styles.root} onSubmit={onSubmit}>
          <p className={styles.text}>
            Здесь вы можете отредактировать название и описание видеостены, или совсем её удалить, если она больше не
            актуальна
          </p>
          <InputField
            control={control}
            rootClassName={styles.input}
            placeholder="Введите название видеостены"
            name="name"
            type="text"
            label="Название"
            error={errors.name}
          />
          <InputField
            control={control}
            rootClassName={styles.input}
            placeholder="Введите краткое описание"
            name="description"
            type="text"
            label="Описание"
            error={errors.description}
          />
          <div className={styles.actions}>
            <Button variant="primary" type="submit" size="large" isLoading={isUpdateLoading} disabled={!isValid}>
              Сохранить
            </Button>
            <Button
              variant="ghost"
              type="button"
              size="large"
              isLoading={isDeleteLoading}
              onClick={handleDeleteVideoWall}>
              Удалить видеостену
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};