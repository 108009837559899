import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={111} height={110} fill="none" {...props}>
    <circle cx={55.5} cy={55} r={54} stroke="#696B77" strokeWidth={2} />
    <circle cx={55.5} cy={55} r={44} stroke="#696B77" strokeWidth={2} />
    <circle cx={55.5} cy={55} r={14} stroke="#696B77" strokeWidth={2} />
    <circle cx={102.5} cy={8} r={7} stroke="#696B77" strokeWidth={2} />
  </svg>
);
export default SvgBox;
