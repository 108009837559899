import {
  AccessPointRequestDto,
  useLazySecurityUserAccessPointControllerGetAccessPointByIdsQuery,
} from '@shared/services/apiService/apiService_integration';
import {
  AttendanceLogResponseDto,
  SecurityUserOfficeResponseDto,
  SecurityUserVisitorResponseDto,
  useLazySecurityUsersV2ControllerSearchVisitorsQuery,
} from '@shared/services/apiService/apiService_base';
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_LIMIT_ITEMS_ON_PAGE,
  DEFAULT_NULL_MILLISECONDS,
  DEFAULT_POLLING_INTERVAL_MILLISECONDS,
} from '@shared/constants/pagination';
import {
  NotificationLogResponseDto,
  useSecurityUserControllerGetNotificationsQuery,
} from '@shared/services/apiService/apiService_log';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { SuspiciousActivityTabEnum } from '@shared/store/types';
import { useEffect } from 'react';
import { useLocalStorage } from '@shared/hooks';

type GetNotificationsProps = {
  page: number;
  limit?: number,
  suspiciousActivityTab: SuspiciousActivityTabEnum;
};

type NotificationsProps = {
  notification?: NotificationLogResponseDto;
  attendanceLog?: AttendanceLogResponseDto;
  visitorInfo?: SecurityUserVisitorResponseDto;
  accessPointInfo?: AccessPointRequestDto;
};

export const useGetNotifications = ({ page, limit, suspiciousActivityTab }: GetNotificationsProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const isFirstPage = page === DEFAULT_INITIAL_PAGE;
  const pollingInterval = isFirstPage ? DEFAULT_POLLING_INTERVAL_MILLISECONDS : DEFAULT_NULL_MILLISECONDS;

  const officeId = activeOffice && activeOffice.id;

  const [triggerGetVisitorsInfo, { data: visitorsInfo, isLoading: isVisitorsInfoLoading }] =
    useLazySecurityUsersV2ControllerSearchVisitorsQuery();

  const [triggerGetAccessPoint, { data: accessPointInfo, isLoading: isAccessPointLoading }] =
    useLazySecurityUserAccessPointControllerGetAccessPointByIdsQuery();

  const {
    data: dataGetNotifications,
    isLoading: isLoadingGetNotifications,
    refetch,
  } = useSecurityUserControllerGetNotificationsQuery(
    {
      officeId: officeId!,
      suspiciousActivityTab: suspiciousActivityTab,
      page: page,
      limit: limit ? limit : DEFAULT_LIMIT_ITEMS_ON_PAGE,
    },
    { refetchOnMountOrArgChange: true, pollingInterval, skip: !officeId },
  );

  useEffect(() => {
    const visitorsIds = dataGetNotifications?.data.map(visitor => visitor.visitor_id);
    const accessPointIds = dataGetNotifications?.data.map(visitor => visitor.access_point_id);

    visitorsIds && triggerGetVisitorsInfo({ ids: [...new Set(visitorsIds)] });
    accessPointIds && triggerGetAccessPoint({ ids: [...new Set(accessPointIds)] });
  }, [dataGetNotifications]);

  const notifications: NotificationsProps[] | undefined =
    dataGetNotifications?.data &&
    dataGetNotifications?.data.map(notification => {
      const visitorInfoByLogId = visitorsInfo?.find(visitor => visitor.id === notification.visitor_id);
      const accessPointInfoByLogId = accessPointInfo?.find(
        accessPoint => accessPoint.id === notification.access_point_id,
      );

      return {
        notification: notification ? notification : undefined,
        visitorInfo: visitorInfoByLogId ? visitorInfoByLogId : undefined,
        accessPointInfo: accessPointInfoByLogId ? accessPointInfoByLogId : undefined,
      };
    });

  const pageCount = dataGetNotifications?.meta.pageCount;
  const isLoading = isLoadingGetNotifications || isVisitorsInfoLoading || isAccessPointLoading;
  const isEmpty = notifications?.length === 0 && !isLoading;
  const hasPagination = dataGetNotifications?.meta && dataGetNotifications?.meta.pageCount > 1;

  return { notifications, isLoading, pageCount, isEmpty, hasPagination, refetch };
};
