import { FC, ReactNode } from 'react';
import { NavLink, NavLinkVariant } from '@shared/ui';

import styles from './layoutHeader.module.scss';

export interface TabProps {
  title: string;
  to?: string;
  isTabActive?: boolean;
  parent?: string;
  variantNavigation?: NavLinkVariant;
  onNavigation?: () => void;
  hasCounter?: boolean;
}

export type LayoutHeaderProps = {
  title: string;
  tabs: TabProps[];
  rightAddons?: ReactNode;
};

export const LayoutHeader: FC<LayoutHeaderProps> = ({ title, tabs, rightAddons }) => {
  if (!tabs) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.nav}>
          {tabs.map(
            ({ title, parent, isTabActive = false, variantNavigation = NavLinkVariant.ROUTER, onNavigation, to }) => (
              <NavLink
                key={title}
                to={to || ''}
                parent={parent}
                variant={variantNavigation}
                onNavigation={onNavigation}
                isTabActive={isTabActive}>
                {title}
              </NavLink>
            ),
          )}
        </div>
      </div>
      {rightAddons}
    </div>
  );
};
