import { FC, useRef } from 'react';
import { SearchFormFieldsProps, SearchFormProps } from './types';
import { useFormAutoSubmit, useOutsideClick, useToggle } from '@shared/hooks';

import { InputFieldWithPopover } from '@shared/ui';
import { Search } from '@shared/assets/icons/components';
import styles from './searchForm.module.scss';
import { useForm } from 'react-hook-form';

export const SearchForm: FC<SearchFormProps> = ({ defaultSearchValue = '', childrenResults, onSearch }) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const { control, watch, handleSubmit, setValue } = useForm<SearchFormFieldsProps>({
    defaultValues: {
      searchValue: defaultSearchValue,
    },
  });

  const onSubmit = handleSubmit(data => {
    const { searchValue } = data;
    onSearch(searchValue);
  });

  const handleResetSearchForm = () => {
    setValue('searchValue', '');
  };

  const { state: isVisiblePopover, setTrue: openPopover, setFalse: closePopover } = useToggle();

  useOutsideClick(inputRef, undefined, closePopover);
  useFormAutoSubmit(watch, onSubmit);

  return (
    <div className={styles.root} ref={inputRef} onClick={openPopover}>
      <form onSubmit={onSubmit}>
        <InputFieldWithPopover
          size="small"
          hasClear
          inputClassName={styles.input}
          name="searchValue"
          placeholder="Поиск"
          type="text"
          control={control}
          leftIcon={<Search width={24} height={24} />}
          onClear={handleResetSearchForm}
          isPopoverVisible={isVisiblePopover}
          childrenPopover={childrenResults}
        />
      </form>
    </div>
  );
};
