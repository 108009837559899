import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationAttention20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <g fill="#FAC031" fillRule="evenodd" clipPath="url(#NotificationAttention20_svg__a)" clipRule="evenodd">
      <path
        fillOpacity={0.25}
        d="M.772 7.693c.216-2.906.324-4.36 1.783-5.819C4.014.416 5.467.307 8.375.091a31.23 31.23 0 0 1 2.307-.09c.777 0 1.555.034 2.308.09 2.907.216 4.36.325 5.82 1.783 1.458 1.46 1.566 2.913 1.783 5.82.056.753.09 1.53.09 2.307 0 .778-.034 1.555-.09 2.309-.217 2.906-.325 4.36-1.784 5.818-1.459 1.46-2.912 1.567-5.819 1.784-.753.056-1.53.09-2.308.09-.777 0-1.554-.034-2.308-.09-2.907-.217-4.36-.325-5.819-1.784C1.096 16.67.988 15.216.772 12.31c-.056-.753-.09-1.53-.09-2.308 0-.777.034-1.554.09-2.308Z"
      />
      <path d="M10.685 11.34c.553 0 1-.473 1-1.057V6.057c0-.584-.447-1.057-1-1.057-.553 0-1.001.473-1.001 1.057v4.226c0 .584.448 1.057 1 1.057Zm1.249 3.173c0-.73-.56-1.32-1.25-1.32s-1.25.59-1.25 1.32c0 .73.56 1.32 1.25 1.32s1.25-.59 1.25-1.32Z" />
    </g>
    <defs>
      <clipPath id="NotificationAttention20_svg__a">
        <path fill="#fff" d="M.684 0h20v20h-20z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotificationAttention20;
