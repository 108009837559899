import { FC, TdHTMLAttributes, useContext } from 'react';

import { TableContext } from '../table-context';
import classnames from 'classnames';
import styles from './index.module.scss';

export type TCellViolationProps = TdHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  index?: number;
  isViolation?: boolean | null;
};

export const TCellViolation: FC<TCellViolationProps> = ({ className, isViolation, style, index, ...props }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const classesCell = classnames(styles.component, className);
  const classesViolation = classnames(styles.violation, { [styles.violationActive]: isViolation });

  const hasViolationProps = isViolation !== undefined && isViolation !== null;

  return (
    <td className={classesCell} style={{ ...style, width }} {...props}>
      {hasViolationProps && <span className={classesViolation}>{isViolation ? 'Да' : 'Нет'}</span>}
    </td>
  );
};
