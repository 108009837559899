interface ExtendedContextProps extends CanvasRenderingContext2D {
  transformedPoint(x: number, y: number): DOMPoint;
  untransformedPoint(x: number, y: number): DOMPoint;
}

export function useExtendContext(ctx: CanvasRenderingContext2D | null): ExtendedContextProps | null {
  if (!ctx) {
    return ctx;
  }

  const extended = ctx as ExtendedContextProps;

  extended.transformedPoint = (x: number, y: number) => {
    const pt = new DOMPoint(x, y);
    const transform = extended.getTransform();

    return pt.matrixTransform(transform.inverse());
  };

  extended.untransformedPoint = (x: number, y: number) => {
    const pt = new DOMPoint(x, y);
    const transform = extended.getTransform();

    return pt.matrixTransform(transform);
  };

  return extended;
}
