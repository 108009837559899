import { ButtonProps } from './types';
import { SpinnerIcon } from '@shared/assets/icons/components';
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './button.module.scss';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      leftIcon,
      variant = 'secondary',
      size = 'small',
      className,
      isLoading = false,
      disabled,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const classesButton = classNames(
      styles.button,
      styles[variant],
      styles[size],
      {
        [styles.loading]: isLoading,
        [styles.disabled]: disabled,
      },
      className,
    );

    const classesIcon = classNames(styles.leftIcon, { [styles.leftIcon_disabled]: disabled });

    return (
      <button className={classesButton} type={type} disabled={disabled} ref={ref} {...props}>
        {isLoading ? (
          <SpinnerIcon className={styles.spinner} />
        ) : (
          <>
            {leftIcon && <span className={classesIcon}>{leftIcon}</span>}
            {children}
          </>
        )}
      </button>
    );
  },
);
