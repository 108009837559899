import { useRef, useState } from 'react';

import { OnProgressProps } from 'react-player/base';
import ReactHlsPlayer from 'react-hls-player';
import styles from './test.module.scss';

export type VideoStateProps = {
  playing: boolean;
  muted: boolean;
  played: number;
  buffer: boolean;
};

export const Player = () => {
  const videoPlayerRef = useRef(null);

  const [videoState, setVideoState] = useState<VideoStateProps>({
    playing: true,
    muted: true,
    played: 0,
    buffer: true,
  });

  const [videoProgressState, setVideoProgressState] = useState<OnProgressProps>();

  const { playing, muted } = videoState;

  const handleProgress = (state: OnProgressProps) => {
    setVideoProgressState(state);
  };

  const [isLive, setLive] = useState(true);
  const [isRecord, setRecord] = useState(false);

  const liveUrl = 'https://live.alien.urbanpredator.tech/hls/5a08073e-b346-4d7d-a2c9-6bb17103fdcf/video.m3u8';
  const liveUrl15 = 'https://live.alien.urbanpredator.tech/hls/test/live.m3u8';
  const recordUrl = 'https://live-par-2-abr.livepush.io/vod/bigbuckbunnyclip.mp4';
  const sourseUrl = isRecord ? recordUrl : recordUrl;

  return (
    <div className={styles.root}>
      <ReactHlsPlayer
        playerRef={videoPlayerRef}
        src={liveUrl}
        autoPlay={true}
        muted={true}
        hlsConfig={{
          autoStartLoad: true,
          maxLoadingDelay: 4,
          minAutoBitrate: 0,
          lowLatencyMode: true,
          liveSyncDurationCount: 3,
          startFragPrefetch: true,
        }}
        controls={true}
      />
    </div>
  );
};
