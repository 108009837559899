import { JornalSortFieldsEnum, JornalTabFilterEnum, SortOrderEnum } from '@shared/store/types';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthPath } from '@shared/constants/routes';
import { DEFAULT_INITIAL_PAGE } from '@shared/constants/pagination';
import { MIN_LENGHT_SEARCH_QUERY_VISITORS } from './useSearchVisitors';

enum JournalSearchParamsEnum {
  PAGE = 'page',
  SEARCH = 'search',
}

const DEFAULT_JOURNAL_SEARCH = '';

export const useSearchParamsJournal = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultPage =
    searchParams.get(JournalSearchParamsEnum.PAGE) !== null
      ? searchParams.get(JournalSearchParamsEnum.PAGE)
      : DEFAULT_INITIAL_PAGE;

  const defaultSearch =
    searchParams.get(JournalSearchParamsEnum.SEARCH) !== null
      ? (searchParams.get(JournalSearchParamsEnum.SEARCH) as JornalSortFieldsEnum)
      : DEFAULT_JOURNAL_SEARCH;

  const [page, setPage] = useState<number>(Number(defaultPage));
  const [search, setSearch] = useState<string>(defaultSearch);

  const hasSearchQuery = search.length >= MIN_LENGHT_SEARCH_QUERY_VISITORS;

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    if (hasSearchQuery) {
      setPage(DEFAULT_INITIAL_PAGE);
      setSearch(search);
      setSearchParams({ page: page as unknown as string, search });
    }
  }, [search]);

  useEffect(() => {
    const link = `${AuthPath.JOURNAL}/${JornalTabFilterEnum.ALL}?${JournalSearchParamsEnum.PAGE}=${page}&${JournalSearchParamsEnum.SEARCH}=${search}`;
    navigate(link, { replace: true });
  }, [page, search]);

  return {
    page,
    search,
    setPage,
    handleSearch,
  };
};
