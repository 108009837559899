import * as React from 'react';

import type { SVGProps } from 'react';
const SvgAttentionPlayer = (props: SVGProps<SVGSVGElement>) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.725 9.65051L4.54999 45.0005C4.11342 45.7566 3.88241 46.6138 3.87997 47.4868C3.87752 48.3599 4.10372 49.2183 4.53606 49.9768C4.9684 50.7353 5.59181 51.3674 6.34426 51.8101C7.09672 52.2529 7.95199 52.4909 8.82499 52.5005H51.175C52.048 52.4909 52.9033 52.2529 53.6557 51.8101C54.4082 51.3674 55.0316 50.7353 55.4639 49.9768C55.8963 49.2183 56.1225 48.3599 56.12 47.4868C56.1176 46.6138 55.8866 45.7566 55.45 45.0005L34.275 9.65051C33.8293 8.91578 33.2018 8.30831 32.453 7.88672C31.7042 7.46513 30.8593 7.24365 30 7.24365C29.1407 7.24365 28.2958 7.46513 27.547 7.88672C26.7982 8.30831 26.1707 8.91578 25.725 9.65051Z"
      stroke="#717680"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M30 22.5V32.5" stroke="#717680" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30 42.5005H30.0281" stroke="#717680" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgAttentionPlayer;
