import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { Input } from '.';
import { InputProps } from './types';

export function InputField<T extends FieldValues>(props: UseControllerProps<T> & InputProps) {
  const { name, control } = props;
  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name,
    control,
  });

  return <Input {...props} {...field} error={error} />;
}
