import { IconButtonProps } from './types';
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './iconButton.module.scss';
import { useClientSize } from '@shared/hooks';

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ variant = 'primary', segment = 'default', className, children, size = 32, ...props }, ref) => {
    const { getIsBreakpoint } = useClientSize();
    const isDesktopUltraHd = getIsBreakpoint('desktopUltraHD');

    const classesButton = classNames(styles.root, styles[variant], styles[segment], className);

    return (
      <button className={classesButton} ref={ref} {...props} style={{ width: isDesktopUltraHd ? 44 : size, height: isDesktopUltraHd ? 44 : size }}>
        {children}
      </button>
    );
  },
);
