import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenu20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#fff"
      d="M10 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM10 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM10 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
);
export default SvgMenu20;
