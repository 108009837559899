export enum BreakpointsEnum {
  desktopUltraHD = 2560,
  desktopFullHD = 1920,
  desktopL = 1600,
  desktop = 1440,
  laptop = 1280,
  tablet = 1024,
  mobile = 768
}

export type BreakpointsType = keyof typeof BreakpointsEnum;
