import { ArrowLeft, ArrowRight } from '@shared/assets/icons/components';

import { FC } from 'react';
import { PaginatorProps } from './types';
import ReactPaginate from 'react-paginate';
import styles from './paginator.module.scss';

export const Paginator: FC<PaginatorProps> = ({ initialPage, pageCount, onPageChange }) => {
  const forcePage = initialPage ? initialPage - 1 : undefined;

  if (!pageCount || !onPageChange) {
    return null;
  }

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<ArrowRight height={16} width={16} />}
      previousLabel={<ArrowLeft height={16} width={16} />}
      forcePage={forcePage}
      onPageChange={({ selected }) => {
        const currentPage = selected + 1;
        onPageChange(currentPage);
      }}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      className={styles.root}
      activeLinkClassName={styles.activeLinkClassName}
      breakLinkClassName={styles.breakLinkClassName}
      pageClassName={styles.pageClassName}
      pageLinkClassName={styles.pageLinkClassName}
      previousClassName={styles.previousClassName}
      nextClassName={styles.nextClassName}
    />
  );
};
