import {
  SecurityUserOfficeResponseDto,
  useSecurityUsersAreasV2ControllerCreateMutation,
} from '@shared/services/apiService/apiService_base';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { schemaResolverUploadLocation } from '../constants';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from '@shared/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

type CreateAreaFormValue = {
  name: string;
  plan: string;
};

export const useCreateArea = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const formMethods = useForm<CreateAreaFormValue>({
    resolver: yupResolver(schemaResolverUploadLocation),
    mode: 'onChange',
    defaultValues: {
      name: '',
      plan: '',
    },
  });

  const officeId = activeOffice && activeOffice.id;

  const [createArea, { isSuccess, isLoading }] = useSecurityUsersAreasV2ControllerCreateMutation();

  const onSubmit = async ({ name, plan }: CreateAreaFormValue) => {
    if (!officeId) {
      return;
    }

    await createArea({
      securityUserCreateAreaDto: {
        name: name,
        planFileKey: plan,
        officeId: officeId,
        zero: {
          x: 0,
          y: 0,
        },
        scale: {
          x: 0,
          y: 0,
        },
        isEntranceLocation: true,
      },
    });
  };

  return {
    isSuccess,
    isLoading,
    formMethods,
    onSubmit,
  };
};
