import React from 'react';

export type ColumnConfiguration = {
  width?: number;
  index: number;
};

export type TableContextType = {
  columnsConfiguration: ColumnConfiguration[];
};

export const DEFAULT_TABLE_CONTEXT: TableContextType = {
  columnsConfiguration: [],
};

export const TableContext = React.createContext<TableContextType>(DEFAULT_TABLE_CONTEXT);
