import {
  SecurityUserOfficeResponseDto,
  useSecurityUsersV2ControllerGetMeQuery,
} from '@shared/services/apiService/apiService_base';
import { StorageKeys, useLocalStorage } from '@shared/hooks/useLocalStorage';

export const useChangeOffice = () => {
  const [activeOffice, setOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(
    StorageKeys.UP_OFFICE,
    undefined,
  );

  const { data: getMe } = useSecurityUsersV2ControllerGetMeQuery();
  const offices = getMe?.offices || [];
  const hasOffices = offices.length > 0;

  const handleSelectOffice = (office: SecurityUserOfficeResponseDto) => {
    setOffice(office);
  };

  return {
    activeOffice,
    offices,
    hasOffices,
    handleSelectOffice,
  };
};
