import { Button, Spinner } from '@shared/ui';
import { Close, EmptyWallList } from '@shared/assets/icons/components';
import { MainLayout, SidebarLayout } from '@shared/layouts';
import { VideoWallActions, VideoWallList } from '@pages/VideoWalls/ui';
import { useGetVideoWallGrid, useVideoWall } from '@pages/VideoWalls/hooks';
import { useTitle, useToggle } from '@shared/hooks';

import { Box } from '@shared/assets/icons/components';
import { MAX_GRID_CELL } from '@pages/VideoWalls/hooks/useGetVideoWallGrid';
import { Sidebar } from '@shared/widgets';
import { Stream } from '@entities/Stream';
import { Titles } from '@shared/constants/titles';
import { UpdateVideoWall } from '@features/UpdateVideoWall';
import { UpdateVideoWallStreams } from '@features/UpdateVideoWallStreams';
import classNames from 'classnames';
import styles from './videoWall.module.scss';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useUpdateVideoWallStreams } from '@features/UpdateVideoWallStreams/hooks';
import { v4 as uuidv4 } from 'uuid';

export const VideoWall = () => {
  useTitle(Titles.VideoWalls);

  const [swapStreamId, setSwapStreamId] = useState<string | undefined>();

  const { videoWallId } = useParams();

  const { state: isUpdateVideoWallModalVisible, toggle: toggleUpdateVideoWallModal } = useToggle();
  const {
    state: isUpdateVideoWallCameraModalVisible,
    toggle: toggleUpdateVideoWallCameraModal,
    setFalse: closeUpdateVideoWallCameraModal,
  } = useToggle();

  const {
    videoWallData,
    camerasListData,
    videoWallsList,
    isLoadingVideoWallData,
    handleSelectVideoWall,
    refetchVideoWallsList,
  } = useVideoWall();

  const handleSelectSwapStream = (id: string) => {
    id ? setSwapStreamId(id) : setSwapStreamId(undefined);
    toggleUpdateVideoWallCameraModal();
  };

  const { state: isSidebarModalOpen, toggle: toggleSidabarModal } = useToggle();

  const videoWallGrid = useGetVideoWallGrid(camerasListData?.length);

  const hasStockVideoWall = !videoWallData?.securityUserId;
  const hasMaxLenghtVideoWall = camerasListData?.length === MAX_GRID_CELL;
  const hasLastCameraVideoWall = camerasListData?.length === 1;
  const isUpdateVideoWallCameraDisable = hasStockVideoWall || hasMaxLenghtVideoWall;
  const isVideoWallLoading = isLoadingVideoWallData || !videoWallId;
  const isEmptyWall = videoWallData === undefined;

  const classesRoot = classNames(styles.root, styles[videoWallGrid.gridType]);

  const handleCloseUpdateVideoWallStreamsModal = () => {
    setSwapStreamId(undefined);
    closeUpdateVideoWallCameraModal();
  };

  const { isSuccessUpdateVideoWall, handleDeleteStream, handleUpdateVideoWallStreams } =
    useUpdateVideoWallStreams(swapStreamId);

  return (
    <>
      <SidebarLayout
        variant="secondary"
        toggleSidabarModal={toggleSidabarModal}
        isSidebarModalOpen={isSidebarModalOpen}
        sidebar={
          <Sidebar
            headerTitle={videoWallData?.name}
            headerSubtitle={videoWallData?.description}
            headerAction={toggleUpdateVideoWallModal}
            hasHeaderActionNotAllowed={hasStockVideoWall}
            contentList={
              <VideoWallList
                videoWallList={videoWallsList}
                cameras={camerasListData}
                onSelectVideoWall={handleSelectVideoWall}
              />
            }
            contentActions={
              <VideoWallActions
                isEmptyWall={isEmptyWall}
                isUpdateVideoWallCameraDisable={isUpdateVideoWallCameraDisable}
                refetchVideoWallsList={refetchVideoWallsList}
              />
            }
          />
        }>
        {isVideoWallLoading ? (
          <div className={styles.container}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={classesRoot}>
              {camerasListData?.map((stream, index) => (
                <Stream
                  index={index}
                  key={stream.id}
                  stream={stream}
                  hasStockVideoWall={hasStockVideoWall || hasLastCameraVideoWall}
                  onSelectSwapStream={handleSelectSwapStream}
                  onDeleteStream={handleDeleteStream}
                />
              ))}
              {[...Array(videoWallGrid.gridEmptyCellCount)].map(() => (
                <div
                  key={uuidv4()}
                  className={classNames(styles.card, { [styles.card_disabled]: isUpdateVideoWallCameraDisable })}
                  onClick={isUpdateVideoWallCameraDisable ? () => {} : toggleUpdateVideoWallCameraModal}>
                  <Close />
                </div>
              ))}
            </div>
          </>
        )}

        {videoWallsList?.length === 0 && (
          <MainLayout>
            <div className={styles.select}>
              <div className={styles.content}>
                <EmptyWallList style={{ zoom: 0.6 }} />
                <p className={styles.text}>Видеостен пока нет. Создайте первую видеостену и приступайте к настройке</p>
                <VideoWallActions
                  isEmptyWall={isEmptyWall}
                  isUpdateVideoWallCameraDisable={isUpdateVideoWallCameraDisable}
                  refetchVideoWallsList={refetchVideoWallsList}
                />
              </div>
            </div>
          </MainLayout>
        )}
      </SidebarLayout>
      <UpdateVideoWall
        name={videoWallData?.name}
        description={videoWallData?.description}
        isModalVisible={isUpdateVideoWallModalVisible}
        closeCallback={toggleUpdateVideoWallModal}
        onSuccess={refetchVideoWallsList}
      />
      <UpdateVideoWallStreams
        isModalVisible={isUpdateVideoWallCameraModalVisible}
        closeCallback={handleCloseUpdateVideoWallStreamsModal}
        isSuccess={isSuccessUpdateVideoWall}
        onSelectStream={handleUpdateVideoWallStreams}
      />
    </>
  );
};
