import * as React from 'react';
function SvgDangerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3339_141111)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.105879 9.23178C0.365483 5.74365 0.495285 3.99959 2.24585 2.24902C3.99642 0.498456 5.74049 0.368654 9.22861 0.109051C10.1328 0.0417557 11.0656 0.000976562 11.9983 0.000976562C12.931 0.000976562 13.8638 0.0417564 14.768 0.109052C18.2561 0.368657 20.0002 0.498459 21.7507 2.24903C23.5013 3.9996 23.6311 5.74366 23.8907 9.23179C23.958 10.136 23.9988 11.0687 23.9988 12.0015C23.9988 12.9342 23.958 13.8669 23.8907 14.7712C23.6311 18.2593 23.5013 20.0033 21.7507 21.7539C20.0002 23.5045 18.2561 23.6343 14.768 23.8939C13.8638 23.9612 12.931 24.002 11.9983 24.002C11.0656 24.002 10.1328 23.9612 9.22861 23.8939C5.74048 23.6343 3.99642 23.5045 2.24585 21.7539C0.495282 20.0033 0.36548 18.2593 0.105877 14.7711C0.0385818 13.8669 -0.00219727 12.9342 -0.00219727 12.0015C-0.00219727 11.0687 0.0385825 10.136 0.105879 9.23178Z" fill="#FF0014" fillOpacity="0.25"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0014 13.6085C12.6647 13.6085 13.2025 13.0405 13.2025 12.3399L13.2024 7.26859C13.2024 6.56797 12.6647 6 12.0013 6C11.338 6 10.8003 6.56797 10.8003 7.2686L10.8003 12.3399C10.8003 13.0405 11.338 13.6085 12.0014 13.6085ZM13.5 17.4157C13.5 16.5407 12.8284 15.8314 12 15.8314C11.1716 15.8314 10.5 16.5407 10.5 17.4157C10.5 18.2907 11.1716 19 12 19C12.8284 19 13.5 18.2907 13.5 17.4157Z" fill="#FF0014"/>
      </g>
      <defs>
        <clipPath id="clip0_3339_141111">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgDangerIcon;
