import { ColumnConfiguration, TableContext } from '../table-context';
import { ReactElement, TableHTMLAttributes, forwardRef, useMemo } from 'react';

import { Paginator } from '@shared/ui';
import { PaginatorProps } from '@shared/ui/Paginator/types';
import classnames from 'classnames';
import { findAllHeadCellsProps } from './utils';
import styles from './index.module.scss';

export type TableProps = TableHTMLAttributes<HTMLTableElement> & {
  className?: string;
  children: ReactElement | ReactElement[];
  hasPaginator?: boolean;
  paginator?: PaginatorProps;
  hasTopBorder?: boolean;
};

export const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, children, hasPaginator, paginator, hasTopBorder = true, ...props }, ref) => {
    const columnsConfiguration: ColumnConfiguration[] = useMemo(
      () =>
        findAllHeadCellsProps(children).map((columnProps, index) => ({
          width: columnProps.width,
          index,
        })),
      [children],
    );

    const classesTable = classnames(styles.component, className, { [styles.hasTopBorder]: hasTopBorder });
    const paginatorVariant = paginator?.backgroundVariant ? paginator?.backgroundVariant : 'primary';
    const classesPaginator = classnames(styles.paginator, styles[paginatorVariant], paginator?.classNameTablePaginator);

    return (
      <TableContext.Provider
        value={{
          columnsConfiguration,
        }}>
        <div className={classesTable}>
          <table ref={ref} className={styles.table} {...props}>
            {children}
          </table>
        </div>
        {hasPaginator && (
          <div className={classesPaginator}>
            <Paginator
              initialPage={paginator?.initialPage}
              pageCount={paginator?.pageCount}
              onPageChange={paginator?.onPageChange}
            />
          </div>
        )}
      </TableContext.Provider>
    );
  },
);
