import { NavLinkProps, NavLinkVariant } from './types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styles from './navLink.module.scss';

export const NavLink = ({
  isTabActive,
  variant = NavLinkVariant.ROUTER,
  className,
  children,
  parent,
  onNavigation,
  hasCounter = false,
  countValue,
  ...props
}: NavLinkProps) => {
  const location = useLocation();

  const hasParantActiveLink = parent && location?.pathname.includes(parent);
  const isVideoWallLink = parent === '/video-walls';

  if (variant === NavLinkVariant.TAB_NAVIGATION) {
    return (
      <div className={classNames(styles.root, className, { [styles.active]: isTabActive })} onClick={onNavigation}>
        <span className={styles.linkText}>{children}</span>
        {hasCounter && (
          <div className={styles.containerCounter}>
            <span className={styles.counter}>{countValue}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <RouterLink
      reloadDocument={isVideoWallLink ? true : false}
      className={({ isActive }) =>
        classNames(styles.root, className, {
          [styles.active]: isActive || hasParantActiveLink,
        })
      }
      {...props}>
      <span className={styles.linkText}>{children}</span>
    </RouterLink>
  );
};
