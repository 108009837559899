import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSearchClear = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 5a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm3.207 3.792a1 1 0 0 1 0 1.415L13.414 12l1.793 1.793a1 1 0 0 1-1.414 1.414L12 13.414l-1.793 1.793a1 1 0 0 1-1.414-1.415L10.586 12l-1.793-1.793a1 1 0 0 1 1.414-1.414L12 10.586l1.793-1.794a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSearchClear;
