import * as React from 'react';
import { SVGProps } from 'react';
const SvgSortDesc = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22 1.976a1 1 0 0 1 1.56 0l1.92 2.4A1 1 0 0 1 9.92 6H6.08a1 1 0 0 1-.78-1.625l1.92-2.399Z"
      fill="#717680"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78 14.024a1 1 0 0 1-1.56 0l-1.92-2.4A1 1 0 0 1 6.08 10h3.84a1 1 0 0 1 .78 1.625l-1.92 2.399Z"
      fill="#fff"
    />
  </svg>
);
export default SvgSortDesc;
