import * as React from 'react';
function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#Camera_svg__clip0_2738_4041)" stroke="#fff" strokeWidth={1.5} strokeLinecap="round">
        <rect x={0.667} y={3.333} width={10} height={9.333} rx={2.667} />
        <path d="M15.333 4.667L10.667 8l4.666 3.333V4.666z" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="Camera_svg__clip0_2738_4041">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgCamera;
