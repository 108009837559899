import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { InputFieldWithPopoverProps, InputProps } from './types';

import { Input } from '.';
import { Popover } from '../Popover';
import { useRef } from 'react';

export function InputFieldWithPopover<T extends FieldValues>(
  props: UseControllerProps<T> & InputProps & InputFieldWithPopoverProps,
) {
  const fieldRef = useRef<HTMLInputElement>(null);

  const { name, control, isPopoverVisible, childrenPopover, ...inputProps } = props;

  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name,
    control,
  });

  return (
    <>
      <Input {...inputProps} {...field} error={error} ref={fieldRef} />
      <Popover isVisible={isPopoverVisible!} anchorElement={fieldRef.current as HTMLElement}>
        {childrenPopover}
      </Popover>
    </>
  );
}
