import { FC, ReactNode, TdHTMLAttributes, useContext } from 'react';

import { ConditionalWrapper } from '@shared/ui/ConditionalWrapper';
import { TableContext } from '../table-context';
import { Tooltip } from '@shared/ui/Tooltip';
import classnames from 'classnames';
import styles from './index.module.scss';
import { v4 as uuidv4 } from 'uuid';

export type TCellTooltipProps = TdHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  index?: number;
  maxStringChildrenLenght: number;
  children: ReactNode;
};

export const TCellTooltip: FC<TCellTooltipProps> = ({
  className,
  style,
  maxStringChildrenLenght,
  index,
  children,
  ...props
}) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const classesCell = classnames(styles.component, className);

  if (!children) {
    return null;
  }

  const childrenText = children.toString();
  const isLongChildren = childrenText.length > maxStringChildrenLenght;
  const substringChildren = childrenText.substring(0, maxStringChildrenLenght) + '...';

  return (
    <td className={classesCell} style={{ ...style, width }} {...props}>
      <ConditionalWrapper
        condition={isLongChildren}
        wrapper={children => {
          return (
            <Tooltip id={uuidv4()} content={childrenText}>
              {children}
            </Tooltip>
          );
        }}>
        <p className={styles.children}>{isLongChildren ? substringChildren : childrenText}</p>
      </ConditionalWrapper>
    </td>
  );
};
