import { useParams } from 'react-router-dom';
import { useSecurityUsersAreasV2ControllerGetAreaInfoQuery } from '@shared/services/apiService/apiService_base';

export const useGetAreaInfoById = () => {
  const { areaId } = useParams();

  const { data: areaInfo, isLoading: isGetAreaInfoLoading } = useSecurityUsersAreasV2ControllerGetAreaInfoQuery(
    {
      areaId: areaId!,
    },
    { skip: !areaId },
  );

  return {
    areaInfo,
    isGetAreaInfoLoading,
  };
};
