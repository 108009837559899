import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthPath } from '@shared/constants/routes';
import { DEFAULT_INITIAL_PAGE } from '@shared/constants/pagination';
import { NotificationTabEnum } from '../constants';
import { SortOrderEnum } from '@shared/store/types';

enum NotificationsSearchParamsEnum {
  PAGE = 'page',
}

type NotificationsParamsProps = {
  tab: NotificationTabEnum;
};

export const useSearchParamsNotifications = ({ tab }: NotificationsParamsProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const defaultPage =
    searchParams.get(NotificationsSearchParamsEnum.PAGE) !== null
      ? searchParams.get(NotificationsSearchParamsEnum.PAGE)
      : DEFAULT_INITIAL_PAGE;

  const [page, setPage] = useState<number>(Number(defaultPage));

  useEffect(() => {
    const link = `${AuthPath.NOTIFICATIONS}/${tab}?${NotificationsSearchParamsEnum.PAGE}=${page}`;
    navigate(link, { replace: true });
  }, [page]);

  return { page, setPage };
};
