import { Button, Spinner } from '@shared/ui';
import { DashboardJournal, OfficeMetrics } from './ui';
import { useGetTargetCamera, useUpdateTargetDashboardCamera } from '@features/UpdateVideoWallStreams/hooks';
import { useTitle, useToggle } from '@shared/hooks';

import { Box } from '@shared/assets/icons/components';
import { MainLayout } from '@shared/layouts';
import { NotificationsSidebar } from './ui/NotificationsSidebar';
import { Stream } from '@entities/Stream';
import { Titles } from '@shared/constants/titles';
import { UpdateVideoWallStreams } from '@features/UpdateVideoWallStreams';
import styles from './dashboard.module.scss';
import { useEffect } from 'react';

export const Dashboard = () => {
  useTitle(Titles.Dashboard);

  const { isSuccessDeleteTargetCamera, handleDeleteCamera, isSuccessUpdateTargetCamera, handleUpdateCamera } =
    useUpdateTargetDashboardCamera();

  const { targetCamera, isLoadingtargetCamera, refetchTargetCamera } = useGetTargetCamera();

  const hasStream = targetCamera !== null;

  const { state: isTargetCameraModalOpen, toggle: toggleTargetCameraModal } = useToggle();

  useEffect(() => {
    if (isSuccessDeleteTargetCamera || isSuccessUpdateTargetCamera) {
      refetchTargetCamera();
    }
  }, [isSuccessDeleteTargetCamera, isSuccessUpdateTargetCamera]);

  return (
    <MainLayout variantDashboard="secondaryDashboard">
      <div className={styles.root}>
        <div className={styles.dashboard}>
          <div className={styles.office}>
            <OfficeMetrics />
            {isLoadingtargetCamera || targetCamera === undefined ? (
              <div className={styles.loader}>
                <Spinner />
              </div>
            ) : (
              <>
                {hasStream && targetCamera ? (
                  <Stream
                    className={styles.stream}
                    stream={targetCamera}
                    hasStockVideoWall={false}
                    hasFooter={false}
                    onSelectSwapStream={toggleTargetCameraModal}
                    onDeleteStream={handleDeleteCamera}
                  />
                ) : (
                  <div className={styles.select}>
                    <div className={styles.content}>
                      <Box style={{ zoom: 0.6 }} />
                      <p className={styles.text}>
                        Выберите наиболее важную камеру для быстрого доступа и непрерывного наблюдения. Её можно
                        поменять в любой момент
                      </p>
                      <Button
                        variant="primary"
                        size="small"
                        onClick={toggleTargetCameraModal}
                        className={styles.button}>
                        Выбрать камеру
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.journal}>
            <DashboardJournal />
          </div>
        </div>
        <NotificationsSidebar className={styles.sidebar} />
      </div>
      <UpdateVideoWallStreams
        isModalVisible={isTargetCameraModalOpen}
        closeCallback={toggleTargetCameraModal}
        isSuccess={isSuccessUpdateTargetCamera}
        onSelectStream={handleUpdateCamera}
      />
    </MainLayout>
  );
};
