import { BaseSelect, InputField, Modal, Paginator, Spinner } from '@shared/ui';

import { CameraCard } from '@entities/CameraCard';
import { Search } from '@shared/assets/icons/components';
import styles from './updateVideoWallStreams.module.scss';
import { useEffect } from 'react';
import { useFormAutoSubmit } from '@shared/hooks';
import { useGetCameras } from '../hooks';

type UpdateVideoWallStreamsProps = {
  isModalVisible: boolean;
  isSuccess?: boolean;
  closeCallback: () => void;
  onSelectStream?: (id: string) => void;
};

export const UpdateVideoWallStreams = ({
  isSuccess = false,
  isModalVisible,
  closeCallback,
  onSelectStream,
}: UpdateVideoWallStreamsProps) => {
  const {
    activeArea,
    areasOptions,
    setActiveArea,
    isLoading,
    cameras,
    isCamerasEmpty,
    page,
    setPage,
    pageCount,
    formMethods: { control, reset, watch, handleSubmit, setValue },
    handleSearchCameras,
  } = useGetCameras({ isModalVisible: isModalVisible });

  const handleSelectCamera = (id: string) => {
    onSelectStream && onSelectStream(id);
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      closeCallback();
    }
  }, [isSuccess]);

  useEffect(() => {
    reset();
  }, [isModalVisible]);

  const onSubmit = handleSubmit(data => {
    const { searchValue } = data;
    handleSearchCameras(searchValue);
  });

  useFormAutoSubmit(watch, onSubmit);

  return (
    <>
      <Modal
        isVisible={isModalVisible}
        closeCallback={closeCallback}
        title="Выбор камеры"
        containerVariant="largeContainer">
        <div className={styles.root}>
          <div className={styles.filters}>
            <form onSubmit={onSubmit}>
              {/* <InputField
                control={control}
                className={styles.input}
                placeholder="Введите название камеры"
                name="searchValue"
                type="text"
                leftIcon={<Search />}
              /> */}
            </form>
            <div className={styles.container}>
              <p className={styles.heading}>Локация</p>
              <BaseSelect selected={activeArea} onChange={setActiveArea} options={areasOptions} />
            </div>
          </div>
          <>
            {isLoading || !cameras ? (
              <div className={styles.contentEmpty}>
                <Spinner />
              </div>
            ) : (
              <>
                {isCamerasEmpty ? (
                  <div className={styles.contentEmpty}>
                    <p className={styles.empty}>Нет камер в выбранной локации</p>
                  </div>
                ) : (
                  <div className={styles.content}>
                    {cameras.map(camera => (
                      <CameraCard
                        id={camera.id}
                        key={camera.id}
                        name={camera.name}
                        imgUrl={camera.previewImageUrl}
                        isStatusError={camera.status === 'error' || camera.status === 'off'}
                        onSelect={() => handleSelectCamera(camera.id)}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        </div>
        <div className={styles.paginator}>
          <Paginator initialPage={page} pageCount={pageCount} onPageChange={setPage} />
        </div>
      </Modal>
    </>
  );
};
