export const markerColors = {
  employee_primary: 'rgba(99, 201, 102, 1)',
  guest_primary: 'rgba(255, 223, 52, 1)',
  contractor_primary: 'rgba(196, 70, 199, 1)',
  unidentified_primary: 'rgba(255, 0, 20, 1)',
  security_primary: 'rgba(0, 124, 255, 1)',
  employee_secondary: 'rgba(49, 229, 54, 0.2)',
  guest_secondary: 'rgba(255, 223, 52, 0.2)',
  contractor_secondary: 'rgba(213, 70, 217, 0.2)',
  unidentified_secondary: 'rgba(255, 0, 20, 0.2)',
  security_secondary: 'rgba(0, 124, 255, 0.2)',
};
