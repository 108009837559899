import { Ble20, Ble24, Camera20, Camera24 } from '@shared/assets/icons/components';
import { useClientSize, useLocalStorage } from '@shared/hooks';

import { ChangeOffice } from '@features/ChangeOffice';
import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import styles from './officeMetrics.module.scss';

export const OfficeMetrics = () => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeName = activeOffice && activeOffice?.name;
  const officeLocation = activeOffice && activeOffice?.address;
  const officeBackground = '/mockOfficeBackground.jpg';
  const officeBackgroundUrl = `url(${officeBackground})`;

  const { getIsBreakpoint } = useClientSize();
  const isDesktopFullHD = getIsBreakpoint('desktopFullHD');

  const metrics = [
    {
      icon: (
        <svg
          height={isDesktopFullHD ? 10 : 8}
          width={isDesktopFullHD ? 10 : 8}
          viewBox="0 0 8 8"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.employee}>
          <circle cx="4" cy="4" r="4" />
        </svg>
      ),
      title: 'Сотрудники',
      counter: 185,
      counterMax: 790,
    },
    {
      icon: (
        <svg
          height={isDesktopFullHD ? 10 : 8}
          width={isDesktopFullHD ? 10 : 8}
          viewBox="0 0 8 8"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.guest}>
          <circle cx="4" cy="4" r="4" />
        </svg>
      ),
      title: 'Гости',
      counter: 0,
      counterMax: 790,
    },
    {
      icon: (
        <svg
          height={isDesktopFullHD ? 10 : 8}
          width={isDesktopFullHD ? 10 : 8}
          viewBox="0 0 8 8"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.contractor}>
          <circle cx="4" cy="4" r="4" />
        </svg>
      ),
      title: 'Подрядчики',
      counter: 0,
      counterMax: 790,
    },
    {
      icon: (
        <svg
          height={isDesktopFullHD ? 10 : 8}
          width={isDesktopFullHD ? 10 : 8}
          viewBox="0 0 8 8"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.unrecognized}>
          <circle cx="4" cy="4" r="4" />
        </svg>
      ),
      title: 'Чужие',
      counter: 0,
      counterMax: undefined,
    },
    {
      icon: isDesktopFullHD ? <Camera24 /> : <Camera20 />,
      title: 'Камеры',
      counter: '-',
      counterMax: 790,
    },
    {
      icon: isDesktopFullHD ? <Ble24 /> : <Ble20 />,
      title: 'Маячки',
      counter: '-',
      counterMax: 790,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.office} style={{ backgroundImage: officeBackgroundUrl }}>
        <div>
          <p className={styles.officeName}>{officeName}</p>
          <p className={styles.officeLocation}>{officeLocation}</p>
        </div>
        <div className={styles.button}>
          <ChangeOffice />
        </div>
      </div>
      <div className={styles.metrics}>
        {metrics.map(item => (
          <div className={styles.card} key={item.title}>
            <div className={styles.card__icon}>{item.icon}</div>
            <div>
              <p className={styles.card__title}>{item.title}</p>
              <div className={styles.counterContainer}>
                <p className={styles.counter}>{item.counter}</p>
                {item.counterMax && <p className={styles.counterMax}></p>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
