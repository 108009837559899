import { Coords } from '../../types';
import { FC } from 'react';
import { VisitorRoleEnum } from '@pages/Visitors/types';

export interface MapMarkerProps {
  markerKey: string;
  coords: Coords;
  role: VisitorRoleEnum;
  isActive: boolean;
  isFocused: boolean;
  isAvatar: boolean;
  onClick?: () => void;
}

export const MapMarker: FC<MapMarkerProps> = () => null;
