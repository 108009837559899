import * as React from 'react';
function SvgErrorShape(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.686 1.687C.373 3 .276 4.308.081 6.924.031 7.602 0 8.302 0 9.001c0 .7.03 1.4.081 2.078.195 2.616.292 3.924 1.605 5.236 1.313 1.313 2.62 1.41 5.237 1.605.678.05 1.377.081 2.077.081s1.399-.03 2.077-.08c2.616-.195 3.924-.293 5.237-1.605 1.313-1.313 1.41-2.621 1.605-5.237C17.969 10.4 18 9.7 18 9s-.03-1.399-.081-2.077c-.195-2.616-.292-3.924-1.605-5.237C15.001.375 13.694.277 11.077.083A28.107 28.107 0 009 0c-.7 0-1.399.031-2.077.082-2.616.194-3.924.292-5.237 1.604zm10.521 4.105a1 1 0 010 1.415L10.414 9l1.793 1.793a1 1 0 01-1.414 1.414L9 10.414l-1.793 1.793a1 1 0 01-1.414-1.415L7.586 9 5.793 7.207a1 1 0 111.414-1.414L9 7.585l1.793-1.793a1 1 0 011.414 0z"
        fill="#FA8A64"
      />
    </svg>
  );
}
export default SvgErrorShape;
