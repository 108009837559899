import { BeaconTypeEnum, EntryTypeEnum, PassageTypeEnum } from '@shared/store/types';

import { JoutnalPath } from '@shared/constants/routes';

export const JORNAL_TABS = [
  {
    title: 'Все',
    to: JoutnalPath.ALL,
  },
  {
    title: 'Подозрительные проходы',
    to: JoutnalPath.SUSPICIOUS,
  },
];

export const JOURNAL_PAGE_HEADING = 'Журнал посещений';

export const entryTypeMapping = {
  [EntryTypeEnum.ENTRANCE]: 'Вход',
  [EntryTypeEnum.EXIT]: 'Выход',
};

export const passageTypeMapping = {
  [PassageTypeEnum.DEFAULT]: 'Обычный',
  [PassageTypeEnum.SUSPICIOUS]: 'Подозрительный',
};

export const beaconsTypeMapping = {
  [BeaconTypeEnum.ACTIVE]: 'В работе',
  [BeaconTypeEnum.INACTIVE]: 'Не работает',
};
