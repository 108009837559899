import { IconButton } from '@shared/ui';
import SvgCloseIcon from '@shared/assets/icons/components/CloseIcon';
import SvgDangerIcon from '@shared/assets/icons/components/DangerIcon';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { memo } from 'react';
import plural from 'plural-ru';
import styles from './unrecognizedVisitors.module.scss';

type Props = {
  className?: string;
  dateTime: Date | string;
  description: string;
  unrecognizedVisitorsCount: number;
  onClose: () => void;
};

const UnrecognizedVisitors = memo(({ className, dateTime, description, unrecognizedVisitorsCount, onClose }: Props) => {
  const isTodayPrefix = dayjs(dateTime).isToday() ? 'Сегодня в' : null;
  const isYesterdayPrefix = dayjs(dateTime).isYesterday() ? 'Вчера в' : null;
  const date = isTodayPrefix
    ? `${isTodayPrefix} ${dayjs(dateTime).format('HH:mm')}`
    : isYesterdayPrefix
    ? `${isYesterdayPrefix} ${dayjs(dateTime).format('HH:mm')}`
    : dayjs(dateTime).format('D MMMM HH:mm');

  const visitorsPlural = plural(unrecognizedVisitorsCount, 'посетитель', 'посетителя', 'посетителей');

  return (
    <div className={classNames(styles.root, className)}>
      <header className={styles.header}>
        <SvgDangerIcon width={24} height={24} className={styles.icon} />
        <div className={styles.titleWrap}>
          <p className={styles.title}>
            {unrecognizedVisitorsCount} {visitorsPlural} не распознано
          </p>
          <time className={styles.time}>{date}</time>
        </div>
        <IconButton className={styles.closeBtn} onClick={onClose}>
          <SvgCloseIcon width={10} height={10} className={styles.closeIcon} />
        </IconButton>
      </header>
      <p className={styles.description}>{description}</p>
    </div>
  );
});

export default UnrecognizedVisitors;
