import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBle20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#717680"
      strokeWidth={1.667}
      d="m3.006 12.914 4.83-8.343c.964-1.664 3.365-1.664 4.328 0l4.83 8.343c.965 1.667-.238 3.753-2.163 3.753H5.17c-1.926 0-3.129-2.086-2.164-3.753Z"
    />
  </svg>
);
export default SvgBle20;
