import { HidePassIcon, SearchClear, ShowPassIcon } from '@shared/assets/icons/components';

import { InputProps } from './types';
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './input.module.scss';
import { useToggle } from '@shared/hooks';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      className,
      rootClassName,
      inputClassName,
      placeholder,
      isDisabled = false,
      isSecure = false,
      error,
      maxLength,
      leftIcon,
      type,
      hasClear = false,
      onClear,
      size = 'large',
      ...props
    },
    ref,
  ) => {
    const { state: isSecureField, toggle: toggleSecureField } = useToggle(isSecure);

    const passwordInputType = isSecureField ? type : 'text';
    const inputType = type === 'password' ? passwordInputType : type;

    const hasFilled = !!props.value;
    const hasError = !!error;
    const hasSecureFieldIcon = hasFilled && isSecure;
    const hasClearButton = hasClear && !isSecure && hasFilled && !isDisabled;

    const classesInputRoot = classNames(styles.root, rootClassName);
    const classesLabel = classNames(styles.label, { [styles.labelError]: hasError });
    const classesInputWrap = classNames(styles.inputWrap, className);
    const classesInput = classNames(
      styles.input,
      styles[size],
      {
        [styles.inputError]: hasError,
        [styles.inputDisabled]: isDisabled,
        [styles.hasLeftIcon]: !!leftIcon,
      },
      inputClassName,
    );
    const classesLeftIcon = classNames(styles[size], styles.iconContainer, styles.leftIconButton);
    const classesRightIcon = classNames(styles[size], styles.iconContainer, styles.rightIconButton);
    const classesSecureFieldIcon = classNames({ [styles.secureFieldErrorIcon]: hasError });

    return (
      <div className={classesInputRoot}>
        {label && (
          <label htmlFor={name} className={classesLabel}>
            {label}
          </label>
        )}
        <div className={classesInputWrap}>
          <input
            className={classesInput}
            id={name}
            ref={ref}
            maxLength={maxLength}
            placeholder={placeholder}
            type={inputType}
            {...props}
          />
          {leftIcon && <div className={classesLeftIcon}>{leftIcon}</div>}
          {hasSecureFieldIcon && (
            <>
              {isSecureField ? (
                <button type="button" className={classesRightIcon} onClick={toggleSecureField}>
                  <ShowPassIcon width={24} height={24} className={classesSecureFieldIcon} />
                </button>
              ) : (
                <button type="button" className={classesRightIcon} onClick={toggleSecureField}>
                  <HidePassIcon width={24} height={24} className={classesSecureFieldIcon} />
                </button>
              )}
            </>
          )}
          {hasClearButton && (
            <button type="button" className={classesRightIcon} onClick={onClear}>
              <SearchClear width={24} height={24} />
            </button>
          )}
          {hasError && <span className={styles.errorText}>{error?.message}</span>}
        </div>
      </div>
    );
  },
);
