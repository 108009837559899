import { FC, HTMLAttributes } from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

export type THeadProps = HTMLAttributes<HTMLTableSectionElement> & {
  backgroundVariant?: 'primary' | 'secondary';
  heightRowVariant?: 'bigRow' | 'smallRow';
};

export const THead: FC<THeadProps> = ({
  backgroundVariant = 'primary',
  heightRowVariant = 'bigRow',
  children,
  ...props
}) => (
  <thead className={classnames(styles.component, styles.sticky, styles[backgroundVariant])} {...props}>
    <tr className={styles[heightRowVariant]}>{children}</tr>
  </thead>
);
