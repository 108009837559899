import { Button, ButtonGroup, Modal } from '@shared/ui';
import { FC, useEffect, useState } from 'react';
import { NotificationLogResponseDto, VerificationLogStatusKind } from '@shared/services/apiService/apiService_log';

import { TextareaField } from '@shared/ui/Textarea/TextareaField';
import { getIsVioleted } from '../helpers';
import styles from './verifyNotification.module.scss';
import { useVerifyNotification } from '../hooks';

type VerifyNotificationProps = {
  isModalVisible: boolean;
  hasCreateNotificationVerify?: boolean;
  notification: NotificationLogResponseDto;
  closeCallback: () => void;
  onSuccess: () => void;
};

const MAX_LENGTH = 300;

export const VerifyNotification: FC<VerifyNotificationProps> = ({
  isModalVisible,
  hasCreateNotificationVerify = true,
  notification,
  closeCallback,
  onSuccess,
}) => {
  const initialVioleted = getIsVioleted(notification.verify_status!);

  const [isViolated, setViolated] = useState<boolean>(initialVioleted ? initialVioleted : false);

  const { errors, control, isDirty, isLoading, isSuccess, onSubmit, setValue, reset, handleSubmit } =
    useVerifyNotification({
      notificationId: notification.attendance_log_id,
      isViolated: isViolated,
    });

  const hasInitialViolated = isViolated === initialVioleted;
  const hasButtonSaveDisabled = hasCreateNotificationVerify ? false : !isDirty && hasInitialViolated;

  useEffect(() => {
    if (isModalVisible) {
      setViolated(initialVioleted ? initialVioleted : false);
      notification.security_comment && setValue('comment', notification.security_comment);
    } else {
      reset();
    }
  }, [isModalVisible]);

  useEffect(() => {
    notification.security_comment && setValue('comment', notification.security_comment);
  }, [notification]);

  useEffect(() => {
    if (isSuccess) {
      closeCallback();
      onSuccess();
    }
  }, [isSuccess]);

  return (
    <>
      <Modal title="Результат проверки" isVisible={isModalVisible} closeCallback={closeCallback}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <ButtonGroup
            name="isViolated"
            label="Были нарушения?"
            checked={isViolated}
            onChange={() => setViolated(prevState => !prevState)}
          />
          <TextareaField
            name="comment"
            defaultValue={notification.security_comment || ''}
            control={control}
            label="Комментарий"
            placeholder="Оставьте комментарий о подозрительном проходе"
            maxLength={MAX_LENGTH}
            error={errors.comment}
          />
          <Button type="submit" variant="primary" size="large" isLoading={isLoading} disabled={hasButtonSaveDisabled}>
            Сохранить результат
          </Button>
        </form>
      </Modal>
    </>
  );
};
