import { DEFAULT_VISITORS_MAP_POOLING_INTERVAL, VisitorsMapPath } from '../constants';
import {
  OfficeMetricsProps,
  OfficeVisitorsMetricsProps,
  SecurityUserAreasWithVisitorCountListItemResponseDto,
} from '../types';
import {
  SecurityUserAreasListItemResponseDto,
  SecurityUserOfficeResponseDto,
} from '@shared/services/apiService/apiService_base';
import { useEffect, useState } from 'react';

import { StorageKeys } from '@shared/hooks/useLocalStorage';
import axios from 'axios';
import { useLocalStorage } from '@shared/hooks';

type UseOfficeMetricsProps = { areas?: SecurityUserAreasListItemResponseDto[] };

export const useOfficeMetrics = ({ areas }: UseOfficeMetricsProps) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const officeId = activeOffice && activeOffice.id;

  const initialAreasData: SecurityUserAreasWithVisitorCountListItemResponseDto[] = areas
    ? areas.map(area => ({ ...area, visitorCount: 0 }))
    : [];

  const [areasWithVisitorsCount, setAreasWithVisitorsCount] =
    useState<SecurityUserAreasWithVisitorCountListItemResponseDto[]>(initialAreasData);

  useEffect(() => {
    setAreasWithVisitorsCount(initialAreasData);
  }, [areas]);

  useEffect(() => {
    const timer = setInterval(async () => {
      axios.get(VisitorsMapPath.OFFICES_METRICS(officeId)).then(function (response) {
        const officeMetricsData: OfficeMetricsProps = response.data;
        const areasMetrics: OfficeVisitorsMetricsProps[] = officeMetricsData.metrics;

        const newAreasData: SecurityUserAreasWithVisitorCountListItemResponseDto[] | undefined =
          areas &&
          areas.map(area => {
            const metricsByAreaId = areasMetrics.filter(areaMetrics => area.id === areaMetrics.areaUuid);
            const visitorsByAreaMetrics = metricsByAreaId.reduce(
              (accumulator, currentValue) => accumulator + currentValue.visitorCount,
              0,
            );

            return { ...area, visitorCount: visitorsByAreaMetrics };
          });

        if (newAreasData) {
          setAreasWithVisitorsCount(newAreasData);
        }
      });
    }, DEFAULT_VISITORS_MAP_POOLING_INTERVAL);

    return () => clearInterval(timer);
  }, [areas, officeId]);

  return { areasWithVisitorsCount };
};
