import { CameraResponseDto, VideoWallsListItemDto } from '@shared/services/apiService/apiService_videowall';
import { FC, useState } from 'react';

import { VideoWallAccordeon } from '../VideoWallAccordeon';
import { useParams } from 'react-router-dom';

type VideoWallListProps = {
  videoWallList?: VideoWallsListItemDto[];
  cameras?: CameraResponseDto[];
  onSelectVideoWall: (videoWall: VideoWallsListItemDto) => void;
};

export const VideoWallList: FC<VideoWallListProps> = ({ onSelectVideoWall, videoWallList, cameras }) => {
  const { videoWallId } = useParams();

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index: number, videoWall: VideoWallsListItemDto) => {
    setActiveIndex(activeIndex === index ? -1 : index);
    onSelectVideoWall(videoWall);
  };

  return (
    <>
      {videoWallList?.map((videoWall, index) => (
        <VideoWallAccordeon
          key={videoWall.id}
          name={videoWall.name}
          cameras={videoWallId === videoWall.id ? cameras : []}
          streamsCount={videoWall.camerasAmount}
          onClick={() => handleAccordionClick(index, videoWall)}
          checked={videoWall.id === videoWallId}
          index={index}
          activeIndex={activeIndex}
        />
      ))}
    </>
  );
};
