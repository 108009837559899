import {
  useLazyOfficesControllerGetOfficeVideoWallsQuery,
  useLazyVideoWallsControllerFindByIdQuery,
  useVideoWallsControllerUpdateByIdMutation,
} from '@shared/services/apiService/apiService_videowall';

import { SecurityUserOfficeResponseDto } from '@shared/services/apiService/apiService_base';
import { StorageKeys } from '@shared/hooks/useLocalStorage';
import { useEffect } from 'react';
import { useLocalStorage } from '@shared/hooks';
import { useParams } from 'react-router-dom';

export const useUpdateVideoWallStreams = (swapStreamId?: string) => {
  const [activeOffice] = useLocalStorage<SecurityUserOfficeResponseDto | undefined>(StorageKeys.UP_OFFICE, undefined);

  const { videoWallId } = useParams();

  const officeId = activeOffice && activeOffice.id;

  const [triggerVideoWallDataById, videoWall] = useLazyVideoWallsControllerFindByIdQuery();

  const [triggerGetVideoWallList] = useLazyOfficesControllerGetOfficeVideoWallsQuery();

  useEffect(() => {
    if (videoWallId) {
      triggerVideoWallDataById(
        {
          id: videoWallId,
        },
        true,
      );
    }
  }, [videoWallId]);

  const videoWallCameras = videoWall.data?.cameras;

  const camerasIds = videoWallCameras ? videoWallCameras.map(camera => camera.id) : [];

  const [updateVideoWall, { isSuccess: isSuccessUpdateVideoWall }] = useVideoWallsControllerUpdateByIdMutation();

  const handleUpdateVideoWallStreams = async (id: string) => {
    if (!videoWallId) {
      return;
    }

    const newCamerasIds = swapStreamId
      ? [...camerasIds.map(cameraId => (cameraId === swapStreamId ? id : cameraId))]
      : [...camerasIds, id];

    await updateVideoWall({
      id: videoWallId,
      updateVideoWallRequestDto: {
        camerasIds: newCamerasIds,
      },
    });

    if (!officeId) {
      return;
    }

    await triggerGetVideoWallList({ officeId: officeId });

    window.location.reload();
  };

  const handleDeleteStream = async (id: string) => {
    if (!videoWallId) {
      return;
    }

    const newCamerasIds = camerasIds.filter(item => item !== id && item);

    await updateVideoWall({
      id: videoWallId,
      updateVideoWallRequestDto: {
        camerasIds: [...newCamerasIds],
      },
    });

    if (!officeId) {
      return;
    }

    await triggerGetVideoWallList({ officeId: officeId });
  };

  return {
    isSuccessUpdateVideoWall,
    handleUpdateVideoWallStreams,
    handleDeleteStream,
  };
};
