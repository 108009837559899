import { FC, TdHTMLAttributes, useContext } from 'react';

import { PassageTypeEnum } from '@shared/store/types';
import { TableContext } from '../table-context';
import classnames from 'classnames';
import { passageTypeMapping } from '@pages/Journal/constants';
import styles from './index.module.scss';

export type TCellPassageTypeProps = TdHTMLAttributes<HTMLTableCellElement> & {
  passageType: PassageTypeEnum;
  className?: string;
  index?: number;
};
export const TCellPassageType: FC<TCellPassageTypeProps> = ({ className, passageType, index }) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const isPassageTypeSuspicious = passageType === PassageTypeEnum.SUSPICIOUS;

  const classesCell = classnames(styles.component, className);
  const classesPassageType = classnames(styles.passageType, {
    [styles.passageTypeSuspicious]: isPassageTypeSuspicious,
  });

  return (
    <td className={classesCell} style={{ width }}>
      <span className={classesPassageType}>{passageTypeMapping[passageType]}</span>
    </td>
  );
};
