import {
  CANVAS_ZOOM_SHIFT,
  INITIAL_SCALE_COUNTER,
  MAX_SCALE_COUNTER,
  MIN_SCALE_COUNTER,
  SCALE_COUNTER_SHIFT,
  SCALE_DOWN_FACTOR,
  SCALE_UP_FACTOR,
} from '../constants';
import { Dispatch, RefObject, SetStateAction, useMemo } from 'react';

import { Coords } from '../types';
import { useGetCursorCoords } from './useGetCursorCoords';

export const useZoom = (
  canvasRef: RefObject<HTMLCanvasElement>,
  cursorRef: RefObject<Coords | null>,
  setTotalScaleFactor: Dispatch<SetStateAction<number>>,
  redraw: () => void,
) => {
  const zoom = useMemo(
    () => (clicks: number) => {
      let factor = clicks > 0 ? SCALE_DOWN_FACTOR : SCALE_UP_FACTOR;

      const targetScaleFactorIncrement = INITIAL_SCALE_COUNTER + SCALE_COUNTER_SHIFT - CANVAS_ZOOM_SHIFT;
      const targetScaleFactorDecrement = INITIAL_SCALE_COUNTER - SCALE_COUNTER_SHIFT + CANVAS_ZOOM_SHIFT;

      let zoomScale = INITIAL_SCALE_COUNTER;

      function step() {
        if (!canvasRef.current) {
          return;
        }

        const context = canvasRef.current.getContext('2d');
        if (!context) {
          return;
        }

        const pt = useGetCursorCoords(canvasRef, cursorRef);

        if (pt === null) {
          return;
        }

        if (factor > 1) {
          if (zoomScale <= targetScaleFactorIncrement) {
            setTotalScaleFactor(prev =>
              prev + CANVAS_ZOOM_SHIFT <= MAX_SCALE_COUNTER ? prev + CANVAS_ZOOM_SHIFT : prev,
            );

            context.translate(pt.x, pt.y);
            context.scale(factor, factor);
            context.translate(-pt.x, -pt.y);

            redraw();
            window.requestAnimationFrame(step);

            zoomScale = zoomScale + CANVAS_ZOOM_SHIFT;
          }
        } else {
          if (zoomScale >= targetScaleFactorDecrement) {
            setTotalScaleFactor(prev =>
              prev - CANVAS_ZOOM_SHIFT >= MIN_SCALE_COUNTER ? prev - CANVAS_ZOOM_SHIFT : prev,
            );

            context.translate(pt.x, pt.y);
            context.scale(factor, factor);
            context.translate(-pt.x, -pt.y);

            redraw();
            window.requestAnimationFrame(step);

            zoomScale = zoomScale - CANVAS_ZOOM_SHIFT;
          }
        }
      }
      window.requestAnimationFrame(step);
    },
    [redraw],
  );

  return zoom;
};
