import * as React from 'react';

import type { SVGProps } from 'react';
const SvgToastClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.66663 4.66669L11.3333 11.3334"
      stroke="#696B77"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3334 4.66669L4.66671 11.3334"
      stroke="#696B77"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgToastClose;
