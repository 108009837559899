import * as React from 'react';

import type { SVGProps } from 'react';
const SvgSpinnerIconRed = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <mask
      id="spinner-icon-red_svg__b"
      width={7}
      height={8}
      x={16}
      y={4}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <path
        fill="url(#spinner-icon-red_svg__a)"
        d="M16.267 6.45 18.1 4.073A9.992 9.992 0 0 1 22 12h-3a6.991 6.991 0 0 0-2.733-5.55Z"
      />
    </mask>
    <g mask="url(#spinner-icon-red_svg__b)">
      <path fill="#E30611" d="M1 1h22v22H1z" />
    </g>
    <mask
      id="spinner-icon-red_svg__d"
      width={11}
      height={10}
      x={12}
      y={12}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <path
        fill="url(#spinner-icon-red_svg__c)"
        d="M12 19a7 7 0 0 0 7-7h3c0 .173-.005.348-.014.523-.28 5.34-4.7 9.481-9.986 9.477v-3Z"
      />
    </mask>
    <g mask="url(#spinner-icon-red_svg__d)">
      <path fill="#E30611" d="M1 1h22v22H1z" />
    </g>
    <mask
      id="spinner-icon-red_svg__f"
      width={11}
      height={10}
      x={1}
      y={12}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <path
        fill="url(#spinner-icon-red_svg__e)"
        d="M11.634 18.99c.122.006.245.01.366.01v3c-.174 0-.348-.005-.523-.014-5.34-.28-9.481-4.7-9.477-9.986h3a7 7 0 0 0 6.634 6.99Z"
      />
    </mask>
    <g mask="url(#spinner-icon-red_svg__f)">
      <path fill="#E30611" d="M1 1h22v22H1z" />
    </g>
    <mask
      id="spinner-icon-red_svg__h"
      width={13}
      height={11}
      x={1}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <path
        fill="url(#spinner-icon-red_svg__g)"
        d="M12.366 5.01A7 7 0 0 0 5 12H2c0-.174.005-.348.014-.524.289-5.515 4.994-9.752 10.51-9.463a1.5 1.5 0 0 1-.158 2.996Z"
      />
    </mask>
    <g mask="url(#spinner-icon-red_svg__h)">
      <path fill="#E30611" d="M1 1h22v22H1z" />
    </g>
    <defs>
      <linearGradient id="spinner-icon-red_svg__a" x1={19} x2={16} y1={12} y2={4} gradientUnits="userSpaceOnUse">
        <stop stopOpacity={0.08} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient id="spinner-icon-red_svg__c" x1={12} x2={19} y1={19} y2={12} gradientUnits="userSpaceOnUse">
        <stop stopOpacity={0.35} />
        <stop offset={1} stopOpacity={0.08} />
      </linearGradient>
      <linearGradient id="spinner-icon-red_svg__e" x1={5} x2={12} y1={12} y2={19} gradientUnits="userSpaceOnUse">
        <stop stopOpacity={0.65} />
        <stop offset={1} stopOpacity={0.35} />
      </linearGradient>
      <linearGradient id="spinner-icon-red_svg__g" x1={14} x2={5} y1={5} y2={12} gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset={1} stopOpacity={0.65} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSpinnerIconRed;
