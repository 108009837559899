import { NotificationsPath } from '@shared/constants/routes';

export const NOTIFICATIONS_TABS = [
  {
    title: 'Подозрительные проходы',
    to: NotificationsPath.SUSPICIOUS,
  },
  {
    title: 'Проверенные',
    to: NotificationsPath.VERIFIED,
  },
];

export const NOTIFICATION_PAGE_HEADING = 'Уведомления';

export enum NotificationTabEnum {
  SUSPICIOUS = 'suspicious',
  VERIFIED = 'verified',
}
