import { FC, TdHTMLAttributes, useContext, useId } from 'react';

import { Button } from '@shared/ui/Button';
import { ConditionalWrapper } from '@shared/ui/ConditionalWrapper';
import { TableContext } from '../table-context';
import { Tooltip } from '@shared/ui/Tooltip';
import classnames from 'classnames';
import styles from './index.module.scss';

export type TCellCommentProps = TdHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  index?: number;
  notificationComment?: string | null;
  hasPatch?: boolean;
  onClick: () => void;
};

export const TCellComment: FC<TCellCommentProps> = ({
  className,
  style,
  index,
  notificationComment,
  hasPatch = false,
  onClick,
  ...props
}) => {
  const { columnsConfiguration } = useContext(TableContext);

  const column = index === undefined ? null : columnsConfiguration[index];
  const width = column?.width;

  const maxCommentLenght = 30;
  const comment = notificationComment || '';

  const isLongComment = comment.length > maxCommentLenght;
  const substringComment = comment.substring(0, maxCommentLenght) + '...';

  const classesCell = classnames(styles.component, className);

  return (
    <td className={classesCell} style={{ ...style, width }} {...props}>
      <div className={styles.inner}>
        {!!comment ? (
          <ConditionalWrapper
            condition={isLongComment}
            wrapper={children => {
              return (
                <Tooltip id={useId()} content={comment}>
                  {children}
                </Tooltip>
              );
            }}>
            <p className={styles.comment} onClick={onClick}>
              {isLongComment ? substringComment : comment}
            </p>
          </ConditionalWrapper>
        ) : (
          <Button variant="secondary" onClick={onClick}>
            {hasPatch ? 'Добавить комментарий' : 'Отметить как проверенный'}
          </Button>
        )}
      </div>
    </td>
  );
};
