import React from 'react';
import { toast } from 'react-toastify';
import { ToastSuccess, ToastClose, ToastError } from '@shared/assets/icons/components';

import 'react-toastify/dist/ReactToastify.css';
import styles from './toast.module.scss';

interface IToast {
  variant: string,
  message?: string,
  containerId: string
}

const CustomToast: React.FC<IToast> = ({ variant, message }) => (
  <div className={styles.customToast}>
    {variant === 'error' ? <ToastError />: <ToastSuccess /> }
    <div className={styles.customToastMessage}>{message}</div>
    <div className={styles.customToastClose}>
      <div className={styles.progressBar}>
        <ToastClose/>
        <progress value="100" max="100" style={{visibility:'hidden',height:0,width:0}}>
        </progress>
      </div>
    </div>
  </div>
);

export const showToast = (props: IToast) => {
  toast(<CustomToast {...props} />, {
    className: styles.customToastContainer,
    bodyClassName:styles.customToastBody,
    progressClassName: styles.customProgressBar,
    autoClose: 9000,
    position: 'bottom-right',
    containerId: props.containerId
  });
};